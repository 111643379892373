.menuBox {
    background-color: white;
    height: auto;
    box-shadow: 1px 1px 10px #ddd;
    list-style: none;
    padding-left: 0;
}

ul.menuBox {

}

ul.menuBox li {
    padding: 8px 20px;
    min-width: 120px;
    border-bottom: 1px solid #f1f1f1;
    color: #212529;
    cursor: pointer;
    font-family: Poppins, sans-serif;;
}

ul.menuBox li:hover {
    background-color: #f1f1f1;
}
