

.bx {
    font-family: "boxicons" !important;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    text-rendering: auto;
    display: inline-block;
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.bx-ul {
    margin-left: 2em;
    padding-left: 0;
    list-style: none
}

.bx-ul > li {
    position: relative
}

.bx-ul .bx {
    font-size: inherit;
    line-height: inherit;
    position: absolute;
    left: -2em;
    width: 2em;
    text-align: center
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

@-webkit-keyframes burst {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
    90% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        opacity: 0
    }
}

@keyframes burst {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
    90% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        opacity: 0
    }
}

@-webkit-keyframes flashing {
    0% {
        opacity: 1
    }
    45% {
        opacity: 0
    }
    90% {
        opacity: 1
    }
}

@keyframes flashing {
    0% {
        opacity: 1
    }
    45% {
        opacity: 0
    }
    90% {
        opacity: 1
    }
}

@-webkit-keyframes fade-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1
    }
    75% {
        -webkit-transform: translateX(-20px);
        transform: translateX(-20px);
        opacity: 0
    }
}

@keyframes fade-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1
    }
    75% {
        -webkit-transform: translateX(-20px);
        transform: translateX(-20px);
        opacity: 0
    }
}

@-webkit-keyframes fade-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1
    }
    75% {
        -webkit-transform: translateX(20px);
        transform: translateX(20px);
        opacity: 0
    }
}

@keyframes fade-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1
    }
    75% {
        -webkit-transform: translateX(20px);
        transform: translateX(20px);
        opacity: 0
    }
}

@-webkit-keyframes fade-up {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1
    }
    75% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
        opacity: 0
    }
}

@keyframes fade-up {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1
    }
    75% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
        opacity: 0
    }
}

@-webkit-keyframes fade-down {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1
    }
    75% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0
    }
}

@keyframes fade-down {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1
    }
    75% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0
    }
}

@-webkit-keyframes tada {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
    10%, 20% {
        -webkit-transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
        transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg)
    }
    30%, 50%, 70%, 90% {
        -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
        transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg)
    }
    40%, 60%, 80% {
        -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg);
        transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg)
    }
    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

@keyframes tada {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
    10%, 20% {
        -webkit-transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
        transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg)
    }
    30%, 50%, 70%, 90% {
        -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
        transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg)
    }
    40%, 60%, 80% {
        -webkit-transform: rotate3d(0, 0, 1, -10deg);
        transform: rotate3d(0, 0, 1, -10deg)
    }
    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

.bx-spin {
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite
}

.bx-spin-hover:hover {
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite
}

.bx-tada {
    -webkit-animation: tada 1.5s ease infinite;
    animation: tada 1.5s ease infinite
}

.bx-tada-hover:hover {
    -webkit-animation: tada 1.5s ease infinite;
    animation: tada 1.5s ease infinite
}

.bx-flashing {
    -webkit-animation: flashing 1.5s infinite linear;
    animation: flashing 1.5s infinite linear
}

.bx-flashing-hover:hover {
    -webkit-animation: flashing 1.5s infinite linear;
    animation: flashing 1.5s infinite linear
}

.bx-burst {
    -webkit-animation: burst 1.5s infinite linear;
    animation: burst 1.5s infinite linear
}

.bx-burst-hover:hover {
    -webkit-animation: burst 1.5s infinite linear;
    animation: burst 1.5s infinite linear
}

.bx-fade-up {
    -webkit-animation: fade-up 1.5s infinite linear;
    animation: fade-up 1.5s infinite linear
}

.bx-fade-up-hover:hover {
    -webkit-animation: fade-up 1.5s infinite linear;
    animation: fade-up 1.5s infinite linear
}

.bx-fade-down {
    -webkit-animation: fade-down 1.5s infinite linear;
    animation: fade-down 1.5s infinite linear
}

.bx-fade-down-hover:hover {
    -webkit-animation: fade-down 1.5s infinite linear;
    animation: fade-down 1.5s infinite linear
}

.bx-fade-left {
    -webkit-animation: fade-left 1.5s infinite linear;
    animation: fade-left 1.5s infinite linear
}

.bx-fade-left-hover:hover {
    -webkit-animation: fade-left 1.5s infinite linear;
    animation: fade-left 1.5s infinite linear
}

.bx-fade-right {
    -webkit-animation: fade-right 1.5s infinite linear;
    animation: fade-right 1.5s infinite linear
}

.bx-fade-right-hover:hover {
    -webkit-animation: fade-right 1.5s infinite linear;
    animation: fade-right 1.5s infinite linear
}

.bx-xs {
    font-size: 1rem !important
}

.bx-sm {
    font-size: 1.55rem !important
}

.bx-md {
    font-size: 2.25rem !important
}

.bx-lg {
    font-size: 3rem !important
}

.bx-fw {
    font-size: 1.2857142857em;
    line-height: .8em;
    width: 1.2857142857em;
    height: .8em;
    margin-top: -0.2em !important;
    vertical-align: middle
}

.bx-pull-left {
    float: left;
    margin-right: .3em !important
}

.bx-pull-right {
    float: right;
    margin-left: .3em !important
}

.bx-rotate-90 {
    transform: rotate(90deg);
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)"
}

.bx-rotate-180 {
    transform: rotate(180deg);
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)"
}

.bx-rotate-270 {
    transform: rotate(270deg);
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)"
}

.bx-flip-horizontal {
    transform: scaleX(-1);
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)"
}

.bx-flip-vertical {
    transform: scaleY(-1);
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)"
}

.bx-border {
    padding: .25em;
    border: .07em solid rgba(0, 0, 0, 0.1);
    border-radius: .25em
}

.bx-border-circle {
    padding: .25em;
    border: .07em solid rgba(0, 0, 0, 0.1);
    border-radius: 50%
}

.bxs-balloon:before {
    content: ""
}

.bxs-castle:before {
    content: ""
}

.bxs-coffee-bean:before {
    content: ""
}

.bxs-objects-horizontal-center:before {
    content: ""
}

.bxs-objects-horizontal-left:before {
    content: ""
}

.bxs-objects-horizontal-right:before {
    content: ""
}

.bxs-objects-vertical-bottom:before {
    content: ""
}

.bxs-objects-vertical-center:before {
    content: ""
}

.bxs-objects-vertical-top:before {
    content: ""
}

.bxs-pear:before {
    content: ""
}

.bxs-shield-minus:before {
    content: ""
}

.bxs-shield-plus:before {
    content: ""
}

.bxs-shower:before {
    content: ""
}

.bxs-sushi:before {
    content: ""
}

.bxs-universal-access:before {
    content: ""
}

.bx-child:before {
    content: ""
}

.bx-horizontal-left:before {
    content: ""
}

.bx-horizontal-right:before {
    content: ""
}

.bx-objects-horizontal-center:before {
    content: ""
}

.bx-objects-horizontal-left:before {
    content: ""
}

.bx-objects-horizontal-right:before {
    content: ""
}

.bx-objects-vertical-bottom:before {
    content: ""
}

.bx-objects-vertical-center:before {
    content: ""
}

.bx-objects-vertical-top:before {
    content: ""
}

.bx-rfid:before {
    content: ""
}

.bx-shield-minus:before {
    content: ""
}

.bx-shield-plus:before {
    content: ""
}

.bx-shower:before {
    content: ""
}

.bx-sushi:before {
    content: ""
}

.bx-universal-access:before {
    content: ""
}

.bx-vertical-bottom:before {
    content: ""
}

.bx-vertical-top:before {
    content: ""
}

.bxl-graphql:before {
    content: ""
}

.bxl-typescript:before {
    content: ""
}

.bxs-color:before {
    content: ""
}

.bx-reflect-horizontal:before {
    content: ""
}

.bx-reflect-vertical:before {
    content: ""
}

.bx-color:before {
    content: ""
}

.bxl-mongodb:before {
    content: ""
}

.bxl-postgresql:before {
    content: ""
}

.bxl-deezer:before {
    content: ""
}

.bxs-hard-hat:before {
    content: ""
}

.bxs-home-alt-2:before {
    content: ""
}

.bxs-cheese:before {
    content: ""
}

.bx-home-alt-2:before {
    content: ""
}

.bx-hard-hat:before {
    content: ""
}

.bx-cheese:before {
    content: ""
}

.bx-cart-add:before {
    content: ""
}

.bx-cart-download:before {
    content: ""
}

.bx-no-signal:before {
    content: ""
}

.bx-signal-1:before {
    content: ""
}

.bx-signal-2:before {
    content: ""
}

.bx-signal-3:before {
    content: ""
}

.bx-signal-4:before {
    content: ""
}

.bx-signal-5:before {
    content: ""
}

.bxl-xing:before {
    content: ""
}

.bxl-meta:before {
    content: ""
}

.bx-lemon:before {
    content: ""
}

.bxs-lemon:before {
    content: ""
}

.bx-cricket-ball:before {
    content: ""
}

.bx-baguette:before {
    content: ""
}

.bx-bowl-hot:before {
    content: ""
}

.bx-bowl-rice:before {
    content: ""
}

.bx-cable-car:before {
    content: ""
}

.bx-candles:before {
    content: ""
}

.bx-circle-half:before {
    content: ""
}

.bx-circle-quarter:before {
    content: ""
}

.bx-circle-three-quarter:before {
    content: ""
}

.bx-cross:before {
    content: ""
}

.bx-fork:before {
    content: ""
}

.bx-knife:before {
    content: ""
}

.bx-money-withdraw:before {
    content: ""
}

.bx-popsicle:before {
    content: ""
}

.bx-scatter-chart:before {
    content: ""
}

.bxs-baguette:before {
    content: ""
}

.bxs-bowl-hot:before {
    content: ""
}

.bxs-bowl-rice:before {
    content: ""
}

.bxs-cable-car:before {
    content: ""
}

.bxs-circle-half:before {
    content: ""
}

.bxs-circle-quarter:before {
    content: ""
}

.bxs-circle-three-quarter:before {
    content: ""
}

.bxs-cricket-ball:before {
    content: ""
}

.bxs-invader:before {
    content: ""
}

.bx-male-female:before {
    content: ""
}

.bxs-popsicle:before {
    content: ""
}

.bxs-tree-alt:before {
    content: ""
}

.bxl-venmo:before {
    content: ""
}

.bxl-upwork:before {
    content: ""
}

.bxl-netlify:before {
    content: ""
}

.bxl-java:before {
    content: ""
}

.bxl-heroku:before {
    content: ""
}

.bxl-go-lang:before {
    content: ""
}

.bxl-gmail:before {
    content: ""
}

.bxl-flask:before {
    content: ""
}

.bxl-99designs:before {
    content: ""
}

.bxl-500px:before {
    content: ""
}

.bxl-adobe:before {
    content: ""
}

.bxl-airbnb:before {
    content: ""
}

.bxl-algolia:before {
    content: ""
}

.bxl-amazon:before {
    content: ""
}

.bxl-android:before {
    content: ""
}

.bxl-angular:before {
    content: ""
}

.bxl-apple:before {
    content: ""
}

.bxl-audible:before {
    content: ""
}

.bxl-aws:before {
    content: ""
}

.bxl-baidu:before {
    content: ""
}

.bxl-behance:before {
    content: ""
}

.bxl-bing:before {
    content: ""
}

.bxl-bitcoin:before {
    content: ""
}

.bxl-blender:before {
    content: ""
}

.bxl-blogger:before {
    content: ""
}

.bxl-bootstrap:before {
    content: ""
}

.bxl-chrome:before {
    content: ""
}

.bxl-codepen:before {
    content: ""
}

.bxl-c-plus-plus:before {
    content: ""
}

.bxl-creative-commons:before {
    content: ""
}

.bxl-css3:before {
    content: ""
}

.bxl-dailymotion:before {
    content: ""
}

.bxl-deviantart:before {
    content: ""
}

.bxl-dev-to:before {
    content: ""
}

.bxl-digg:before {
    content: ""
}

.bxl-digitalocean:before {
    content: ""
}

.bxl-discord:before {
    content: ""
}

.bxl-discord-alt:before {
    content: ""
}

.bxl-discourse:before {
    content: ""
}

.bxl-django:before {
    content: ""
}

.bxl-docker:before {
    content: ""
}

.bxl-dribbble:before {
    content: ""
}

.bxl-dropbox:before {
    content: ""
}

.bxl-drupal:before {
    content: ""
}

.bxl-ebay:before {
    content: ""
}

.bxl-edge:before {
    content: ""
}

.bxl-etsy:before {
    content: ""
}

.bxl-facebook:before {
    content: ""
}

.bxl-facebook-circle:before {
    content: ""
}

.bxl-facebook-square:before {
    content: ""
}

.bxl-figma:before {
    content: ""
}

.bxl-firebase:before {
    content: ""
}

.bxl-firefox:before {
    content: ""
}

.bxl-flickr:before {
    content: ""
}

.bxl-flickr-square:before {
    content: ""
}

.bxl-flutter:before {
    content: ""
}

.bxl-foursquare:before {
    content: ""
}

.bxl-git:before {
    content: ""
}

.bxl-github:before {
    content: ""
}

.bxl-gitlab:before {
    content: ""
}

.bxl-google:before {
    content: ""
}

.bxl-google-cloud:before {
    content: ""
}

.bxl-google-plus:before {
    content: ""
}

.bxl-google-plus-circle:before {
    content: ""
}

.bxl-html5:before {
    content: ""
}

.bxl-imdb:before {
    content: ""
}

.bxl-instagram:before {
    content: ""
}

.bxl-instagram-alt:before {
    content: ""
}

.bxl-internet-explorer:before {
    content: ""
}

.bxl-invision:before {
    content: ""
}

.bxl-javascript:before {
    content: ""
}

.bxl-joomla:before {
    content: ""
}

.bxl-jquery:before {
    content: ""
}

.bxl-jsfiddle:before {
    content: ""
}

.bxl-kickstarter:before {
    content: ""
}

.bxl-kubernetes:before {
    content: ""
}

.bxl-less:before {
    content: ""
}

.bxl-linkedin:before {
    content: ""
}

.bxl-linkedin-square:before {
    content: ""
}

.bxl-magento:before {
    content: ""
}

.bxl-mailchimp:before {
    content: ""
}

.bxl-markdown:before {
    content: ""
}

.bxl-mastercard:before {
    content: ""
}

.bxl-mastodon:before {
    content: ""
}

.bxl-medium:before {
    content: ""
}

.bxl-medium-old:before {
    content: ""
}

.bxl-medium-square:before {
    content: ""
}

.bxl-messenger:before {
    content: ""
}

.bxl-microsoft:before {
    content: ""
}

.bxl-microsoft-teams:before {
    content: ""
}

.bxl-nodejs:before {
    content: ""
}

.bxl-ok-ru:before {
    content: ""
}

.bxl-opera:before {
    content: ""
}

.bxl-patreon:before {
    content: ""
}

.bxl-paypal:before {
    content: ""
}

.bxl-periscope:before {
    content: ""
}

.bxl-php:before {
    content: ""
}

.bxl-pinterest:before {
    content: ""
}

.bxl-pinterest-alt:before {
    content: ""
}

.bxl-play-store:before {
    content: ""
}

.bxl-pocket:before {
    content: ""
}

.bxl-product-hunt:before {
    content: ""
}

.bxl-python:before {
    content: ""
}

.bxl-quora:before {
    content: ""
}

.bxl-react:before {
    content: ""
}

.bxl-redbubble:before {
    content: ""
}

.bxl-reddit:before {
    content: ""
}

.bxl-redux:before {
    content: ""
}

.bxl-sass:before {
    content: ""
}

.bxl-shopify:before {
    content: ""
}

.bxl-sketch:before {
    content: ""
}

.bxl-skype:before {
    content: ""
}

.bxl-slack:before {
    content: ""
}

.bxl-slack-old:before {
    content: ""
}

.bxl-snapchat:before {
    content: ""
}

.bxl-soundcloud:before {
    content: ""
}

.bxl-spotify:before {
    content: ""
}

.bxl-spring-boot:before {
    content: ""
}

.bxl-squarespace:before {
    content: ""
}

.bxl-stack-overflow:before {
    content: ""
}

.bxl-steam:before {
    content: ""
}

.bxl-stripe:before {
    content: ""
}

.bxl-tailwind-css:before {
    content: ""
}

.bxl-telegram:before {
    content: ""
}

.bxl-tiktok:before {
    content: ""
}

.bxl-trello:before {
    content: ""
}

.bxl-trip-advisor:before {
    content: ""
}

.bxl-tumblr:before {
    content: ""
}

.bxl-tux:before {
    content: ""
}

.bxl-twitch:before {
    content: ""
}

.bxl-twitter:before {
    content: ""
}

.bxl-unity:before {
    content: ""
}

.bxl-unsplash:before {
    content: ""
}

.bxl-vimeo:before {
    content: ""
}

.bxl-visa:before {
    content: ""
}

.bxl-visual-studio:before {
    content: ""
}

.bxl-vk:before {
    content: ""
}

.bxl-vuejs:before {
    content: ""
}

.bxl-whatsapp:before {
    content: ""
}

.bxl-whatsapp-square:before {
    content: ""
}

.bxl-wikipedia:before {
    content: ""
}

.bxl-windows:before {
    content: ""
}

.bxl-wix:before {
    content: ""
}

.bxl-wordpress:before {
    content: ""
}

.bxl-yahoo:before {
    content: ""
}

.bxl-yelp:before {
    content: ""
}

.bxl-youtube:before {
    content: ""
}

.bxl-zoom:before {
    content: ""
}

.bx-collapse-alt:before {
    content: ""
}

.bx-collapse-horizontal:before {
    content: ""
}

.bx-collapse-vertical:before {
    content: ""
}

.bx-expand-horizontal:before {
    content: ""
}

.bx-expand-vertical:before {
    content: ""
}

.bx-injection:before {
    content: ""
}

.bx-leaf:before {
    content: ""
}

.bx-math:before {
    content: ""
}

.bx-party:before {
    content: ""
}

.bx-abacus:before {
    content: ""
}

.bx-accessibility:before {
    content: ""
}

.bx-add-to-queue:before {
    content: ""
}

.bx-adjust:before {
    content: ""
}

.bx-alarm:before {
    content: ""
}

.bx-alarm-add:before {
    content: ""
}

.bx-alarm-exclamation:before {
    content: ""
}

.bx-alarm-off:before {
    content: ""
}

.bx-alarm-snooze:before {
    content: ""
}

.bx-album:before {
    content: ""
}

.bx-align-justify:before {
    content: ""
}

.bx-align-left:before {
    content: ""
}

.bx-align-middle:before {
    content: ""
}

.bx-align-right:before {
    content: ""
}

.bx-analyse:before {
    content: ""
}

.bx-anchor:before {
    content: ""
}

.bx-angry:before {
    content: ""
}

.bx-aperture:before {
    content: ""
}

.bx-arch:before {
    content: ""
}

.bx-archive:before {
    content: ""
}

.bx-archive-in:before {
    content: ""
}

.bx-archive-out:before {
    content: ""
}

.bx-area:before {
    content: ""
}

.bx-arrow-back:before {
    content: ""
}

.bx-arrow-from-bottom:before {
    content: ""
}

.bx-arrow-from-left:before {
    content: ""
}

.bx-arrow-from-right:before {
    content: ""
}

.bx-arrow-from-top:before {
    content: ""
}

.bx-arrow-to-bottom:before {
    content: ""
}

.bx-arrow-to-left:before {
    content: ""
}

.bx-arrow-to-right:before {
    content: ""
}

.bx-arrow-to-top:before {
    content: ""
}

.bx-at:before {
    content: ""
}

.bx-atom:before {
    content: ""
}

.bx-award:before {
    content: ""
}

.bx-badge:before {
    content: ""
}

.bx-badge-check:before {
    content: ""
}

.bx-ball:before {
    content: ""
}

.bx-band-aid:before {
    content: ""
}

.bx-bar-chart:before {
    content: ""
}

.bx-bar-chart-alt:before {
    content: ""
}

.bx-bar-chart-alt-2:before {
    content: ""
}

.bx-bar-chart-square:before {
    content: ""
}

.bx-barcode:before {
    content: ""
}

.bx-barcode-reader:before {
    content: ""
}

.bx-baseball:before {
    content: ""
}

.bx-basket:before {
    content: ""
}

.bx-basketball:before {
    content: ""
}

.bx-bath:before {
    content: ""
}

.bx-battery:before {
    content: ""
}

.bx-bed:before {
    content: ""
}

.bx-been-here:before {
    content: ""
}

.bx-beer:before {
    content: ""
}

.bx-bell:before {
    content: ""
}

.bx-bell-minus:before {
    content: ""
}

.bx-bell-off:before {
    content: ""
}

.bx-bell-plus:before {
    content: ""
}

.bx-bible:before {
    content: ""
}

.bx-bitcoin:before {
    content: ""
}

.bx-blanket:before {
    content: ""
}

.bx-block:before {
    content: ""
}

.bx-bluetooth:before {
    content: ""
}

.bx-body:before {
    content: ""
}

.bx-bold:before {
    content: ""
}

.bx-bolt-circle:before {
    content: ""
}

.bx-bomb:before {
    content: ""
}

.bx-bone:before {
    content: ""
}

.bx-bong:before {
    content: ""
}

.bx-book:before {
    content: ""
}

.bx-book-add:before {
    content: ""
}

.bx-book-alt:before {
    content: ""
}

.bx-book-bookmark:before {
    content: ""
}

.bx-book-content:before {
    content: ""
}

.bx-book-heart:before {
    content: ""
}

.bx-bookmark:before {
    content: ""
}

.bx-bookmark-alt:before {
    content: ""
}

.bx-bookmark-alt-minus:before {
    content: ""
}

.bx-bookmark-alt-plus:before {
    content: ""
}

.bx-bookmark-heart:before {
    content: ""
}

.bx-bookmark-minus:before {
    content: ""
}

.bx-bookmark-plus:before {
    content: ""
}

.bx-bookmarks:before {
    content: ""
}

.bx-book-open:before {
    content: ""
}

.bx-book-reader:before {
    content: ""
}

.bx-border-all:before {
    content: ""
}

.bx-border-bottom:before {
    content: ""
}

.bx-border-inner:before {
    content: ""
}

.bx-border-left:before {
    content: ""
}

.bx-border-none:before {
    content: ""
}

.bx-border-outer:before {
    content: ""
}

.bx-border-radius:before {
    content: ""
}

.bx-border-right:before {
    content: ""
}

.bx-border-top:before {
    content: ""
}

.bx-bot:before {
    content: ""
}

.bx-bowling-ball:before {
    content: ""
}

.bx-box:before {
    content: ""
}

.bx-bracket:before {
    content: ""
}

.bx-braille:before {
    content: ""
}

.bx-brain:before {
    content: ""
}

.bx-briefcase:before {
    content: ""
}

.bx-briefcase-alt:before {
    content: ""
}

.bx-briefcase-alt-2:before {
    content: ""
}

.bx-brightness:before {
    content: ""
}

.bx-brightness-half:before {
    content: ""
}

.bx-broadcast:before {
    content: ""
}

.bx-brush:before {
    content: ""
}

.bx-brush-alt:before {
    content: ""
}

.bx-bug:before {
    content: ""
}

.bx-bug-alt:before {
    content: ""
}

.bx-building:before {
    content: ""
}

.bx-building-house:before {
    content: ""
}

.bx-buildings:before {
    content: ""
}

.bx-bulb:before {
    content: ""
}

.bx-bullseye:before {
    content: ""
}

.bx-buoy:before {
    content: ""
}

.bx-bus:before {
    content: ""
}

.bx-bus-school:before {
    content: ""
}

.bx-cabinet:before {
    content: ""
}

.bx-cake:before {
    content: ""
}

.bx-calculator:before {
    content: ""
}

.bx-calendar:before {
    content: ""
}

.bx-calendar-alt:before {
    content: ""
}

.bx-calendar-check:before {
    content: ""
}

.bx-calendar-edit:before {
    content: ""
}

.bx-calendar-event:before {
    content: ""
}

.bx-calendar-exclamation:before {
    content: ""
}

.bx-calendar-heart:before {
    content: ""
}

.bx-calendar-minus:before {
    content: ""
}

.bx-calendar-plus:before {
    content: ""
}

.bx-calendar-star:before {
    content: ""
}

.bx-calendar-week:before {
    content: ""
}

.bx-calendar-x:before {
    content: ""
}

.bx-camera:before {
    content: ""
}

.bx-camera-home:before {
    content: ""
}

.bx-camera-movie:before {
    content: ""
}

.bx-camera-off:before {
    content: ""
}

.bx-capsule:before {
    content: ""
}

.bx-captions:before {
    content: ""
}

.bx-car:before {
    content: ""
}

.bx-card:before {
    content: ""
}

.bx-caret-down:before {
    content: ""
}

.bx-caret-down-circle:before {
    content: ""
}

.bx-caret-down-square:before {
    content: ""
}

.bx-caret-left:before {
    content: ""
}

.bx-caret-left-circle:before {
    content: ""
}

.bx-caret-left-square:before {
    content: ""
}

.bx-caret-right:before {
    content: ""
}

.bx-caret-right-circle:before {
    content: ""
}

.bx-caret-right-square:before {
    content: ""
}

.bx-caret-up:before {
    content: ""
}

.bx-caret-up-circle:before {
    content: ""
}

.bx-caret-up-square:before {
    content: ""
}

.bx-carousel:before {
    content: ""
}

.bx-cart:before {
    content: ""
}

.bx-cart-alt:before {
    content: ""
}

.bx-cast:before {
    content: ""
}

.bx-category:before {
    content: ""
}

.bx-category-alt:before {
    content: ""
}

.bx-cctv:before {
    content: ""
}

.bx-certification:before {
    content: ""
}

.bx-chair:before {
    content: ""
}

.bx-chalkboard:before {
    content: ""
}

.bx-chart:before {
    content: ""
}

.bx-chat:before {
    content: ""
}

.bx-check:before {
    content: ""
}

.bx-checkbox:before {
    content: ""
}

.bx-checkbox-checked:before {
    content: ""
}

.bx-checkbox-minus:before {
    content: ""
}

.bx-checkbox-square:before {
    content: ""
}

.bx-check-circle:before {
    content: ""
}

.bx-check-double:before {
    content: ""
}

.bx-check-shield:before {
    content: ""
}

.bx-check-square:before {
    content: ""
}

.bx-chevron-down:before {
    content: ""
}

.bx-chevron-down-circle:before {
    content: ""
}

.bx-chevron-down-square:before {
    content: ""
}

.bx-chevron-left:before {
    content: ""
}

.bx-chevron-left-circle:before {
    content: ""
}

.bx-chevron-left-square:before {
    content: ""
}

.bx-chevron-right:before {
    content: ""
}

.bx-chevron-right-circle:before {
    content: ""
}

.bx-chevron-right-square:before {
    content: ""
}

.bx-chevrons-down:before {
    content: ""
}

.bx-chevrons-left:before {
    content: ""
}

.bx-chevrons-right:before {
    content: ""
}

.bx-chevrons-up:before {
    content: ""
}

.bx-chevron-up:before {
    content: ""
}

.bx-chevron-up-circle:before {
    content: ""
}

.bx-chevron-up-square:before {
    content: ""
}

.bx-chip:before {
    content: ""
}

.bx-church:before {
    content: ""
}

.bx-circle:before {
    content: ""
}

.bx-clinic:before {
    content: ""
}

.bx-clipboard:before {
    content: ""
}

.bx-closet:before {
    content: ""
}

.bx-cloud:before {
    content: ""
}

.bx-cloud-download:before {
    content: ""
}

.bx-cloud-drizzle:before {
    content: ""
}

.bx-cloud-lightning:before {
    content: ""
}

.bx-cloud-light-rain:before {
    content: ""
}

.bx-cloud-rain:before {
    content: ""
}

.bx-cloud-snow:before {
    content: ""
}

.bx-cloud-upload:before {
    content: ""
}

.bx-code:before {
    content: ""
}

.bx-code-alt:before {
    content: ""
}

.bx-code-block:before {
    content: ""
}

.bx-code-curly:before {
    content: ""
}

.bx-coffee:before {
    content: ""
}

.bx-coffee-togo:before {
    content: ""
}

.bx-cog:before {
    content: ""
}

.bx-coin:before {
    content: ""
}

.bx-coin-stack:before {
    content: ""
}

.bx-collapse:before {
    content: ""
}

.bx-collection:before {
    content: ""
}

.bx-color-fill:before {
    content: ""
}

.bx-columns:before {
    content: ""
}

.bx-command:before {
    content: ""
}

.bx-comment:before {
    content: ""
}

.bx-comment-add:before {
    content: ""
}

.bx-comment-check:before {
    content: ""
}

.bx-comment-detail:before {
    content: ""
}

.bx-comment-dots:before {
    content: ""
}

.bx-comment-edit:before {
    content: ""
}

.bx-comment-error:before {
    content: ""
}

.bx-comment-minus:before {
    content: ""
}

.bx-comment-x:before {
    content: ""
}

.bx-compass:before {
    content: ""
}

.bx-confused:before {
    content: ""
}

.bx-conversation:before {
    content: ""
}

.bx-cookie:before {
    content: ""
}

.bx-cool:before {
    content: ""
}

.bx-copy:before {
    content: ""
}

.bx-copy-alt:before {
    content: ""
}

.bx-copyright:before {
    content: ""
}

.bx-credit-card:before {
    content: ""
}

.bx-credit-card-alt:before {
    content: ""
}

.bx-credit-card-front:before {
    content: ""
}

.bx-crop:before {
    content: ""
}

.bx-crosshair:before {
    content: ""
}

.bx-crown:before {
    content: ""
}

.bx-cube:before {
    content: ""
}

.bx-cube-alt:before {
    content: ""
}

.bx-cuboid:before {
    content: ""
}

.bx-current-location:before {
    content: ""
}

.bx-customize:before {
    content: ""
}

.bx-cut:before {
    content: ""
}

.bx-cycling:before {
    content: ""
}

.bx-cylinder:before {
    content: ""
}

.bx-data:before {
    content: ""
}

.bx-desktop:before {
    content: ""
}

.bx-detail:before {
    content: ""
}

.bx-devices:before {
    content: ""
}

.bx-dialpad:before {
    content: ""
}

.bx-dialpad-alt:before {
    content: ""
}

.bx-diamond:before {
    content: ""
}

.bx-dice-1:before {
    content: ""
}

.bx-dice-2:before {
    content: ""
}

.bx-dice-3:before {
    content: ""
}

.bx-dice-4:before {
    content: ""
}

.bx-dice-5:before {
    content: ""
}

.bx-dice-6:before {
    content: ""
}

.bx-directions:before {
    content: ""
}

.bx-disc:before {
    content: ""
}

.bx-dish:before {
    content: ""
}

.bx-dislike:before {
    content: ""
}

.bx-dizzy:before {
    content: ""
}

.bx-dna:before {
    content: ""
}

.bx-dock-bottom:before {
    content: ""
}

.bx-dock-left:before {
    content: ""
}

.bx-dock-right:before {
    content: ""
}

.bx-dock-top:before {
    content: ""
}

.bx-dollar:before {
    content: ""
}

.bx-dollar-circle:before {
    content: ""
}

.bx-donate-blood:before {
    content: ""
}

.bx-donate-heart:before {
    content: ""
}

.bx-door-open:before {
    content: ""
}

.bx-dots-horizontal:before {
    content: ""
}

.bx-dots-horizontal-rounded:before {
    content: ""
}

.bx-dots-vertical:before {
    content: ""
}

.bx-dots-vertical-rounded:before {
    content: ""
}

.bx-doughnut-chart:before {
    content: ""
}

.bx-down-arrow:before {
    content: ""
}

.bx-down-arrow-alt:before {
    content: ""
}

.bx-down-arrow-circle:before {
    content: ""
}

.bx-download:before {
    content: ""
}

.bx-downvote:before {
    content: ""
}

.bx-drink:before {
    content: ""
}

.bx-droplet:before {
    content: ""
}

.bx-dumbbell:before {
    content: ""
}

.bx-duplicate:before {
    content: ""
}

.bx-edit:before {
    content: ""
}

.bx-edit-alt:before {
    content: ""
}

.bx-envelope:before {
    content: ""
}

.bx-envelope-open:before {
    content: ""
}

.bx-equalizer:before {
    content: ""
}

.bx-eraser:before {
    content: ""
}

.bx-error:before {
    content: ""
}

.bx-error-alt:before {
    content: ""
}

.bx-error-circle:before {
    content: ""
}

.bx-euro:before {
    content: ""
}

.bx-exclude:before {
    content: ""
}

.bx-exit:before {
    content: ""
}

.bx-exit-fullscreen:before {
    content: ""
}

.bx-expand:before {
    content: ""
}

.bx-expand-alt:before {
    content: ""
}

.bx-export:before {
    content: ""
}

.bx-extension:before {
    content: ""
}

.bx-face:before {
    content: ""
}

.bx-fast-forward:before {
    content: ""
}

.bx-fast-forward-circle:before {
    content: ""
}

.bx-female:before {
    content: ""
}

.bx-female-sign:before {
    content: ""
}

.bx-file:before {
    content: ""
}

.bx-file-blank:before {
    content: ""
}

.bx-file-find:before {
    content: ""
}

.bx-film:before {
    content: ""
}

.bx-filter:before {
    content: ""
}

.bx-filter-alt:before {
    content: ""
}

.bx-fingerprint:before {
    content: ""
}

.bx-first-aid:before {
    content: ""
}

.bx-first-page:before {
    content: ""
}

.bx-flag:before {
    content: ""
}

.bx-folder:before {
    content: ""
}

.bx-folder-minus:before {
    content: ""
}

.bx-folder-open:before {
    content: ""
}

.bx-folder-plus:before {
    content: ""
}

.bx-font:before {
    content: ""
}

.bx-font-color:before {
    content: ""
}

.bx-font-family:before {
    content: ""
}

.bx-font-size:before {
    content: ""
}

.bx-food-menu:before {
    content: ""
}

.bx-food-tag:before {
    content: ""
}

.bx-football:before {
    content: ""
}

.bx-fridge:before {
    content: ""
}

.bx-fullscreen:before {
    content: ""
}

.bx-game:before {
    content: ""
}

.bx-gas-pump:before {
    content: ""
}

.bx-ghost:before {
    content: ""
}

.bx-gift:before {
    content: ""
}

.bx-git-branch:before {
    content: ""
}

.bx-git-commit:before {
    content: ""
}

.bx-git-compare:before {
    content: ""
}

.bx-git-merge:before {
    content: ""
}

.bx-git-pull-request:before {
    content: ""
}

.bx-git-repo-forked:before {
    content: ""
}

.bx-glasses:before {
    content: ""
}

.bx-glasses-alt:before {
    content: ""
}

.bx-globe:before {
    content: ""
}

.bx-globe-alt:before {
    content: ""
}

.bx-grid:before {
    content: ""
}

.bx-grid-alt:before {
    content: ""
}

.bx-grid-horizontal:before {
    content: ""
}

.bx-grid-small:before {
    content: ""
}

.bx-grid-vertical:before {
    content: ""
}

.bx-group:before {
    content: ""
}

.bx-handicap:before {
    content: ""
}

.bx-happy:before {
    content: ""
}

.bx-happy-alt:before {
    content: ""
}

.bx-happy-beaming:before {
    content: ""
}

.bx-happy-heart-eyes:before {
    content: ""
}

.bx-hash:before {
    content: ""
}

.bx-hdd:before {
    content: ""
}

.bx-heading:before {
    content: ""
}

.bx-headphone:before {
    content: ""
}

.bx-health:before {
    content: ""
}

.bx-heart:before {
    content: ""
}

.bx-heart-circle:before {
    content: ""
}

.bx-heart-square:before {
    content: ""
}

.bx-help-circle:before {
    content: ""
}

.bx-hide:before {
    content: ""
}

.bx-highlight:before {
    content: ""
}

.bx-history:before {
    content: ""
}

.bx-hive:before {
    content: ""
}

.bx-home:before {
    content: ""
}

.bx-home-alt:before {
    content: ""
}

.bx-home-circle:before {
    content: ""
}

.bx-home-heart:before {
    content: ""
}

.bx-home-smile:before {
    content: ""
}

.bx-horizontal-center:before {
    content: ""
}

.bx-hotel:before {
    content: ""
}

.bx-hourglass:before {
    content: ""
}

.bx-id-card:before {
    content: ""
}

.bx-image:before {
    content: ""
}

.bx-image-add:before {
    content: ""
}

.bx-image-alt:before {
    content: ""
}

.bx-images:before {
    content: ""
}

.bx-import:before {
    content: ""
}

.bx-infinite:before {
    content: ""
}

.bx-info-circle:before {
    content: ""
}

.bx-info-square:before {
    content: ""
}

.bx-intersect:before {
    content: ""
}

.bx-italic:before {
    content: ""
}

.bx-joystick:before {
    content: ""
}

.bx-joystick-alt:before {
    content: ""
}

.bx-joystick-button:before {
    content: ""
}

.bx-key:before {
    content: ""
}

.bx-label:before {
    content: ""
}

.bx-landscape:before {
    content: ""
}

.bx-laptop:before {
    content: ""
}

.bx-last-page:before {
    content: ""
}

.bx-laugh:before {
    content: ""
}

.bx-layer:before {
    content: ""
}

.bx-layer-minus:before {
    content: ""
}

.bx-layer-plus:before {
    content: ""
}

.bx-layout:before {
    content: ""
}

.bx-left-arrow:before {
    content: ""
}

.bx-left-arrow-alt:before {
    content: ""
}

.bx-left-arrow-circle:before {
    content: ""
}

.bx-left-down-arrow-circle:before {
    content: ""
}

.bx-left-indent:before {
    content: ""
}

.bx-left-top-arrow-circle:before {
    content: ""
}

.bx-library:before {
    content: ""
}

.bx-like:before {
    content: ""
}

.bx-line-chart:before {
    content: ""
}

.bx-line-chart-down:before {
    content: ""
}

.bx-link:before {
    content: ""
}

.bx-link-alt:before {
    content: ""
}

.bx-link-external:before {
    content: ""
}

.bx-lira:before {
    content: ""
}

.bx-list-check:before {
    content: ""
}

.bx-list-minus:before {
    content: ""
}

.bx-list-ol:before {
    content: ""
}

.bx-list-plus:before {
    content: ""
}

.bx-list-ul:before {
    content: ""
}

.bx-loader:before {
    content: ""
}

.bx-loader-alt:before {
    content: ""
}

.bx-loader-circle:before {
    content: ""
}

.bx-location-plus:before {
    content: ""
}

.bx-lock:before {
    content: ""
}

.bx-lock-alt:before {
    content: ""
}

.bx-lock-open:before {
    content: ""
}

.bx-lock-open-alt:before {
    content: ""
}

.bx-log-in:before {
    content: ""
}

.bx-log-in-circle:before {
    content: ""
}

.bx-log-out:before {
    content: ""
}

.bx-log-out-circle:before {
    content: ""
}

.bx-low-vision:before {
    content: ""
}

.bx-magnet:before {
    content: ""
}

.bx-mail-send:before {
    content: ""
}

.bx-male:before {
    content: ""
}

.bx-male-sign:before {
    content: ""
}

.bx-map:before {
    content: ""
}

.bx-map-alt:before {
    content: ""
}

.bx-map-pin:before {
    content: ""
}

.bx-mask:before {
    content: ""
}

.bx-medal:before {
    content: ""
}

.bx-meh:before {
    content: ""
}

.bx-meh-alt:before {
    content: ""
}

.bx-meh-blank:before {
    content: ""
}

.bx-memory-card:before {
    content: ""
}

.bx-menu:before {
    content: ""
}

.bx-menu-alt-left:before {
    content: ""
}

.bx-menu-alt-right:before {
    content: ""
}

.bx-merge:before {
    content: ""
}

.bx-message:before {
    content: ""
}

.bx-message-add:before {
    content: ""
}

.bx-message-alt:before {
    content: ""
}

.bx-message-alt-add:before {
    content: ""
}

.bx-message-alt-check:before {
    content: ""
}

.bx-message-alt-detail:before {
    content: ""
}

.bx-message-alt-dots:before {
    content: ""
}

.bx-message-alt-edit:before {
    content: ""
}

.bx-message-alt-error:before {
    content: ""
}

.bx-message-alt-minus:before {
    content: ""
}

.bx-message-alt-x:before {
    content: ""
}

.bx-message-check:before {
    content: ""
}

.bx-message-detail:before {
    content: ""
}

.bx-message-dots:before {
    content: ""
}

.bx-message-edit:before {
    content: ""
}

.bx-message-error:before {
    content: ""
}

.bx-message-minus:before {
    content: ""
}

.bx-message-rounded:before {
    content: ""
}

.bx-message-rounded-add:before {
    content: ""
}

.bx-message-rounded-check:before {
    content: ""
}

.bx-message-rounded-detail:before {
    content: ""
}

.bx-message-rounded-dots:before {
    content: ""
}

.bx-message-rounded-edit:before {
    content: ""
}

.bx-message-rounded-error:before {
    content: ""
}

.bx-message-rounded-minus:before {
    content: ""
}

.bx-message-rounded-x:before {
    content: ""
}

.bx-message-square:before {
    content: ""
}

.bx-message-square-add:before {
    content: ""
}

.bx-message-square-check:before {
    content: ""
}

.bx-message-square-detail:before {
    content: ""
}

.bx-message-square-dots:before {
    content: ""
}

.bx-message-square-edit:before {
    content: ""
}

.bx-message-square-error:before {
    content: ""
}

.bx-message-square-minus:before {
    content: ""
}

.bx-message-square-x:before {
    content: ""
}

.bx-message-x:before {
    content: ""
}

.bx-meteor:before {
    content: ""
}

.bx-microchip:before {
    content: ""
}

.bx-microphone:before {
    content: ""
}

.bx-microphone-off:before {
    content: ""
}

.bx-minus:before {
    content: ""
}

.bx-minus-back:before {
    content: ""
}

.bx-minus-circle:before {
    content: ""
}

.bx-minus-front:before {
    content: ""
}

.bx-mobile:before {
    content: ""
}

.bx-mobile-alt:before {
    content: ""
}

.bx-mobile-landscape:before {
    content: ""
}

.bx-mobile-vibration:before {
    content: ""
}

.bx-money:before {
    content: ""
}

.bx-moon:before {
    content: ""
}

.bx-mouse:before {
    content: ""
}

.bx-mouse-alt:before {
    content: ""
}

.bx-move:before {
    content: ""
}

.bx-move-horizontal:before {
    content: ""
}

.bx-move-vertical:before {
    content: ""
}

.bx-movie:before {
    content: ""
}

.bx-movie-play:before {
    content: ""
}

.bx-music:before {
    content: ""
}

.bx-navigation:before {
    content: ""
}

.bx-network-chart:before {
    content: ""
}

.bx-news:before {
    content: ""
}

.bx-no-entry:before {
    content: ""
}

.bx-note:before {
    content: ""
}

.bx-notepad:before {
    content: ""
}

.bx-notification:before {
    content: ""
}

.bx-notification-off:before {
    content: ""
}

.bx-outline:before {
    content: ""
}

.bx-package:before {
    content: ""
}

.bx-paint:before {
    content: ""
}

.bx-paint-roll:before {
    content: ""
}

.bx-palette:before {
    content: ""
}

.bx-paperclip:before {
    content: ""
}

.bx-paper-plane:before {
    content: ""
}

.bx-paragraph:before {
    content: ""
}

.bx-paste:before {
    content: ""
}

.bx-pause:before {
    content: ""
}

.bx-pause-circle:before {
    content: ""
}

.bx-pen:before {
    content: ""
}

.bx-pencil:before {
    content: ""
}

.bx-phone:before {
    content: ""
}

.bx-phone-call:before {
    content: ""
}

.bx-phone-incoming:before {
    content: ""
}

.bx-phone-off:before {
    content: ""
}

.bx-phone-outgoing:before {
    content: ""
}

.bx-photo-album:before {
    content: ""
}

.bx-pie-chart:before {
    content: ""
}

.bx-pie-chart-alt:before {
    content: ""
}

.bx-pie-chart-alt-2:before {
    content: ""
}

.bx-pin:before {
    content: ""
}

.bx-planet:before {
    content: ""
}

.bx-play:before {
    content: ""
}

.bx-play-circle:before {
    content: ""
}

.bx-plug:before {
    content: ""
}

.bx-plus:before {
    content: ""
}

.bx-plus-circle:before {
    content: ""
}

.bx-plus-medical:before {
    content: ""
}

.bx-podcast:before {
    content: ""
}

.bx-pointer:before {
    content: ""
}

.bx-poll:before {
    content: ""
}

.bx-polygon:before {
    content: ""
}

.bx-pound:before {
    content: ""
}

.bx-power-off:before {
    content: ""
}

.bx-printer:before {
    content: ""
}

.bx-pulse:before {
    content: ""
}

.bx-purchase-tag:before {
    content: ""
}

.bx-purchase-tag-alt:before {
    content: ""
}

.bx-pyramid:before {
    content: ""
}

.bx-qr:before {
    content: ""
}

.bx-qr-scan:before {
    content: ""
}

.bx-question-mark:before {
    content: ""
}

.bx-radar:before {
    content: ""
}

.bx-radio:before {
    content: ""
}

.bx-radio-circle:before {
    content: ""
}

.bx-radio-circle-marked:before {
    content: ""
}

.bx-receipt:before {
    content: ""
}

.bx-rectangle:before {
    content: ""
}

.bx-recycle:before {
    content: ""
}

.bx-redo:before {
    content: ""
}

.bx-refresh:before {
    content: ""
}

.bx-registered:before {
    content: ""
}

.bx-rename:before {
    content: ""
}

.bx-repeat:before {
    content: ""
}

.bx-reply:before {
    content: ""
}

.bx-reply-all:before {
    content: ""
}

.bx-repost:before {
    content: ""
}

.bx-reset:before {
    content: ""
}

.bx-restaurant:before {
    content: ""
}

.bx-revision:before {
    content: ""
}

.bx-rewind:before {
    content: ""
}

.bx-rewind-circle:before {
    content: ""
}

.bx-right-arrow:before {
    content: ""
}

.bx-right-arrow-alt:before {
    content: ""
}

.bx-right-arrow-circle:before {
    content: ""
}

.bx-right-down-arrow-circle:before {
    content: ""
}

.bx-right-indent:before {
    content: ""
}

.bx-right-top-arrow-circle:before {
    content: ""
}

.bx-rocket:before {
    content: ""
}

.bx-rotate-left:before {
    content: ""
}

.bx-rotate-right:before {
    content: ""
}

.bx-rss:before {
    content: ""
}

.bx-ruble:before {
    content: ""
}

.bx-ruler:before {
    content: ""
}

.bx-run:before {
    content: ""
}

.bx-rupee:before {
    content: ""
}

.bx-sad:before {
    content: ""
}

.bx-save:before {
    content: ""
}

.bx-scan:before {
    content: ""
}

.bx-screenshot:before {
    content: ""
}

.bx-search:before {
    content: ""
}

.bx-search-alt:before {
    content: ""
}

.bx-search-alt-2:before {
    content: ""
}

.bx-selection:before {
    content: ""
}

.bx-select-multiple:before {
    content: ""
}

.bx-send:before {
    content: ""
}

.bx-server:before {
    content: ""
}

.bx-shape-circle:before {
    content: ""
}

.bx-shape-polygon:before {
    content: ""
}

.bx-shape-square:before {
    content: ""
}

.bx-shape-triangle:before {
    content: ""
}

.bx-share:before {
    content: ""
}

.bx-share-alt:before {
    content: ""
}

.bx-shekel:before {
    content: ""
}

.bx-shield:before {
    content: ""
}

.bx-shield-alt:before {
    content: ""
}

.bx-shield-alt-2:before {
    content: ""
}

.bx-shield-quarter:before {
    content: ""
}

.bx-shield-x:before {
    content: ""
}

.bx-shocked:before {
    content: ""
}

.bx-shopping-bag:before {
    content: ""
}

.bx-show:before {
    content: ""
}

.bx-show-alt:before {
    content: ""
}

.bx-shuffle:before {
    content: ""
}

.bx-sidebar:before {
    content: ""
}

.bx-sitemap:before {
    content: ""
}

.bx-skip-next:before {
    content: ""
}

.bx-skip-next-circle:before {
    content: ""
}

.bx-skip-previous:before {
    content: ""
}

.bx-skip-previous-circle:before {
    content: ""
}

.bx-sleepy:before {
    content: ""
}

.bx-slider:before {
    content: ""
}

.bx-slider-alt:before {
    content: ""
}

.bx-slideshow:before {
    content: ""
}

.bx-smile:before {
    content: ""
}

.bx-sort:before {
    content: ""
}

.bx-sort-alt-2:before {
    content: ""
}

.bx-sort-a-z:before {
    content: ""
}

.bx-sort-down:before {
    content: ""
}

.bx-sort-up:before {
    content: ""
}

.bx-sort-z-a:before {
    content: ""
}

.bx-spa:before {
    content: ""
}

.bx-space-bar:before {
    content: ""
}

.bx-speaker:before {
    content: ""
}

.bx-spray-can:before {
    content: ""
}

.bx-spreadsheet:before {
    content: ""
}

.bx-square:before {
    content: ""
}

.bx-square-rounded:before {
    content: ""
}

.bx-star:before {
    content: ""
}

.bx-station:before {
    content: ""
}

.bx-stats:before {
    content: ""
}

.bx-sticker:before {
    content: ""
}

.bx-stop:before {
    content: ""
}

.bx-stop-circle:before {
    content: ""
}

.bx-stopwatch:before {
    content: ""
}

.bx-store:before {
    content: ""
}

.bx-store-alt:before {
    content: ""
}

.bx-street-view:before {
    content: ""
}

.bx-strikethrough:before {
    content: ""
}

.bx-subdirectory-left:before {
    content: ""
}

.bx-subdirectory-right:before {
    content: ""
}

.bx-sun:before {
    content: ""
}

.bx-support:before {
    content: ""
}

.bx-swim:before {
    content: ""
}

.bx-sync:before {
    content: ""
}

.bx-tab:before {
    content: ""
}

.bx-table:before {
    content: ""
}

.bx-tachometer:before {
    content: ""
}

.bx-tag:before {
    content: ""
}

.bx-tag-alt:before {
    content: ""
}

.bx-target-lock:before {
    content: ""
}

.bx-task:before {
    content: ""
}

.bx-task-x:before {
    content: ""
}

.bx-taxi:before {
    content: ""
}

.bx-tennis-ball:before {
    content: ""
}

.bx-terminal:before {
    content: ""
}

.bx-test-tube:before {
    content: ""
}

.bx-text:before {
    content: ""
}

.bx-time:before {
    content: ""
}

.bx-time-five:before {
    content: ""
}

.bx-timer:before {
    content: ""
}

.bx-tired:before {
    content: ""
}

.bx-toggle-left:before {
    content: ""
}

.bx-toggle-right:before {
    content: ""
}

.bx-tone:before {
    content: ""
}

.bx-traffic-cone:before {
    content: ""
}

.bx-train:before {
    content: ""
}

.bx-transfer:before {
    content: ""
}

.bx-transfer-alt:before {
    content: ""
}

.bx-trash:before {
    content: ""
}

.bx-trash-alt:before {
    content: ""
}

.bx-trending-down:before {
    content: ""
}

.bx-trending-up:before {
    content: ""
}

.bx-trim:before {
    content: ""
}

.bx-trip:before {
    content: ""
}

.bx-trophy:before {
    content: ""
}

.bx-tv:before {
    content: ""
}

.bx-underline:before {
    content: ""
}

.bx-undo:before {
    content: ""
}

.bx-unite:before {
    content: ""
}

.bx-unlink:before {
    content: ""
}

.bx-up-arrow:before {
    content: ""
}

.bx-up-arrow-alt:before {
    content: ""
}

.bx-up-arrow-circle:before {
    content: ""
}

.bx-upload:before {
    content: ""
}

.bx-upside-down:before {
    content: ""
}

.bx-upvote:before {
    content: ""
}

.bx-usb:before {
    content: ""
}

.bx-user:before {
    content: ""
}

.bx-user-check:before {
    content: ""
}

.bx-user-circle:before {
    content: ""
}

.bx-user-minus:before {
    content: ""
}

.bx-user-pin:before {
    content: ""
}

.bx-user-plus:before {
    content: ""
}

.bx-user-voice:before {
    content: ""
}

.bx-user-x:before {
    content: ""
}

.bx-vector:before {
    content: ""
}

.bx-vertical-center:before {
    content: ""
}

.bx-vial:before {
    content: ""
}

.bx-video:before {
    content: ""
}

.bx-video-off:before {
    content: ""
}

.bx-video-plus:before {
    content: ""
}

.bx-video-recording:before {
    content: ""
}

.bx-voicemail:before {
    content: ""
}

.bx-volume:before {
    content: ""
}

.bx-volume-full:before {
    content: ""
}

.bx-volume-low:before {
    content: ""
}

.bx-volume-mute:before {
    content: ""
}

.bx-walk:before {
    content: ""
}

.bx-wallet:before {
    content: ""
}

.bx-wallet-alt:before {
    content: ""
}

.bx-water:before {
    content: ""
}

.bx-webcam:before {
    content: ""
}

.bx-wifi:before {
    content: ""
}

.bx-wifi-0:before {
    content: ""
}

.bx-wifi-1:before {
    content: ""
}

.bx-wifi-2:before {
    content: ""
}

.bx-wifi-off:before {
    content: ""
}

.bx-wind:before {
    content: ""
}

.bx-window:before {
    content: ""
}

.bx-window-alt:before {
    content: ""
}

.bx-window-close:before {
    content: ""
}

.bx-window-open:before {
    content: ""
}

.bx-windows:before {
    content: ""
}

.bx-wine:before {
    content: ""
}

.bx-wink-smile:before {
    content: ""
}

.bx-wink-tongue:before {
    content: ""
}

.bx-won:before {
    content: ""
}

.bx-world:before {
    content: ""
}

.bx-wrench:before {
    content: ""
}

.bx-x:before {
    content: ""
}

.bx-x-circle:before {
    content: ""
}

.bx-yen:before {
    content: ""
}

.bx-zoom-in:before {
    content: ""
}

.bx-zoom-out:before {
    content: ""
}

.bxs-party:before {
    content: ""
}

.bxs-hot:before {
    content: ""
}

.bxs-droplet:before {
    content: ""
}

.bxs-cat:before {
    content: ""
}

.bxs-dog:before {
    content: ""
}

.bxs-injection:before {
    content: ""
}

.bxs-leaf:before {
    content: ""
}

.bxs-add-to-queue:before {
    content: ""
}

.bxs-adjust:before {
    content: ""
}

.bxs-adjust-alt:before {
    content: ""
}

.bxs-alarm:before {
    content: ""
}

.bxs-alarm-add:before {
    content: ""
}

.bxs-alarm-exclamation:before {
    content: ""
}

.bxs-alarm-off:before {
    content: ""
}

.bxs-alarm-snooze:before {
    content: ""
}

.bxs-album:before {
    content: ""
}

.bxs-ambulance:before {
    content: ""
}

.bxs-analyse:before {
    content: ""
}

.bxs-angry:before {
    content: ""
}

.bxs-arch:before {
    content: ""
}

.bxs-archive:before {
    content: ""
}

.bxs-archive-in:before {
    content: ""
}

.bxs-archive-out:before {
    content: ""
}

.bxs-area:before {
    content: ""
}

.bxs-arrow-from-bottom:before {
    content: ""
}

.bxs-arrow-from-left:before {
    content: ""
}

.bxs-arrow-from-right:before {
    content: ""
}

.bxs-arrow-from-top:before {
    content: ""
}

.bxs-arrow-to-bottom:before {
    content: ""
}

.bxs-arrow-to-left:before {
    content: ""
}

.bxs-arrow-to-right:before {
    content: ""
}

.bxs-arrow-to-top:before {
    content: ""
}

.bxs-award:before {
    content: ""
}

.bxs-baby-carriage:before {
    content: ""
}

.bxs-backpack:before {
    content: ""
}

.bxs-badge:before {
    content: ""
}

.bxs-badge-check:before {
    content: ""
}

.bxs-badge-dollar:before {
    content: ""
}

.bxs-ball:before {
    content: ""
}

.bxs-band-aid:before {
    content: ""
}

.bxs-bank:before {
    content: ""
}

.bxs-bar-chart-alt-2:before {
    content: ""
}

.bxs-bar-chart-square:before {
    content: ""
}

.bxs-barcode:before {
    content: ""
}

.bxs-baseball:before {
    content: ""
}

.bxs-basket:before {
    content: ""
}

.bxs-basketball:before {
    content: ""
}

.bxs-bath:before {
    content: ""
}

.bxs-battery:before {
    content: ""
}

.bxs-battery-charging:before {
    content: ""
}

.bxs-battery-full:before {
    content: ""
}

.bxs-battery-low:before {
    content: ""
}

.bxs-bed:before {
    content: ""
}

.bxs-been-here:before {
    content: ""
}

.bxs-beer:before {
    content: ""
}

.bxs-bell:before {
    content: ""
}

.bxs-bell-minus:before {
    content: ""
}

.bxs-bell-off:before {
    content: ""
}

.bxs-bell-plus:before {
    content: ""
}

.bxs-bell-ring:before {
    content: ""
}

.bxs-bible:before {
    content: ""
}

.bxs-binoculars:before {
    content: ""
}

.bxs-blanket:before {
    content: ""
}

.bxs-bolt:before {
    content: ""
}

.bxs-bolt-circle:before {
    content: ""
}

.bxs-bomb:before {
    content: ""
}

.bxs-bone:before {
    content: ""
}

.bxs-bong:before {
    content: ""
}

.bxs-book:before {
    content: ""
}

.bxs-book-add:before {
    content: ""
}

.bxs-book-alt:before {
    content: ""
}

.bxs-book-bookmark:before {
    content: ""
}

.bxs-book-content:before {
    content: ""
}

.bxs-book-heart:before {
    content: ""
}

.bxs-bookmark:before {
    content: ""
}

.bxs-bookmark-alt:before {
    content: ""
}

.bxs-bookmark-alt-minus:before {
    content: ""
}

.bxs-bookmark-alt-plus:before {
    content: ""
}

.bxs-bookmark-heart:before {
    content: ""
}

.bxs-bookmark-minus:before {
    content: ""
}

.bxs-bookmark-plus:before {
    content: ""
}

.bxs-bookmarks:before {
    content: ""
}

.bxs-bookmark-star:before {
    content: ""
}

.bxs-book-open:before {
    content: ""
}

.bxs-book-reader:before {
    content: ""
}

.bxs-bot:before {
    content: ""
}

.bxs-bowling-ball:before {
    content: ""
}

.bxs-box:before {
    content: ""
}

.bxs-brain:before {
    content: ""
}

.bxs-briefcase:before {
    content: ""
}

.bxs-briefcase-alt:before {
    content: ""
}

.bxs-briefcase-alt-2:before {
    content: ""
}

.bxs-brightness:before {
    content: ""
}

.bxs-brightness-half:before {
    content: ""
}

.bxs-brush:before {
    content: ""
}

.bxs-brush-alt:before {
    content: ""
}

.bxs-bug:before {
    content: ""
}

.bxs-bug-alt:before {
    content: ""
}

.bxs-building:before {
    content: ""
}

.bxs-building-house:before {
    content: ""
}

.bxs-buildings:before {
    content: ""
}

.bxs-bulb:before {
    content: ""
}

.bxs-bullseye:before {
    content: ""
}

.bxs-buoy:before {
    content: ""
}

.bxs-bus:before {
    content: ""
}

.bxs-business:before {
    content: ""
}

.bxs-bus-school:before {
    content: ""
}

.bxs-cabinet:before {
    content: ""
}

.bxs-cake:before {
    content: ""
}

.bxs-calculator:before {
    content: ""
}

.bxs-calendar:before {
    content: ""
}

.bxs-calendar-alt:before {
    content: ""
}

.bxs-calendar-check:before {
    content: ""
}

.bxs-calendar-edit:before {
    content: ""
}

.bxs-calendar-event:before {
    content: ""
}

.bxs-calendar-exclamation:before {
    content: ""
}

.bxs-calendar-heart:before {
    content: ""
}

.bxs-calendar-minus:before {
    content: ""
}

.bxs-calendar-plus:before {
    content: ""
}

.bxs-calendar-star:before {
    content: ""
}

.bxs-calendar-week:before {
    content: ""
}

.bxs-calendar-x:before {
    content: ""
}

.bxs-camera:before {
    content: ""
}

.bxs-camera-home:before {
    content: ""
}

.bxs-camera-movie:before {
    content: ""
}

.bxs-camera-off:before {
    content: ""
}

.bxs-camera-plus:before {
    content: ""
}

.bxs-capsule:before {
    content: ""
}

.bxs-captions:before {
    content: ""
}

.bxs-car:before {
    content: ""
}

.bxs-car-battery:before {
    content: ""
}

.bxs-car-crash:before {
    content: ""
}

.bxs-card:before {
    content: ""
}

.bxs-caret-down-circle:before {
    content: ""
}

.bxs-caret-down-square:before {
    content: ""
}

.bxs-caret-left-circle:before {
    content: ""
}

.bxs-caret-left-square:before {
    content: ""
}

.bxs-caret-right-circle:before {
    content: ""
}

.bxs-caret-right-square:before {
    content: ""
}

.bxs-caret-up-circle:before {
    content: ""
}

.bxs-caret-up-square:before {
    content: ""
}

.bxs-car-garage:before {
    content: ""
}

.bxs-car-mechanic:before {
    content: ""
}

.bxs-carousel:before {
    content: ""
}

.bxs-cart:before {
    content: ""
}

.bxs-cart-add:before {
    content: ""
}

.bxs-cart-alt:before {
    content: ""
}

.bxs-cart-download:before {
    content: ""
}

.bxs-car-wash:before {
    content: ""
}

.bxs-category:before {
    content: ""
}

.bxs-category-alt:before {
    content: ""
}

.bxs-cctv:before {
    content: ""
}

.bxs-certification:before {
    content: ""
}

.bxs-chalkboard:before {
    content: ""
}

.bxs-chart:before {
    content: ""
}

.bxs-chat:before {
    content: ""
}

.bxs-checkbox:before {
    content: ""
}

.bxs-checkbox-checked:before {
    content: ""
}

.bxs-checkbox-minus:before {
    content: ""
}

.bxs-check-circle:before {
    content: ""
}

.bxs-check-shield:before {
    content: ""
}

.bxs-check-square:before {
    content: ""
}

.bxs-chess:before {
    content: ""
}

.bxs-chevron-down:before {
    content: ""
}

.bxs-chevron-down-circle:before {
    content: ""
}

.bxs-chevron-down-square:before {
    content: ""
}

.bxs-chevron-left:before {
    content: ""
}

.bxs-chevron-left-circle:before {
    content: ""
}

.bxs-chevron-left-square:before {
    content: ""
}

.bxs-chevron-right:before {
    content: ""
}

.bxs-chevron-right-circle:before {
    content: ""
}

.bxs-chevron-right-square:before {
    content: ""
}

.bxs-chevrons-down:before {
    content: ""
}

.bxs-chevrons-left:before {
    content: ""
}

.bxs-chevrons-right:before {
    content: ""
}

.bxs-chevrons-up:before {
    content: ""
}

.bxs-chevron-up:before {
    content: ""
}

.bxs-chevron-up-circle:before {
    content: ""
}

.bxs-chevron-up-square:before {
    content: ""
}

.bxs-chip:before {
    content: ""
}

.bxs-church:before {
    content: ""
}

.bxs-circle:before {
    content: ""
}

.bxs-city:before {
    content: ""
}

.bxs-clinic:before {
    content: ""
}

.bxs-cloud:before {
    content: ""
}

.bxs-cloud-download:before {
    content: ""
}

.bxs-cloud-lightning:before {
    content: ""
}

.bxs-cloud-rain:before {
    content: ""
}

.bxs-cloud-upload:before {
    content: ""
}

.bxs-coffee:before {
    content: ""
}

.bxs-coffee-alt:before {
    content: ""
}

.bxs-coffee-togo:before {
    content: ""
}

.bxs-cog:before {
    content: ""
}

.bxs-coin:before {
    content: ""
}

.bxs-coin-stack:before {
    content: ""
}

.bxs-collection:before {
    content: ""
}

.bxs-color-fill:before {
    content: ""
}

.bxs-comment:before {
    content: ""
}

.bxs-comment-add:before {
    content: ""
}

.bxs-comment-check:before {
    content: ""
}

.bxs-comment-detail:before {
    content: ""
}

.bxs-comment-dots:before {
    content: ""
}

.bxs-comment-edit:before {
    content: ""
}

.bxs-comment-error:before {
    content: ""
}

.bxs-comment-minus:before {
    content: ""
}

.bxs-comment-x:before {
    content: ""
}

.bxs-compass:before {
    content: ""
}

.bxs-component:before {
    content: ""
}

.bxs-confused:before {
    content: ""
}

.bxs-contact:before {
    content: ""
}

.bxs-conversation:before {
    content: ""
}

.bxs-cookie:before {
    content: ""
}

.bxs-cool:before {
    content: ""
}

.bxs-copy:before {
    content: ""
}

.bxs-copy-alt:before {
    content: ""
}

.bxs-copyright:before {
    content: ""
}

.bxs-coupon:before {
    content: ""
}

.bxs-credit-card:before {
    content: ""
}

.bxs-credit-card-alt:before {
    content: ""
}

.bxs-credit-card-front:before {
    content: ""
}

.bxs-crop:before {
    content: ""
}

.bxs-crown:before {
    content: ""
}

.bxs-cube:before {
    content: ""
}

.bxs-cube-alt:before {
    content: ""
}

.bxs-cuboid:before {
    content: ""
}

.bxs-customize:before {
    content: ""
}

.bxs-cylinder:before {
    content: ""
}

.bxs-dashboard:before {
    content: ""
}

.bxs-data:before {
    content: ""
}

.bxs-detail:before {
    content: ""
}

.bxs-devices:before {
    content: ""
}

.bxs-diamond:before {
    content: ""
}

.bxs-dice-1:before {
    content: ""
}

.bxs-dice-2:before {
    content: ""
}

.bxs-dice-3:before {
    content: ""
}

.bxs-dice-4:before {
    content: ""
}

.bxs-dice-5:before {
    content: ""
}

.bxs-dice-6:before {
    content: ""
}

.bxs-direction-left:before {
    content: ""
}

.bxs-direction-right:before {
    content: ""
}

.bxs-directions:before {
    content: ""
}

.bxs-disc:before {
    content: ""
}

.bxs-discount:before {
    content: ""
}

.bxs-dish:before {
    content: ""
}

.bxs-dislike:before {
    content: ""
}

.bxs-dizzy:before {
    content: ""
}

.bxs-dock-bottom:before {
    content: ""
}

.bxs-dock-left:before {
    content: ""
}

.bxs-dock-right:before {
    content: ""
}

.bxs-dock-top:before {
    content: ""
}

.bxs-dollar-circle:before {
    content: ""
}

.bxs-donate-blood:before {
    content: ""
}

.bxs-donate-heart:before {
    content: ""
}

.bxs-door-open:before {
    content: ""
}

.bxs-doughnut-chart:before {
    content: ""
}

.bxs-down-arrow:before {
    content: ""
}

.bxs-down-arrow-alt:before {
    content: ""
}

.bxs-down-arrow-circle:before {
    content: ""
}

.bxs-down-arrow-square:before {
    content: ""
}

.bxs-download:before {
    content: ""
}

.bxs-downvote:before {
    content: ""
}

.bxs-drink:before {
    content: ""
}

.bxs-droplet-half:before {
    content: ""
}

.bxs-dryer:before {
    content: ""
}

.bxs-duplicate:before {
    content: ""
}

.bxs-edit:before {
    content: ""
}

.bxs-edit-alt:before {
    content: ""
}

.bxs-edit-location:before {
    content: ""
}

.bxs-eject:before {
    content: ""
}

.bxs-envelope:before {
    content: ""
}

.bxs-envelope-open:before {
    content: ""
}

.bxs-eraser:before {
    content: ""
}

.bxs-error:before {
    content: ""
}

.bxs-error-alt:before {
    content: ""
}

.bxs-error-circle:before {
    content: ""
}

.bxs-ev-station:before {
    content: ""
}

.bxs-exit:before {
    content: ""
}

.bxs-extension:before {
    content: ""
}

.bxs-eyedropper:before {
    content: ""
}

.bxs-face:before {
    content: ""
}

.bxs-face-mask:before {
    content: ""
}

.bxs-factory:before {
    content: ""
}

.bxs-fast-forward-circle:before {
    content: ""
}

.bxs-file:before {
    content: ""
}

.bxs-file-archive:before {
    content: ""
}

.bxs-file-blank:before {
    content: ""
}

.bxs-file-css:before {
    content: ""
}

.bxs-file-doc:before {
    content: ""
}

.bxs-file-export:before {
    content: ""
}

.bxs-file-find:before {
    content: ""
}

.bxs-file-gif:before {
    content: ""
}

.bxs-file-html:before {
    content: ""
}

.bxs-file-image:before {
    content: ""
}

.bxs-file-import:before {
    content: ""
}

.bxs-file-jpg:before {
    content: ""
}

.bxs-file-js:before {
    content: ""
}

.bxs-file-json:before {
    content: ""
}

.bxs-file-md:before {
    content: ""
}

.bxs-file-pdf:before {
    content: ""
}

.bxs-file-plus:before {
    content: ""
}

.bxs-file-png:before {
    content: ""
}

.bxs-file-txt:before {
    content: ""
}

.bxs-film:before {
    content: ""
}

.bxs-filter-alt:before {
    content: ""
}

.bxs-first-aid:before {
    content: ""
}

.bxs-flag:before {
    content: ""
}

.bxs-flag-alt:before {
    content: ""
}

.bxs-flag-checkered:before {
    content: ""
}

.bxs-flame:before {
    content: ""
}

.bxs-flask:before {
    content: ""
}

.bxs-florist:before {
    content: ""
}

.bxs-folder:before {
    content: ""
}

.bxs-folder-minus:before {
    content: ""
}

.bxs-folder-open:before {
    content: ""
}

.bxs-folder-plus:before {
    content: ""
}

.bxs-food-menu:before {
    content: ""
}

.bxs-fridge:before {
    content: ""
}

.bxs-game:before {
    content: ""
}

.bxs-gas-pump:before {
    content: ""
}

.bxs-ghost:before {
    content: ""
}

.bxs-gift:before {
    content: ""
}

.bxs-graduation:before {
    content: ""
}

.bxs-grid:before {
    content: ""
}

.bxs-grid-alt:before {
    content: ""
}

.bxs-group:before {
    content: ""
}

.bxs-guitar-amp:before {
    content: ""
}

.bxs-hand:before {
    content: ""
}

.bxs-hand-down:before {
    content: ""
}

.bxs-hand-left:before {
    content: ""
}

.bxs-hand-right:before {
    content: ""
}

.bxs-hand-up:before {
    content: ""
}

.bxs-happy:before {
    content: ""
}

.bxs-happy-alt:before {
    content: ""
}

.bxs-happy-beaming:before {
    content: ""
}

.bxs-happy-heart-eyes:before {
    content: ""
}

.bxs-hdd:before {
    content: ""
}

.bxs-heart:before {
    content: ""
}

.bxs-heart-circle:before {
    content: ""
}

.bxs-heart-square:before {
    content: ""
}

.bxs-help-circle:before {
    content: ""
}

.bxs-hide:before {
    content: ""
}

.bxs-home:before {
    content: ""
}

.bxs-home-circle:before {
    content: ""
}

.bxs-home-heart:before {
    content: ""
}

.bxs-home-smile:before {
    content: ""
}

.bxs-hotel:before {
    content: ""
}

.bxs-hourglass:before {
    content: ""
}

.bxs-hourglass-bottom:before {
    content: ""
}

.bxs-hourglass-top:before {
    content: ""
}

.bxs-id-card:before {
    content: ""
}

.bxs-image:before {
    content: ""
}

.bxs-image-add:before {
    content: ""
}

.bxs-image-alt:before {
    content: ""
}

.bxs-inbox:before {
    content: ""
}

.bxs-info-circle:before {
    content: ""
}

.bxs-info-square:before {
    content: ""
}

.bxs-institution:before {
    content: ""
}

.bxs-joystick:before {
    content: ""
}

.bxs-joystick-alt:before {
    content: ""
}

.bxs-joystick-button:before {
    content: ""
}

.bxs-key:before {
    content: ""
}

.bxs-keyboard:before {
    content: ""
}

.bxs-label:before {
    content: ""
}

.bxs-landmark:before {
    content: ""
}

.bxs-landscape:before {
    content: ""
}

.bxs-laugh:before {
    content: ""
}

.bxs-layer:before {
    content: ""
}

.bxs-layer-minus:before {
    content: ""
}

.bxs-layer-plus:before {
    content: ""
}

.bxs-layout:before {
    content: ""
}

.bxs-left-arrow:before {
    content: ""
}

.bxs-left-arrow-alt:before {
    content: ""
}

.bxs-left-arrow-circle:before {
    content: ""
}

.bxs-left-arrow-square:before {
    content: ""
}

.bxs-left-down-arrow-circle:before {
    content: ""
}

.bxs-left-top-arrow-circle:before {
    content: ""
}

.bxs-like:before {
    content: ""
}

.bxs-location-plus:before {
    content: ""
}

.bxs-lock:before {
    content: ""
}

.bxs-lock-alt:before {
    content: ""
}

.bxs-lock-open:before {
    content: ""
}

.bxs-lock-open-alt:before {
    content: ""
}

.bxs-log-in:before {
    content: ""
}

.bxs-log-in-circle:before {
    content: ""
}

.bxs-log-out:before {
    content: ""
}

.bxs-log-out-circle:before {
    content: ""
}

.bxs-low-vision:before {
    content: ""
}

.bxs-magic-wand:before {
    content: ""
}

.bxs-magnet:before {
    content: ""
}

.bxs-map:before {
    content: ""
}

.bxs-map-alt:before {
    content: ""
}

.bxs-map-pin:before {
    content: ""
}

.bxs-mask:before {
    content: ""
}

.bxs-medal:before {
    content: ""
}

.bxs-megaphone:before {
    content: ""
}

.bxs-meh:before {
    content: ""
}

.bxs-meh-alt:before {
    content: ""
}

.bxs-meh-blank:before {
    content: ""
}

.bxs-memory-card:before {
    content: ""
}

.bxs-message:before {
    content: ""
}

.bxs-message-add:before {
    content: ""
}

.bxs-message-alt:before {
    content: ""
}

.bxs-message-alt-add:before {
    content: ""
}

.bxs-message-alt-check:before {
    content: ""
}

.bxs-message-alt-detail:before {
    content: ""
}

.bxs-message-alt-dots:before {
    content: ""
}

.bxs-message-alt-edit:before {
    content: ""
}

.bxs-message-alt-error:before {
    content: ""
}

.bxs-message-alt-minus:before {
    content: ""
}

.bxs-message-alt-x:before {
    content: ""
}

.bxs-message-check:before {
    content: ""
}

.bxs-message-detail:before {
    content: ""
}

.bxs-message-dots:before {
    content: ""
}

.bxs-message-edit:before {
    content: ""
}

.bxs-message-error:before {
    content: ""
}

.bxs-message-minus:before {
    content: ""
}

.bxs-message-rounded:before {
    content: ""
}

.bxs-message-rounded-add:before {
    content: ""
}

.bxs-message-rounded-check:before {
    content: ""
}

.bxs-message-rounded-detail:before {
    content: ""
}

.bxs-message-rounded-dots:before {
    content: ""
}

.bxs-message-rounded-edit:before {
    content: ""
}

.bxs-message-rounded-error:before {
    content: ""
}

.bxs-message-rounded-minus:before {
    content: ""
}

.bxs-message-rounded-x:before {
    content: ""
}

.bxs-message-square:before {
    content: ""
}

.bxs-message-square-add:before {
    content: ""
}

.bxs-message-square-check:before {
    content: ""
}

.bxs-message-square-detail:before {
    content: ""
}

.bxs-message-square-dots:before {
    content: ""
}

.bxs-message-square-edit:before {
    content: ""
}

.bxs-message-square-error:before {
    content: ""
}

.bxs-message-square-minus:before {
    content: ""
}

.bxs-message-square-x:before {
    content: ""
}

.bxs-message-x:before {
    content: ""
}

.bxs-meteor:before {
    content: ""
}

.bxs-microchip:before {
    content: ""
}

.bxs-microphone:before {
    content: ""
}

.bxs-microphone-alt:before {
    content: ""
}

.bxs-microphone-off:before {
    content: ""
}

.bxs-minus-circle:before {
    content: ""
}

.bxs-minus-square:before {
    content: ""
}

.bxs-mobile:before {
    content: ""
}

.bxs-mobile-vibration:before {
    content: ""
}

.bxs-moon:before {
    content: ""
}

.bxs-mouse:before {
    content: ""
}

.bxs-mouse-alt:before {
    content: ""
}

.bxs-movie:before {
    content: ""
}

.bxs-movie-play:before {
    content: ""
}

.bxs-music:before {
    content: ""
}

.bxs-navigation:before {
    content: ""
}

.bxs-network-chart:before {
    content: ""
}

.bxs-news:before {
    content: ""
}

.bxs-no-entry:before {
    content: ""
}

.bxs-note:before {
    content: ""
}

.bxs-notepad:before {
    content: ""
}

.bxs-notification:before {
    content: ""
}

.bxs-notification-off:before {
    content: ""
}

.bxs-offer:before {
    content: ""
}

.bxs-package:before {
    content: ""
}

.bxs-paint:before {
    content: ""
}

.bxs-paint-roll:before {
    content: ""
}

.bxs-palette:before {
    content: ""
}

.bxs-paper-plane:before {
    content: ""
}

.bxs-parking:before {
    content: ""
}

.bxs-paste:before {
    content: ""
}

.bxs-pen:before {
    content: ""
}

.bxs-pencil:before {
    content: ""
}

.bxs-phone:before {
    content: ""
}

.bxs-phone-call:before {
    content: ""
}

.bxs-phone-incoming:before {
    content: ""
}

.bxs-phone-off:before {
    content: ""
}

.bxs-phone-outgoing:before {
    content: ""
}

.bxs-photo-album:before {
    content: ""
}

.bxs-piano:before {
    content: ""
}

.bxs-pie-chart:before {
    content: ""
}

.bxs-pie-chart-alt:before {
    content: ""
}

.bxs-pie-chart-alt-2:before {
    content: ""
}

.bxs-pin:before {
    content: ""
}

.bxs-pizza:before {
    content: ""
}

.bxs-plane:before {
    content: ""
}

.bxs-plane-alt:before {
    content: ""
}

.bxs-plane-land:before {
    content: ""
}

.bxs-planet:before {
    content: ""
}

.bxs-plane-take-off:before {
    content: ""
}

.bxs-playlist:before {
    content: ""
}

.bxs-plug:before {
    content: ""
}

.bxs-plus-circle:before {
    content: ""
}

.bxs-plus-square:before {
    content: ""
}

.bxs-pointer:before {
    content: ""
}

.bxs-polygon:before {
    content: ""
}

.bxs-printer:before {
    content: ""
}

.bxs-purchase-tag:before {
    content: ""
}

.bxs-purchase-tag-alt:before {
    content: ""
}

.bxs-pyramid:before {
    content: ""
}

.bxs-quote-alt-left:before {
    content: ""
}

.bxs-quote-alt-right:before {
    content: ""
}

.bxs-quote-left:before {
    content: ""
}

.bxs-quote-right:before {
    content: ""
}

.bxs-quote-single-left:before {
    content: ""
}

.bxs-quote-single-right:before {
    content: ""
}

.bxs-radiation:before {
    content: ""
}

.bxs-radio:before {
    content: ""
}

.bxs-receipt:before {
    content: ""
}

.bxs-rectangle:before {
    content: ""
}

.bxs-registered:before {
    content: ""
}

.bxs-rename:before {
    content: ""
}

.bxs-report:before {
    content: ""
}

.bxs-rewind-circle:before {
    content: ""
}

.bxs-right-arrow:before {
    content: ""
}

.bxs-right-arrow-alt:before {
    content: ""
}

.bxs-right-arrow-circle:before {
    content: ""
}

.bxs-right-arrow-square:before {
    content: ""
}

.bxs-right-down-arrow-circle:before {
    content: ""
}

.bxs-right-top-arrow-circle:before {
    content: ""
}

.bxs-rocket:before {
    content: ""
}

.bxs-ruler:before {
    content: ""
}

.bxs-sad:before {
    content: ""
}

.bxs-save:before {
    content: ""
}

.bxs-school:before {
    content: ""
}

.bxs-search:before {
    content: ""
}

.bxs-search-alt-2:before {
    content: ""
}

.bxs-select-multiple:before {
    content: ""
}

.bxs-send:before {
    content: ""
}

.bxs-server:before {
    content: ""
}

.bxs-shapes:before {
    content: ""
}

.bxs-share:before {
    content: ""
}

.bxs-share-alt:before {
    content: ""
}

.bxs-shield:before {
    content: ""
}

.bxs-shield-alt-2:before {
    content: ""
}

.bxs-shield-x:before {
    content: ""
}

.bxs-ship:before {
    content: ""
}

.bxs-shocked:before {
    content: ""
}

.bxs-shopping-bag:before {
    content: ""
}

.bxs-shopping-bag-alt:before {
    content: ""
}

.bxs-shopping-bags:before {
    content: ""
}

.bxs-show:before {
    content: ""
}

.bxs-skip-next-circle:before {
    content: ""
}

.bxs-skip-previous-circle:before {
    content: ""
}

.bxs-skull:before {
    content: ""
}

.bxs-sleepy:before {
    content: ""
}

.bxs-slideshow:before {
    content: ""
}

.bxs-smile:before {
    content: ""
}

.bxs-sort-alt:before {
    content: ""
}

.bxs-spa:before {
    content: ""
}

.bxs-speaker:before {
    content: ""
}

.bxs-spray-can:before {
    content: ""
}

.bxs-spreadsheet:before {
    content: ""
}

.bxs-square:before {
    content: ""
}

.bxs-square-rounded:before {
    content: ""
}

.bxs-star:before {
    content: ""
}

.bxs-star-half:before {
    content: ""
}

.bxs-sticker:before {
    content: ""
}

.bxs-stopwatch:before {
    content: ""
}

.bxs-store:before {
    content: ""
}

.bxs-store-alt:before {
    content: ""
}

.bxs-sun:before {
    content: ""
}

.bxs-tachometer:before {
    content: ""
}

.bxs-tag:before {
    content: ""
}

.bxs-tag-alt:before {
    content: ""
}

.bxs-tag-x:before {
    content: ""
}

.bxs-taxi:before {
    content: ""
}

.bxs-tennis-ball:before {
    content: ""
}

.bxs-terminal:before {
    content: ""
}

.bxs-thermometer:before {
    content: ""
}

.bxs-time:before {
    content: ""
}

.bxs-time-five:before {
    content: ""
}

.bxs-timer:before {
    content: ""
}

.bxs-tired:before {
    content: ""
}

.bxs-toggle-left:before {
    content: ""
}

.bxs-toggle-right:before {
    content: ""
}

.bxs-tone:before {
    content: ""
}

.bxs-torch:before {
    content: ""
}

.bxs-to-top:before {
    content: ""
}

.bxs-traffic:before {
    content: ""
}

.bxs-traffic-barrier:before {
    content: ""
}

.bxs-traffic-cone:before {
    content: ""
}

.bxs-train:before {
    content: ""
}

.bxs-trash:before {
    content: ""
}

.bxs-trash-alt:before {
    content: ""
}

.bxs-tree:before {
    content: ""
}

.bxs-trophy:before {
    content: ""
}

.bxs-truck:before {
    content: ""
}

.bxs-t-shirt:before {
    content: ""
}

.bxs-tv:before {
    content: ""
}

.bxs-up-arrow:before {
    content: ""
}

.bxs-up-arrow-alt:before {
    content: ""
}

.bxs-up-arrow-circle:before {
    content: ""
}

.bxs-up-arrow-square:before {
    content: ""
}

.bxs-upside-down:before {
    content: ""
}

.bxs-upvote:before {
    content: ""
}

.bxs-user:before {
    content: ""
}

.bxs-user-account:before {
    content: ""
}

.bxs-user-badge:before {
    content: ""
}

.bxs-user-check:before {
    content: ""
}

.bxs-user-circle:before {
    content: ""
}

.bxs-user-detail:before {
    content: ""
}

.bxs-user-minus:before {
    content: ""
}

.bxs-user-pin:before {
    content: ""
}

.bxs-user-plus:before {
    content: ""
}

.bxs-user-rectangle:before {
    content: ""
}

.bxs-user-voice:before {
    content: ""
}

.bxs-user-x:before {
    content: ""
}

.bxs-vector:before {
    content: ""
}

.bxs-vial:before {
    content: ""
}

.bxs-video:before {
    content: ""
}

.bxs-video-off:before {
    content: ""
}

.bxs-video-plus:before {
    content: ""
}

.bxs-video-recording:before {
    content: ""
}

.bxs-videos:before {
    content: ""
}

.bxs-virus:before {
    content: ""
}

.bxs-virus-block:before {
    content: ""
}

.bxs-volume:before {
    content: ""
}

.bxs-volume-full:before {
    content: ""
}

.bxs-volume-low:before {
    content: ""
}

.bxs-volume-mute:before {
    content: ""
}

.bxs-wallet:before {
    content: ""
}

.bxs-wallet-alt:before {
    content: ""
}

.bxs-washer:before {
    content: ""
}

.bxs-watch:before {
    content: ""
}

.bxs-watch-alt:before {
    content: ""
}

.bxs-webcam:before {
    content: ""
}

.bxs-widget:before {
    content: ""
}

.bxs-window-alt:before {
    content: ""
}

.bxs-wine:before {
    content: ""
}

.bxs-wink-smile:before {
    content: ""
}

.bxs-wink-tongue:before {
    content: ""
}

.bxs-wrench:before {
    content: ""
}

.bxs-x-circle:before {
    content: ""
}

.bxs-x-square:before {
    content: ""
}

.bxs-yin-yang:before {
    content: ""
}

.bxs-zap:before {
    content: ""
}

.bxs-zoom-in:before {
    content: ""
}

.bxs-zoom-out:before {
    content: ""
}

.bx {
    vertical-align: middle;
    font-size: 1.25rem;
    line-height: 1
}

@font-face {
    font-family: "boxicons";
    font-weight: normal;
    font-style: normal;
    src: url("./boxicons/boxicons.eot");
    src: url("./boxicons/boxicons.eot") format("embedded-opentype"), url("./boxicons/boxicons.woff2") format("woff2"), url("./boxicons/boxicons.woff") format("woff"), url("./boxicons/boxicons.ttf") format("truetype"), url("./boxicons/boxiconsd41d.svg?#boxicons") format("svg")
}

.bx-xs {
    font-size: 1rem !important
}

.bx-sm {
    font-size: 1.125rem !important
}

.bx-md {
    font-size: 1.375rem !important
}

.bx-lg {
    font-size: 1.5rem !important
}

.bx-6px, .bx-6px:before {
    font-size: 6px
}

.bx-8px, .bx-8px:before {
    font-size: 8px
}

.bx-10px, .bx-10px:before {
    font-size: 10px
}

.bx-12px, .bx-12px:before {
    font-size: 12px
}

.bx-14px, .bx-14px:before {
    font-size: 14px
}

.bx-16px, .bx-16px:before {
    font-size: 16px
}

.bx-18px, .bx-18px:before {
    font-size: 18px
}

.bx-20px, .bx-20px:before {
    font-size: 20px
}

.bx-22px, .bx-22px:before {
    font-size: 22px
}

.bx-24px, .bx-24px:before {
    font-size: 24px
}

.bx-26px, .bx-26px:before {
    font-size: 26px
}

.bx-28px, .bx-28px:before {
    font-size: 28px
}

.bx-30px, .bx-30px:before {
    font-size: 30px
}

.bx-32px, .bx-32px:before {
    font-size: 32px
}

.bx-36px, .bx-36px:before {
    font-size: 36px
}

.bx-40px, .bx-40px:before {
    font-size: 40px
}

.bx-42px, .bx-42px:before {
    font-size: 42px
}

.bx-48px, .bx-48px:before {
    font-size: 48px
}
