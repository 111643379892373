.pagination-box {
    margin-top: 0.3rem !important;
    padding-right: 20px;
}

.rc-pagination {
    margin-bottom: 1rem !important;
}

.rc-pagination-item-active {
    border-color: #696cff;
    background-color: #696cff !important;
    box-shadow: 0 .125rem .25rem 0 rgba(105, 108, 255, .4);
}

.rc-pagination-item-active a {
    color: #fff !important;
}

.rc-pagination-item {
    background-color: rgba(34, 48, 62, 0.06);
    border-color: rgba(0, 0, 0, 0);
    text-align: center;
    min-width: calc(2.2505625rem + calc(1px * 2));
    min-height: calc(2.2508625rem + calc(1px * 2));
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
}

.rc-pagination-item:not(:first-child) {
    margin-left: .375rem;
}

.rc-pagination-item-link {
    text-align: center;
    min-width: calc(2.2505625rem + calc(1px * 2));
    min-height: calc(2.2508625rem + calc(1px * 2));
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
    margin-left: .375rem;

}
