body {
    font: 400 16px Open Sans, Arial, Helvetica, sans-serif;
    color: #262626;
    line-height: 1.9;
}

.wrapall, .boxed-layout {
    background-color: #ffffff;
}

body.page-template-page-blank-php {
    background: #ffffff !important;
}

h1 {
    font: 28px Open Sans, Arial, Helvetica, sans-serif;
    color: #333333;
}

h2 {
    font: 24px Open Sans, Arial, Helvetica, sans-serif;
    color: #333333;
}

h3 {
    font: 20px Open Sans, Arial, Helvetica, sans-serif;
    color: #333333;
}

h4 {
    font: 16px Open Sans, Arial, Helvetica, sans-serif;
    color: #333333;
}

/*h5 {
    font: 14px Open Sans, Arial, Helvetica, sans-serif;
    color: #333333;
}*/

h6 {
    font: 12px Open Sans, Arial, Helvetica, sans-serif;
    color: #333333;
}

.title {
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    font-weight: inherit;
    color: inherit;
}

h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover, a:hover h1, a:hover h2, a:hover h3, a:hover h4, a:hover h5, a:hover h6 {
    color: #333333;
}

a {
    color: #921a1d;
}

a:hover {
    color: #333333;
}

input[type='text'], input[type='password'], input[type='email'], input[type='tel'], textarea, select {
    font-family: Open Sans, Arial, Helvetica, sans-serif;
    font-size: 16px;
}

#sidebar .widget h3 {
    font: 14px Work Sans, Arial, Helvetica, sans-serif;
    color: #444444;
}

.container .twelve.alt.sidebar-right, .container .twelve.alt.sidebar-left, #sidebar.sidebar-right #sidebar-widgets, #sidebar.sidebar-left #sidebar-widgets {
    border-color: #efefef;
}

#topbar {
    background: #f5f5f5;
    color: #777777;
}

#topbar a {
    color: #999999;
}

#topbar a:hover {
    color: #333333;
}

@media only screen and (max-width: 767px) {
    #topbar .topbar-col1 {
        background: #f5f5f5;
    }
}

/* Navigation */
#navigation > ul > li > a {
    font: 600 14px Work Sans, Arial, Helvetica, sans-serif;
    color: #921a1d;
}

#navigation > ul > li:hover > a, #navigation > ul > li > a:hover {
    color: #262626;
}

#navigation li.current-menu-item > a:hover, #navigation li.current-page-ancestor > a:hover, #navigation li.current-menu-ancestor > a:hover, #navigation li.current-menu-parent > a:hover, #navigation li.current_page_ancestor > a:hover, #navigation > ul > li.sfHover > a {
    color: #262626;
}

#navigation li.current-menu-item > a, #navigation li.current-page-ancestor > a, #navigation li.current-menu-ancestor > a, #navigation li.current-menu-parent > a, #navigation li.current_page_ancestor > a {
    color: #262626;
}

#navigation ul li:hover {
    border-color: #262626;
}

#navigation li.current-menu-item, #navigation li.current-page-ancestor, #navigation li.current-menu-ancestor, #navigation li.current-menu-parent, #navigation li.current_page_ancestor {
    border-color: #262626;
}

#navigation .sub-menu {
    background: #262626;
}

#navigation .sub-menu li a {
    font: 13px Work Sans, Arial, Helvetica, sans-serif;
    color: #bbbbbb;
}

#navigation .sub-menu li a:hover {
    color: #ffffff;
}

#navigation .sub-menu li.current_page_item > a, #navigation .sub-menu li.current_page_item > a:hover, #navigation .sub-menu li.current-menu-item > a, #navigation .sub-menu li.current-menu-item > a:hover, #navigation .sub-menu li.current-page-ancestor > a, #navigation .sub-menu li.current-page-ancestor > a:hover, #navigation .sub-menu li.current-menu-ancestor > a, #navigation .sub-menu li.current-menu-ancestor > a:hover, #navigation .sub-menu li.current-menu-parent > a, #navigation .sub-menu li.current-menu-parent > a:hover, #navigation .sub-menu li.current_page_ancestor > a, #navigation .sub-menu li.current_page_ancestor > a:hover {
    color: #ffffff;
}

#navigation .sub-menu li a, #navigation .sub-menu ul li a {
    border-color: #333333;
}

#navigation > ul > li.megamenu > ul.sub-menu {
    background: #262626;
    border-color: #262626;
}

#navigation > ul > li.megamenu > ul > li {
    border-right-color: #333333 !important;
}

#navigation > ul > li.megamenu ul li a {
    color: #bbbbbb;
}

#navigation > ul > li.megamenu > ul > li > a {
    color: #ffffff;
}

#navigation > ul > li.megamenu > ul ul li a:hover, #header #navigation > ul > li.megamenu > ul ul li.current-menu-item a {
    color: #ffffff !important;
    background-color: #333333 !important;
}

/* Header General */
#search-btn, #shopping-btn, #close-search-btn {
    color: #bbbbbb;
}

#search-btn:hover, #shopping-btn:hover, #close-search-btn:hover {
    color: #999999;
}

#slogan {
    font: 400 20px Open Sans, Arial, Helvetica, sans-serif;
    color: #777777;
    margin-top: 26px;
}

/* Mobile Header */
#mobile-navigation {
    background: #262626;
}

#mobile-navigation ul li a {
    font: 13px Work Sans, Arial, Helvetica, sans-serif;
    color: #bbbbbb;
    border-bottom-color: #333333 !important;
}

#mobile-navigation ul li a:hover, #mobile-navigation ul li a:hover [class^='fa-'], #mobile-navigation li.open > a, #mobile-navigation ul li.current-menu-item > a, #mobile-navigation ul li.current-menu-ancestor > a {
    color: #ffffff;
}

body #mobile-navigation li.open > a [class^='fa-'] {
    color: #ffffff;
}

#mobile-navigation form, #mobile-navigation form input {
    background: #444444;
    color: #cccccc;
}

#mobile-navigation form:before {
    color: #cccccc;
}

#mobile-header {
    background: #ffffff;
    height: 85px;
}

#mobile-navigation-btn, #mobile-cart-btn, #mobile-shopping-btn {
    color: #bbbbbb;
    line-height: 85px;
}

#mobile-navigation-btn:hover, #mobile-cart-btn:hover, #mobile-shopping-btn:hover {
    color: #999999;
}

#mobile-header .logo {
    margin-top: 34px;
}

/* Header V1 */
#header.header-v1 {
    height: 85px;
    background: #ffffff;
}

.header-v1 .logo {
    margin-top: 34px;
}

.header-v1 #navigation > ul > li {
    height: 85px;
    padding-top: 54px;
}

.header-v1 #navigation .sub-menu {
    top: 85px;
}

.header-v1 .header-icons-divider {
    line-height: 85px;
    background: #efefef;
}

#header.header-v1 .widget_shopping_cart {
    top: 85px;
}

.header-v1 #search-btn, .header-v1 #close-search-btn, .header-v1 #shopping-btn {
    line-height: 85px;
}

.header-v1 #search-top, .header-v1 #search-top input {
    height: 85px;
}

.header-v1 #search-top input {
    color: #666666;
    font-family: Open Sans, Arial, Helvetica, sans-serif;
}

/* Header V3 */
#header.header-v3 {
    background: #ffffff;
}

.header-v3 .navigation-wrap {
    background: #ffffff;
    border-top: 1px solid #efefef;
}

.header-v3 .logo {
    margin-top: 30px;
    margin-bottom: 30px;
}

/* Header V4 */
#header.header-v4 {
    background: #ffffff;
}

.header-v4 .navigation-wrap {
    background: #ffffff;
    border-top: 1px solid #efefef;
}

.header-v4 .logo {
    margin-top: 30px;
    margin-bottom: 30px;
}

/* Transparent Header */
#transparentimage {
    padding: 85px 0 0 0;
}

.header-is-transparent #mobile-navigation {
    top: 85px;
}

/* Stuck */
.stuck {
    background: #ffffff;
}

/* Titlebars */
.titlebar h1 {
    font: 400 22px Open Sans, Arial, Helvetica, sans-serif;
    color: #777777;
}

#fulltitle {
    background: #f9f9f9;
    border-bottom: 1px solid #efefef;
}

#breadcrumbs {
    margin-top: 6px;
}

#breadcrumbs, #breadcrumbs a {
    font: 400 13px Open Sans, Arial, Helvetica, sans-serif;
    color: #aaaaaa;
}

#breadcrumbs a:hover {
    color: #666666;
}

#fullimagecenter h1, #transparentimage h1 {
    font: 700 42px Montserrat, Arial, Helvetica, sans-serif;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 1;
    text-align: center;
}

/* Footer */
#footer .widget h3 {
    font: 13px Montserrat, Arial, Helvetica, sans-serif;
    color: #ffffff;
}

#footer {
    color: #ffffff;
    border-top: 4px none #aa8d17;
}

#footer {
    background-color: #262626;
}

#footer a, #footer .widget ul li:after {
    color: #ffffff;
}

#footer a:hover, #footer .widget ul li:hover:after {
    color: #ffffff;
}

#footer .widget ul li {
    border-bottom-color: #333333;
}

/* Copyright */
#copyright {
    background: #1b1b1b;
    color: #777777;
}

#copyright a {
    color: #999999;
}

#copyright a:hover {
    color: #cccccc;
}

/* Color Accent */
.highlight {
    color: #921a1d !important;
}

::selection {
    background: #921a1d;
}

::-moz-selection {
    background: #921a1d;
}

#shopping-btn span {
    background: #921a1d;
}

.blog-page .post h1 a:hover, .blog-page .post h2 a:hover {
    color: #921a1d;
}

.entry-image .entry-overlay {
    background: #921a1d;
}

.entry-quote a:hover {
    background: #921a1d;
}

.entry-link a:hover {
    background: #921a1d;
}

.blog-single .entry-tags a:hover {
    color: #921a1d;
}

.sharebox ul li a:hover {
    color: #921a1d;
}

#pagination .current a {
    background: #921a1d;
}

#filters ul li a:hover {
    color: #921a1d;
}

#filters ul li a.active {
    color: #921a1d;
}

#back-to-top a:hover {
    background-color: #921a1d;
}

#sidebar .widget ul li a:hover {
    color: #921a1d;
}

#sidebar .widget ul li:hover:after {
    color: #921a1d;
}

.widget_tag_cloud a:hover, .widget_product_tag_cloud a:hover {
    background: #921a1d;
    border-color: #921a1d;
}

.widget_portfolio .portfolio-widget-item .portfolio-overlay {
    background: #921a1d;
}

#sidebar .widget_nav_menu ul li a:hover {
    color: #921a1d;
}

#footer .widget_tag_cloud a:hover, #footer .widget_product_tag_cloud a:hover {
    background: #921a1d;
    border-color: #921a1d;
}

/* Shortcodes */
.box.style-2 {
    border-top-color: #921a1d;
}

.box.style-4 {
    border-color: #921a1d;
}

.box.style-6 {
    background: #921a1d;
}

a.button, input[type=submit], button, .minti_button {
    background: #921a1d;
    border-color: #921a1d;
}

a.button.color-2 {
    color: #921a1d;
    border-color: #921a1d;
}

a.button.color-3 {
    background: #921a1d;
    border-color: #921a1d;
}

a.button.color-9 {
    color: #921a1d;
}

a.button.color-6:hover {
    background: #921a1d;
    border-color: #921a1d;
}

a.button.color-7:hover {
    background: #921a1d;
    border-color: #921a1d;
}

.counter-number {
    color: #921a1d;
}

.divider-title.align-center:after, .divider-title.align-left:after {
    background-color: #921a1d
}

.divider5 {
    border-bottom-color: #921a1d;
}

.dropcap.dropcap-circle {
    background-color: #921a1d;
}

.dropcap.dropcap-box {
    background-color: #921a1d;
}

.dropcap.dropcap-color {
    color: #921a1d;
}

.toggle .toggle-title.active, .color-light .toggle .toggle-title.active {
    background: #921a1d;
    border-color: #921a1d;
}

.iconbox-style-1.icon-color-accent i.boxicon, .iconbox-style-2.icon-color-accent i.boxicon, .iconbox-style-3.icon-color-accent i.boxicon, .iconbox-style-8.icon-color-accent i.boxicon, .iconbox-style-9.icon-color-accent i.boxicon {
    color: #921a1d !important;
}

.iconbox-style-4.icon-color-accent i.boxicon, .iconbox-style-5.icon-color-accent i.boxicon, .iconbox-style-6.icon-color-accent i.boxicon, .iconbox-style-7.icon-color-accent i.boxicon, .flip .icon-color-accent.card .back {
    background: #921a1d;
}

.latest-blog .blog-item .blog-overlay {
    background: #921a1d;
}

.latest-blog .blog-item .blog-pic i {
    color: #921a1d;
}

.latest-blog .blog-item h4 a:hover {
    color: #921a1d;
}

.progressbar .progress-percentage {
    background: #921a1d;
}

.wpb_widgetised_column .widget ul li a:hover {
    color: #921a1d;
}

.wpb_widgetised_column .widget ul li:hover:after {
    color: #921a1d;
}

.wpb_accordion .wpb_accordion_wrapper .ui-state-active .ui-icon {
    background-color: #921a1d;
}

.wpb_accordion .wpb_accordion_wrapper .ui-state-active.wpb_accordion_header a {
    color: #921a1d;
}

.wpb_accordion .wpb_accordion_wrapper .wpb_accordion_header a:hover, .wpb_accordion .wpb_accordion_wrapper .wpb_accordion_header a:hover .ui-state-default .ui-icon {
    color: #921a1d;
}

.wpb_accordion .wpb_accordion_wrapper .wpb_accordion_header:hover .ui-icon {
    background-color: #921a1d !important;
}

.wpb_content_element.wpb_tabs .wpb_tabs_nav li.ui-tabs-active {
    border-bottom-color: #921a1d;
}

.portfolio-item h4 a:hover {
    color: #921a1d;
}

.portfolio-filters ul li a:hover {
    color: #921a1d;
}

.portfolio-filters ul li a.active {
    color: #921a1d;
}

.portfolio-overlay-icon .portfolio-overlay {
    background: #921a1d;
}

.portfolio-overlay-icon i {
    color: #921a1d;
}

.portfolio-overlay-effect .portfolio-overlay {
    background: #921a1d;
}

.portfolio-overlay-name .portfolio-overlay {
    background: #921a1d;
}

.portfolio-detail-attributes ul li a:hover {
    color: #921a1d;
}

a.catimage:hover .catimage-text {
    background: #921a1d;
}

/* WooCommerce */
.products li h3 {
    font: 400 16px Open Sans, Arial, Helvetica, sans-serif;
    color: #262626;
}

.woocommerce .button.checkout-button {
    background: #921a1d;
    border-color: #921a1d;
}

.woocommerce .products .onsale {
    background: #921a1d;
}

.product .onsale {
    background: #921a1d;
}

button.single_add_to_cart_button:hover {
    background: #921a1d;
}

.woocommerce-tabs > ul > li.active a {
    color: #921a1d;
    border-bottom-color: #921a1d;
}

p.stars a:hover {
    background: #921a1d;
}

p.stars a.active, p.stars a.active:after {
    background: #921a1d;
}

.product_list_widget a {
    color: #921a1d;
}

.woocommerce .widget_layered_nav li.chosen a {
    color: #921a1d !important;
}

.woocommerce .widget_product_categories > ul > li.current-cat > a {
    color: #921a1d !important;
}

.woocommerce .widget_product_categories > ul > li.current-cat:after {
    color: #921a1d !important;
}

.woocommerce-message {
    background: #921a1d;
}

.bbp-topics-front ul.super-sticky .bbp-topic-title:before, .bbp-topics ul.super-sticky .bbp-topic-title:before, .bbp-topics ul.sticky .bbp-topic-title:before, .bbp-forum-content ul.sticky .bbp-topic-title:before {
    color: #921a1d !important;
}

#subscription-toggle a:hover {
    background: #921a1d;
}

.bbp-pagination-links span.current {
    background: #921a1d;
}

div.wpcf7-mail-sent-ok, div.wpcf7-mail-sent-ng, div.wpcf7-spam-blocked, div.wpcf7-validation-errors {
    background: #921a1d;
}

.wpcf7-not-valid {
    border-color: #921a1d !important;
}

.products .button.add_to_cart_button {
    color: #921a1d !important;
}

.minti_list.color-accent li:before {
    color: #921a1d !important;
}

.blogslider_text .post-categories li a {
    background-color: #921a1d;
}

.minti_zooming_slider .flex-control-nav li .minti_zooming_slider_ghost {
    background-color: #921a1d;
}

.minti_carousel.pagination_numbers .owl-dots .owl-dot.active {
    background-color: #921a1d;
}

.wpb_content_element.wpb_tour .wpb_tabs_nav li.ui-tabs-active, .color-light .wpb_content_element.wpb_tour .wpb_tabs_nav li.ui-tabs-active {
    background-color: #921a1d;
}

.masonry_icon i {
    color: #921a1d;
}

/* Special Font */
.font-special, .button, .counter-title, h6, .wpb_accordion .wpb_accordion_wrapper .wpb_accordion_header a, .pricing-plan .pricing-plan-head h3, a.catimage, .divider-title, button, input[type='submit'], input[type='reset'], input[type='button'], .vc_pie_chart h4, .page-404 h3, .minti_masonrygrid_item h4 {
    font-family: 'Work Sans', Arial, Helvetica, sans-serif; /*letter-spacing: 0px; font-weight: ;*/
}

.ui-helper-reset {
    line-height: 1.9;
}

/* User CSS from Theme Options */
.header-is-transparent .sticky-wrapper {
    width: 100%;
}

#header.header-transparent.header-v1 {
    box-shadow: none !important;
}

.header-v1 .logo {
    margin-top: 15px;
}

.wpcf7-form .wpcf7-submit {
    background: #d20000;
    color: #ffffff;
    border: none;
    font-weight: 700;
}

a i.fa-facebook {
    color: #3b5998;
}

a i.fa-instagram {
    color: #fb3958;
}

a i.fa-linkedin {
    color: #0077b5;
}

#header.header-transparent.header-v1 #navigation > ul > li > a {
    color: #921a1d;
    font-weight: 700;
}

#header.header-transparent.header-v1 #navigation > ul > li > a:hover {
    color: #333333;
    font-weight: 700;
}

.fa {
    color: #921A1D;
}

#mobile-header .logo {
    margin-top: 17px;
}

#copyright {
    padding: 5px 0 3px 0;
}

@media only screen and (max-width: 959px) {
    #header, .sticky-wrapper {
        display: none;
    }

    #mobile-header {
        display: inherit
    }
}


.headline {
    line-height: 1;
}

h6 {
    /*font-size: 70px;*/
    font-family: "Public Sans" !important;
    font-weight: 600;
    font-size: 15px;
}

h4 {
    font-size: 30px;
}


.color-light input[type="text"], .color-light input[type="password"], .color-light input[type="email"], .color-light textarea {
    color: #333333;
    border: 1px solid rgba(255, 255, 255, 0.74);
    background: rgba(255, 255, 255, 0.74);
}

.color-light .wpcf7-form .wpcf7-submit {
    color: #921a1d;
}

/*

h5 {
    font-size: 14px;
    color: #921A1D;
}
*/

p {
    margin: 0 0 5px 0;
}

.mobile-menu {
    display: block;
}

.mobile-menu .menu-item {
    text-align: left;
}

#footer .container {
    display: flex;
}
