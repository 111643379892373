/*

Theme Name: Unicon
Theme URI: http://themeforest.net/user/minti
Description: Design-Driven Multipurpose WordPress Theme by Minti Themes
Version: 2.2.2
Author: minti
Author URI: http://mintithemes.com
Tags: one-column, two-columns, left-sidebar, right-sidebar, fluid-layout, custom-menu, full-width-template, theme-options, translation-ready
License: Custom
License URI: http://themeforest.net/licenses

/* ------------------------------------------------------------------------ */
/* CSS Reset v2.0 by http://meyerweb.com/eric/tools/css/reset/
/* ------------------------------------------------------------------------ */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* ------------------------------------------------------------------------ */
/* Base 1200px Grid
/* ------------------------------------------------------------------------ */

.container {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    padding: 0;
}

.container .column,
.container .columns {
    float: left;
    display: inline;
    margin-left: 20px;
    margin-right: 20px;
}

.row {
    /*margin-bottom: 40px;*/
}

/* Nested Column Classes */
.column.alpha, .columns.alpha {
    margin-left: 0;
}

.column.omega, .columns.omega {
    margin-right: 0;
}

/* Base Grid */
.container .one.column,
.container .one.columns {
    width: 35px;
}

.container .two.columns {
    width: 110px;
}

.container .three.columns {
    width: 185px;
}

.container .four.columns {
    width: 260px;
}

.container .five.columns {
    width: 335px;
}

.container .six.columns {
    width: 410px;
}

.container .seven.columns {
    width: 485px;
}

.container .eight.columns {
    width: 560px;
}

.container .nine.columns {
    width: 635px;
}

.container .ten.columns {
    width: 710px;
}

.container .eleven.columns {
    width: 785px;
}

.container .twelve.columns {
    width: 860px;
}

.container .thirteen.columns {
    width: 935px;
}

.container .fourteen.columns {
    width: 1010px;
}

.container .fifteen.columns {
    width: 1085px;
}

.container .sixteen.columns {
    width: 1160px;
}

.container .one-third.column,
.container .one-third.columns {
    width: 360px;
}

.container .two-thirds.column,
.container .two-thirds.columns {
    width: 780px;
}

.container .twelve.alt.sidebar-right {
    padding-right: 40px;
    width: 819px;
    border-right: 1px solid #efefef;
}

.container .twelve.alt.sidebar-left {
    padding-left: 40px;
    width: 819px;
    border-left: 1px solid #efefef;
}

#sidebar.sidebar-right #sidebar-widgets {
    border-left: 1px solid #efefef;
    margin-left: -21px;
    padding-left: 40px;
}

#sidebar.sidebar-left #sidebar-widgets {
    border-right: 1px solid #efefef;
    margin-right: -21px;
    padding-right: 40px;
}

/* Clearfix ---------------------------------------------------- */

.container:after {
    content: "\0020";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.clearfix:before,
.clearfix:after,
.row:before,
.row:after {
    content: '\0020';
    display: block;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0;
}

.row:after,
.clearfix:after {
    clear: both
}

.row,
.clearfix {
    zoom: 1
}

.clear {
    clear: both;
    visibility: hidden;
    width: 0;
    height: 0;
}

/* ------------------------------------------------------------------------ */
/* General Styles
/* ------------------------------------------------------------------------ */

body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 13px;
    line-height: 1.7;
    color: #777777;
    -webkit-font-smoothing: antialiased;
}

.wrapall {
    margin: 0;
    padding: 0;
    background: #ffffff;
    min-width: 1200px;
}

html {
    overflow-y: scroll; /*overflow-x: hidden;*/
}

img.scale-with-grid {
    max-width: 100%;
    height: auto;
}

/* Automatically Resize Images */
/*img {
    max-width: 100%;
    height: auto;
}*/

/* Theme Error Message */
.minti-error {
    text-align: center;
    background: #FFF5F5;
    color: #990000;
    font-weight: 600;
    font-size: 16px;
    display: block;
    padding: 20px;
}

/* Blank Page Centering */
html,
body,
.page-template-page-blank-php #content,
.page-template-page-blank-php #page-wrap {
    height: 100%;
}

.page-template-page-blank-php #page-wrap {
    overflow: visible !important;
}

.table-outer {
    display: table;
    height: 100%;
    width: 100%;
}

.table-inner {
    display: table-cell;
    vertical-align: middle;
}

.vertical-center {
    opacity: 0;
}

/* ------------------------------------------------------------------------ */
/* Typography
/* ------------------------------------------------------------------------ */

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Arial, Helvetica, sans-serif;
    color: #444444;
    font-weight: 400;
    margin: 0 0 20px 0;
    padding: 0;
    line-height: 1.4;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    font-weight: inherit
}

h1 {
    font-size: 28px;
}

h2 {
    font-size: 22px;
}

h3 {
    font-size: 26px;
}

h4 {
    font-size: 16px;
    font-weight: 400;
    color: #444444;
}

/*h5 {
    font-size: 14px;
    font-weight: 600;
}*/

h6 {
    font-size: 12px;
}

.highlight {
    color: #44bdbd;
}

p {
    margin: 0 0 20px 0
}

p img {
    margin: 0
}

em {
    font-style: italic
}

b, strong {
    font-weight: 700
}

p strong {
    font-weight: 700;
}

p b {
    font-weight: 600;
}

small {
    font-size: 80%
}

hr {
    border: solid #ddd;
    border-width: 1px 0 0;
    clear: both;
    margin: 10px 0 30px;
    height: 0;
}

pre {
    height: auto;
    font-family: Consolas, monospace;
    border: 1px solid #e2e2e2;
    background: #f9f9f9;
    padding: 20px 20px;
    border-radius: 3px;
    margin: 0 0 20px 0;
    overflow-x: auto;
    box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.hidden {
    display: none !important
}

abbr {
}

address,
cite,
em,
i {
    font-style: italic
}

dfn {
    border-bottom: 1px dashed;
    font-style: italic;
}

small,
sub,
sup {
    position: relative;
    font-size: 11px;
    vertical-align: baseline;
}

sub {
    top: 3px
}

sup {
    bottom: 3px
}

mark {
    display: inline;
    padding: 2px 4px;
    background-color: #f6f2d6;
    color: #7c6d08;
    border-radius: 3px;
}

mark.dark {
    background-color: #333;
    color: #ffffff;
}

::selection {
    /*background: #333333;*/
    color: #ffffff;
}

::-moz-selection {
    background: #333333;
    color: #ffffff;
}

/* Links /--------------------------------------------------------- */

a {
    color: #44bdbd;
    text-decoration: none;
    outline: 0;
}

a i.fa {
    /*font-size: inherit;*/
    font-weight: 400 !important;
}

a strong {
    color: inherit;
}

/* Transisiton for Links */

.post .entry-quote a,
a.button,
.post a,
.widget_tag_cloud a,
#related-posts ul li a,
button,
input[type=submit],
#back-to-top a,
.portfolio-item a,
#filters a,
.widget_product_tag_cloud a,
#footer li a,
.entry-meta a,
.social-icon a,
#footer .widget ul li:after,
#footer .widget a,
#copyright a,
#bbpress-forums a,
.portfolio a,
.portfolio-filters a,
.entry-quote a .quote-source,
.sharebox ul li a,
#cancel-comment-reply-link,
.wpb_accordion .wpb_accordion_wrapper .wpb_accordion_header a,
.product_meta a,
.widget a,
.wpb_text_column p a,
.latest-blog .blog-item h4 a,
.wpb_content_element a,
.product-remove a,
.woocommerce a,
#mobile-navigation-btn,
#mobile-shopping-btn,
#topbar a,
.minti_button,
.minti_button i {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}

a:hover {
    color: #666666
}

p a {
    line-height: inherit
}

/* Lists /--------------------------------------------------------- */

ul,
ol {
    margin-bottom: 20px
}

ul {
    list-style: none outside
}

ol,
ul {
    margin-left: 30px
}

ol {
    list-style: decimal
}

ul {
    list-style: square outside
}

ul li ul,
ol li ul,
ol li ul,
ol li ol {
    margin-bottom: 0
}

/* ------------------------------------------------------------------------ */
/* Forms
/* ------------------------------------------------------------------------ */

form,
fieldset {
}

/* Reset iPad / iPhone Button Styling */
button, input[type=submit] {
    -webkit-appearance: none; /*Safari/Chrome*/
    -webkit-border-radius: 2px;
}

input[type="text"],
input[type="password"],
input[type="email"],
textarea {
    -webkit-appearance: none; /*Safari/Chrome*/
}

input, textarea {
    -webkit-border-radius: 2px;
}

input::-ms-clear {
    width: 0;
    height: 0;
}

input[type="text"], input[type="password"], input[type="email"], input[type="tel"], textarea {
    padding: 5px 12px;
    outline: none !important;
    border-radius: 2px;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 13px;
    /*margin: 0 0 20px 0;*/
    /* width: 200px; */
    /* max-width: 100%; */
    display: block;
    /*color: #999;*/
    color: #384551;
    line-height: 1.6;
    border: 1px solid #dddddd;
    background: #ffffff;
    transition: all 0.2s;
    border-radius: 5px;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
textarea:focus {
    color: #666666;
    background: #ffffff;
    outline: none !important;
    border-color: #999999;
}

textarea {
    min-height: 60px
}

select {
    display: block;
    padding: 10px 12px;
    outline: none !important;
    border-radius: 2px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    line-height: 1.6;
    color: #666;
    margin: 0;
    max-width: 100%;
    color: #999;
    border: 1px solid #dddddd;
    background: #ffffff;
}

label,
legend {
    cursor: pointer;
    display: block;
}

label span,
legend span {
    font-weight: 600
}

button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
    cursor: pointer;
    outline: none !important;
    -webkit-font-smoothing: antialiased;
}

/* ------------------------------------------------------------------------ */
/* Boxed Layout
/* ------------------------------------------------------------------------ */

.boxed-layout {
    width: 1240px;
    padding: 0;
    margin: 0px auto !important;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    overflow: hidden;
}

/* Left - Right Shadow Shine fix for Boxed Layout Mode */
.boxed-layout #header {
    width: 1240px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
}

/* ------------------------------------------------------------------------ */
/* Topbar
/* ------------------------------------------------------------------------ */
#topbar {
    position: relative;
    z-index: 99999;
    background: #f9f9f9;
    color: #777777;
    min-height: 40px;
    line-height: 40px;
}

#topbar .topbar-col1 {
    float: left;
    margin: 0;
    padding: 0;
}

#topbar .topbar-col2 {
    float: right;
    margin: 0;
    padding: 0;
}

#topbar ul.menu {
    margin: 0;
    padding: 0;
    list-style: none;
}

#topbar ul.menu li {
    list-style: none;
    display: inline-block;
    line-height: 1;
    margin: 0 20px 0 0;
}

#topbar ul.menu li a {
    display: inline-block;
}

#topbar .topbar-col2 ul.menu li {
    margin: 0 0 0 20px;
}

#topbar a {
    color: #999999;
}

#topbar a:hover {
    color: #333333;
}

/* ------------------------------------------------------------------------ */
/* Navigation Basic
/* ------------------------------------------------------------------------ */
#navigation ul {
    margin: 0;
    list-style: none;
}

#navigation ul li {
    margin: 0;
    padding: 0;
    display: block;
    float: left;
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}

#navigation ul li a {
    padding: 0;
    margin: 0;
    display: block;
    font-size: 13px;
    line-height: 1.3;
    color: #555555;
    text-align: center;
    -webkit-transition: color 0.1s;
    -moz-transition: color 0.1s;
    -ms-transition: color 0.1s;
    -o-transition: color 0.1s;
    transition: color 0.1s;
}

#navigation > ul > li > a {
    font-weight: 700;
}

#navigation ul > li:hover > a,
#navigation ul li a:hover {
    color: #44bdbd;
}

/* Active Status */
#navigation li.current-menu-item > a,
#navigation li.current-menu-item > a:hover,
#navigation li.current-page-ancestor > a,
#navigation li.current-page-ancestor > a:hover,
#navigation li.current-menu-ancestor > a,
#navigation li.current-menu-ancestor > a:hover,
#navigation li.current-menu-parent > a,
#navigation li.current-menu-parent > a:hover,
#navigation li.current_page_ancestor > a,
#navigation li.current_page_ancestor > a:hover,
#navigation > ul > li.sfHover > a {
    color: #44bdbd;
}

#navigation ul li ul li.sfHover > a {
    color: #ffffff;
}

/* Submenu /--------------------------------------------------------- */
#navigation .sub-menu {
    width: 200px;
    padding: 0;
    margin: 0;
    position: absolute;
    left: 0px;
    background: #666666;
    border: none;
    z-index: 200;
    border-radius: 0px 0px 2px 2px;
    /*    box-shadow:1px 1px 3px 0px rgba(0, 0, 0, 0.1);*/
    display: none;
}

#navigation .sub-menu li {
    float: none;
    border: none;
    margin: 0px 10px;
    padding: 0;
    height: auto;
    position: relative;
}

#navigation .sub-menu li a {
    display: block;
    text-align: left;
    color: #bbbbbb;
    text-transform: none;
    padding: 10px 6px;
    margin: 0;
    border: none;
    border-bottom: 1px solid #757575;
}

#navigation .sub-menu > li:last-child > a {
    border: none !important;
}

#navigation .sub-menu li:hover {
    border: none;
}

#navigation .sub-menu li a:hover {
    color: #ffffff;
}

#navigation .sub-menu li.current-menu-item > a,
#navigation .sub-menu li.current-menu-item > a:hover,
#navigation .sub-menu li.current-page-ancestor > a,
#navigation .sub-menu li.current-page-ancestor > a:hover,
#navigation .sub-menu li.current-menu-ancestor > a,
#navigation .sub-menu li.current-menu-ancestor > a:hover,
#navigation .sub-menu li.current-menu-parent > a,
#navigation .sub-menu li.current-menu-parent > a:hover,
#navigation .sub-menu li.current_page_ancestor > a,
#navigation .sub-menu li.current_page_ancestor > a:hover {
    color: #ffffff;
}

/* Submenu - 2nd Level /--------------------------------------------------------- */
#navigation .sub-menu ul {
    position: absolute;
    left: 202px;
    margin: 0 0 0 -10px;
    top: 0;
    border-radius: 2px;
}

#navigation .sub-menu ul li a {
    display: block;
    text-align: left;
    color: #bbbbbb;
    text-transform: none;
    padding: 10px 6px;
    margin: 0;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}

#navigation ul li ul li.current-menu-item > a {
    color: #44bdbd;
}

/* Megamenu /--------------------------------------------------------- */

#navigation ul li.megamenu {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

#navigation > ul > li.megamenu {
    position: inherit !important;
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

#navigation > ul > li.megamenu > ul.sub-menu {
    width: 1160px;
    left: 0px;
    right: 0px;
    padding: 20px 0px 20px 0;
    margin: 0 20px 0 20px;
    background-color: #666666;
}

#navigation > ul > li.megamenu > ul > li {
    float: left !important;
    padding: 0px 0px;
    width: 25%;
    margin: 0;
}


#navigation > ul > li.megamenu > ul > li:last-child {
    border-right: none !important;
}

#navigation ul > li.megamenu.columns-2 > ul > li {
    width: 50%;
}

#navigation ul > li.megamenu.columns-3 > ul > li {
    width: 33.33%;
}

#navigation ul > li.megamenu.columns-4 > ul > li {
    width: 25%;
}

#navigation ul > li.megamenu.columns-5 > ul > li {
    width: 20%;
}

#navigation ul > li.megamenu.columns-6 > ul > li {
    width: 16.66%;
}

.megamenu li.hiddentitle > a {
    display: none !important;
}

#navigation > ul > li.megamenu > ul > li ul {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: block !important;
    top: 0px !important;
    left: 0px !important;
    margin: 0;
    width: 100%;
    opacity: 1 !important;
    position: relative !important;
    border: none;
    border-radius: none;
    box-shadow: none;
}

#navigation > ul > li.megamenu > ul > li {
    border-right: 1px solid rgba(255, 255, 255, 0.1) !important;
}

#navigation > ul > li.megamenu ul li a {
    border-bottom: none !important;
    padding: 8px 40px;
    color: #ffffff;
    -webkit-transition: all 0.1s;
    -moz-transition: all 0.1s;
    -ms-transition: all 0.1s;
    -o-transition: all 0.1s;
    transition: all 0.1s;
}

#navigation > ul > li.megamenu > ul > li > a {
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: 700;
    color: #ffffff;
    cursor: default;
}

#navigation > ul > li.megamenu > ul ul li a:hover, #navigation > ul > li.megamenu > ul ul li.current-menu-item a {
    color: #ffffff !important;
    background-color: #555555 !important;
    border: none !important;
}

#navigation > ul > li.megamenu > ul > li > a:hover, #navigation > ul > li.megamenu > ul > li.sfHover > a {
    background-color: transparent !important;
}

#navigation ul li.megamenu ul li ul li {
    margin: 0;
    padding: 0;
}

/* Sub-Menu Arrows /--------------------------------------------------------- */
.sf-arrows .sf-with-ul:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    font-size: 10px;
    margin-top: -7px;
    content: '\f054';
    font-family: 'FontAwesome';
}

#navigation .megamenu .sf-with-ul:after,
#navigation > ul > li > a.sf-with-ul:after {
    display: none !important;
}

#navigation > ul > li > a.sf-with-ul:after {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -5px;
    right: 6px;
    font-size: 11px;
    content: '\f107';
    font-family: 'FontAwesome';
    display: none !important;
}

/* ------------------------------------------------------------------------ */
/* Header Basic
/* ------------------------------------------------------------------------ */
#header {
    position: relative;
    background-color: #ffffff;
    width: 100%;
    z-index: 8000;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.16);
    -webkit-transition: background 0.2s;
    -moz-transition: background 0.2s;
    -ms-transition: background 0.2s;
    -o-transition: background 0.2s;
    transition: background 0.2s;
}

.logo {
    font-size: 24px;
    line-height: 20px;
    font-weight: 600;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.logo img {
    max-width: 100%;
    height: auto;
}

#slogan {
    float: right;
    margin-top: 20px;
}

/* ------------------------------------------------------------------------ */
/* Header V1
/* ------------------------------------------------------------------------ */
.header-v1 #header {
    height: 90px;
}

.header-v1 .logo {
    float: left;
    margin-top: 33px;
    margin-bottom: 0;
}

.header-v1 #navigation {
    float: right;
}

.header-v1 #navigation > ul {
    float: right;
    margin: 0 5px 0 0;
}

.header-v1 #navigation > ul > li {
    height: 90px;
    padding-top: 35px;
}

.header-v1 #navigation > ul > li > a {
    padding-left: 15px;
    padding-right: 15px;
}

.header-v1 #navigation .sub-menu {
    top: 90px;
}

.header-v1 #shopping-btn {
    float: right;
    line-height: 90px;
    margin: 0 0 0 20px;
    width: 21px;
}

.header-v1 #search-btn {
    float: right;
    line-height: 90px;
    margin: 0 0 0 20px;
    width: 23px;
}

/* ------------------------------------------------------------------------ */
/* Header V3
/* ------------------------------------------------------------------------ */
.header-v3 .logo {
    float: left;
    margin-top: 30px;
    margin-bottom: 30px;
}

.header-v3 #navigation > ul {
    margin: 0 5px 0 0;
}

.header-v3 #navigation > ul > li > a {
    padding: 20px 30px 20px 0;
}

.header-v3 .navigation-wrap {
    position: relative;
    background: #ffffff;
    border-top: 1px solid #efefef;
}

.header-v3 #navigation {
    float: left;
}

.header-v3 #shopping-btn {
    float: right;
    margin: 21px 0 0 0;
    padding-right: 3px;
    line-height: 1;
}

.header-v3 #search-btn {
    float: right;
    margin: 11px 0 0 20px;
}

/* ------------------------------------------------------------------------ */
/* Header V4
/* ------------------------------------------------------------------------ */
.header-v4 .logo {
    float: none;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.header-v4 #navigation {
    float: none;
    display: inline;
    text-align: center;
}

.header-v4 #navigation > ul {
    margin: 0;
}

.header-v4 #navigation > ul > li {
    float: none;
    display: inline-block;
    text-align: center;
}

.header-v4 #navigation > ul > li > a {
    padding: 20px 15px 20px 15px;
}

.header-v4 .navigation-wrap {
    background: #ffffff;
    border-top: 1px solid #efefef;
}

/* ------------------------------------------------------------------------ */
/* Header V5
/* ------------------------------------------------------------------------ */
.header-wide-container {
    padding: 0 40px;
}

.header-wide-container #navigation > ul > li.megamenu > ul.sub-menu {
    width: 97%;
    margin: 0 1.5%;
}

/* ------------------------------------------------------------------------ */
/* Mobile Navigation
/* ------------------------------------------------------------------------ */
#mobile-header {
    display: none;
    position: relative;
    background-color: #ffffff;
    height: 90px;
    width: 100%;
    z-index: 8000;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.16);
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}

#mobile-header .logo {
    margin-top: 33px;
    margin-bottom: 0;
    float: left;
}

#mobile-navigation-btn {
    float: right;
    font-size: 21px;
    line-height: 90px;
    margin-top: 0px;
    color: #cccccc;
}

#mobile-navigation-btn:hover {
    color: #999999;
}

#mobile-shopping-btn {
    float: right;
    line-height: 90px;
    margin: 2px 20px -3px 0;
    font-size: 17px;
    width: 21px;
    color: #999999;
}

#mobile-shopping-btn:hover {
    color: #555555;
}

#mobile-navigation {
    background: #666666;
    padding: 0px 0;
    position: relative;
    margin: 0;
    z-index: 1001;
    display: none;
}

#mobile-navigation .container ul {
    list-style: none;
    margin: 16px 0 30px 0;
}

#mobile-navigation .container ul li ul {
    margin: 0 0 0 30px;
    display: none;
}

#mobile-navigation .container > ul > li:last-child a {
    border-bottom: 0px;
}

#mobile-navigation ul li a {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
    color: #bbbbbb;
    display: block;
    position: relative;
    padding: 12px 0 !important;
}

#mobile-navigation ul li a:hover,
#mobile-navigation ul li a:hover [class^="fa-"],
#mobile-navigation li.open > a,
#mobile-navigation ul li.current-menu-item > a,
#mobile-navigation ul li.current-menu-ancestor > a {
    color: #ffffff;
}

body #mobile-navigation li.open > a [class^="fa-"] {
    color: #ffffff;
}

#mobile-navigation ul li i {
    display: block;
    padding: 10px 6px 10px 40px;
    float: right;
    margin: -7px 0 0 0;
    font-size: 14px;
}

/* Mobile Search /--------------------------------------------------------- */

#mobile-navigation form {
    position: relative;
    background: #444444;
    padding: 0px 12px 0px 37px;
    margin: 0 0 32px 0;
    border: none;
    border-radius: 2px 2px 2px 2px;
}

#mobile-navigation form input {
    width: 100%;
    padding: 12px 0;
    margin: 0;
    background: #444444;
    border: none;
    border-radius: none;
    box-shadow: none;
    color: #cccccc;
    -webkit-font-smoothing: antialiased;
}

#mobile-navigation form:before {
    position: absolute;
    top: 7px;
    left: 12px;
    font-size: 16px;
    color: #999999;
    content: '\f002';
    font-family: 'FontAwesome';
}

/* Header Buttons & Header Search /--------------------------------------------------------- */

.header-icons {
    float: right;
}

.header-icons-divider {
    height: 30px;
    line-height: 90px;
    width: 1px;
    float: left;
    background: #efefef;
    position: absolute;
    top: 50%;
    margin-top: -17px;
    display: block;
}

#search-btn {
    font-size: 23px;
    color: #bbbbbb;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

#search-btn:hover {
    color: #999999;
}

#shopping-btn {
    position: relative;
    font-size: 17px;
    color: #999999;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

#shopping-btn:hover {
    color: #555555;
}

#shopping-btn span {
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -18px;
    right: -7px;
    height: 16px;
    width: 16px;
    line-height: 16px;
    font-size: 11px;
    font-weight: 700;
    text-align: center;
    border-radius: 50%;
    background: #44bdbd;
    color: #ffffff;
}

#search-top {
    position: absolute;
    top: 0;
    left: 0;
    height: 90px;
    width: 1160px;
    margin: 0 20px;
    padding: 0;
    display: none;
}

.header-wide-container #search-top {
    width: 100%;
    margin: 0;
    padding: 0 40px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

#search-top input {
    float: left;
    margin: 0px;
    width: 96%;
    height: 90px;
    padding: 0px;
    border: 0;
    background: transparent;
    color: #666666;
    font-size: 38px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 300;
    border-radius: none;
    box-shadow: none;
    -webkit-font-smoothing: antialiased;
}

#search-top input::-webkit-input-placeholder {
    color: #666666;
    opacity: 0.6;
}

#search-top input:-moz-placeholder {
    color: #666666;
    opacity: 0.6;
}

#search-top input::-moz-placeholder {
    color: #666666;
    opacity: 0.6;
}

#search-top input:-ms-input-placeholder {
    color: #666666;
    opacity: 0.6;
}

#search-top input:focus {
    outline: none;
}

#close-search-btn {
    float: right;
    line-height: 90px;
    margin: 0;
    padding: 0;
    font-size: 21px;
    color: #bbbbbb;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

#close-search-btn i {
    line-height: 1;
}

#close-search-btn:hover {
    color: #999999;
}

/* ------------------------------------------------------------------------ */
/* Header Transparent
/* ------------------------------------------------------------------------ */
#header.header-transparent.header-v1 {
    background: transparent !important;
    background-position: top center !important;
    box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.2) !important;
}

#header.header-transparent.header-v1 #navigation > ul > li > a {
    color: rgba(255, 255, 255, 0.8);
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -ms-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
}

#header.header-transparent.header-v1 #navigation > ul > li:hover > a,
#header.header-transparent.header-v1 #navigation > ul > li > a:hover {
    color: rgba(255, 255, 255, 1);
}

#header.header-transparent.header-v1 #search-btn,
#header.header-transparent.header-v1 #shopping-btn,
#header.header-transparent.header-v1 #close-search-btn,
#header.header-transparent.header-v1 #mobile-navigation-btn,
#header.header-transparent.header-v1 #mobile-cart-btn {
    color: rgba(255, 255, 255, 0.8);
}

#header.header-transparent.header-v1 #search-btn:hover,
#header.header-transparent.header-v1 #shopping-btn:hover,
#header.header-transparent.header-v1 #close-search-btn:hover,
#header.header-transparent.header-v1 #mobile-navigation-btn:hover,
#header.header-transparent.header-v1 #mobile-cart-btn:hover {
    color: rgba(255, 255, 255, 1);
}

#header.header-transparent.header-v1 .header-icons-divider {
    background: rgba(255, 255, 255, 0.2);
}

#header.header-transparent.header-v1 #search-top input {
    color: #ffffff;
}

#header.header-transparent.header-v1 #search-top input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
}

#header.header-transparent.header-v1 #search-top input:-moz-placeholder {
    color: rgba(255, 255, 255, 0.8);
}

#header.header-transparent.header-v1 #search-top input::-moz-placeholder {
    color: rgba(255, 255, 255, 0.8);
}

#header.header-transparent.header-v1 #search-top input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
}

/*#header.header-transparent.header-v1 #navigation > ul > li.current-menu-item > a,
#header.header-transparent.header-v1 #navigation > ul > li.current-menu-item > a:hover,
#header.header-transparent.header-v1 #navigation > ul > li.current-page-ancestor > a,
#header.header-transparent.header-v1 #navigation > ul > li.current-page-ancestor > a:hover,
#header.header-transparent.header-v1 #navigation > ul > li.current-menu-ancestor > a,
#header.header-transparent.header-v1 #navigation > ul > li.current-menu-ancestor > a:hover,
#header.header-transparent.header-v1 #navigation > ul > li.current-menu-parent > a,
#header.header-transparent.header-v1 #navigation > ul > li.current-menu-parent > a:hover,
#header.header-transparent.header-v1 #navigation > ul > li.current_page_ancestor > a,
#header.header-transparent.header-v1 #navigation > ul > li.current_page_ancestor > a:hover,
#header.header-transparent.header-v1 #navigation > ul > li.sfHover > a { color: rgba(255,255,255,1) !important; }*/

/* Negative margin on Page Wrap */
#header.header-transparent, .header-is-transparent #mobile-header {
    position: absolute;
}

.header-is-transparent #mobile-navigation {
    top: 90px;
}

/* Negative margin on Page Wrap */
#page-wrap {
    position: relative;
    clear: both;
    float: none;
}

/* Dark Header Class --------------- */
#header.header-transparent.darkheader.header-v1 {
    background: transparent !important;
    background-position: top center !important;
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.07) !important;
}

#header.header-transparent.darkheader.header-v1 #navigation > ul > li > a {
    color: rgba(0, 0, 0, 0.5);
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -ms-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
}

#header.header-transparent.darkheader.header-v1 #navigation > ul > li:hover > a,
#header.header-transparent.darkheader.header-v1 #navigation > ul > li > a:hover {
    color: rgba(0, 0, 0, 0.8);
}

#header.header-transparent.darkheader.header-v1 #search-btn,
#header.header-transparent.darkheader.header-v1 #shopping-btn,
#header.header-transparent.darkheader.header-v1 #close-search-btn,
#header.header-transparent.darkheader.header-v1 #mobile-navigation-btn,
#header.header-transparent.darkheader.header-v1 #mobile-cart-btn {
    color: rgba(0, 0, 0, 0.4);
}

#header.header-transparent.darkheader.header-v1 #search-btn:hover,
#header.header-transparent.darkheader.header-v1 #shopping-btn:hover,
#header.header-transparent.darkheader.header-v1 #close-search-btn:hover,
#header.header-transparent.darkheader.header-v1 #mobile-navigation-btn:hover,
#header.header-transparent.darkheader.header-v1 #mobile-cart-btn:hover {
    color: rgba(0, 0, 0, 0.8);
}

#header.header-transparent.darkheader.header-v1 .header-icons-divider {
    background: rgba(0, 0, 0, 0.1);
}

#header.header-transparent.darkheader.header-v1 #search-top input {
    color: rgba(0, 0, 0, 0.8);
}

#header.header-transparent.darkheader.header-v1 #search-top input::-webkit-input-placeholder {
    color: #111111;
    opacity: 0.5;
}

#header.header-transparent.darkheader.header-v1 #search-top input:-moz-placeholder {
    color: #111111;
    opacity: 0.5;
}

#header.header-transparent.darkheader.header-v1 #search-top input::-moz-placeholder {
    color: #111111;
    opacity: 0.5;
}

#header.header-transparent.darkheader.header-v1 #search-top input:-ms-input-placeholder {
    color: #111111;
    opacity: 0.5;
}

/*#header.header-transparent.darkheader.header-v1 #navigation > ul > li.current-menu-item > a,
#header.header-transparent.darkheader.header-v1 #navigation > ul > li.current-menu-item > a:hover,
#header.header-transparent.darkheader.header-v1 #navigation > ul > li.current-page-ancestor > a,
#header.header-transparent.darkheader.header-v1 #navigation > ul > li.current-page-ancestor > a:hover,
#header.header-transparent.darkheader.header-v1 #navigation > ul > li.current-menu-ancestor > a,
#header.header-transparent.darkheader.header-v1 #navigation > ul > li.current-menu-ancestor > a:hover,
#header.header-transparent.darkheader.header-v1 #navigation > ul > li.current-menu-parent > a,
#header.header-transparent.darkheader.header-v1 #navigation > ul > li.current-menu-parent > a:hover,
#header.header-transparent.darkheader.header-v1 #navigation > ul > li.current_page_ancestor > a,
#header.header-transparent.darkheader.header-v1 #navigation > ul > li.current_page_ancestor > a:hover,
#header.header-transparent.darkheader.header-v1 #navigation > ul > li.sfHover > a { color: rgba(0,0,0,0.8) !important; }*/

/* ------------------------------------------------------------------------ */
/* Logo Retina
/* ------------------------------------------------------------------------ */
/* Standard Logos */
.logo_standard {
    display: inherit
}

.logo_retina {
    display: none
}

.header-transparent .logo_standard {
    display: none
}

.header-transparent .logo_retina {
    display: none
}

.logo_transparent {
    display: none
}

.logo_retina_transparent {
    display: none
}

.header-transparent .logo_transparent {
    display: inherit
}

.header-transparent .logo_retina_transparent {
    display: none
}

/* Dark Header */
.header-transparent.darkheader .logo_standard {
    display: inherit
}

.header-transparent.darkheader .logo_transparent {
    display: none
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
    /* Standard Logos */
    .logo_standard {
        display: none
    }

    .logo_retina {
        display: inherit
    }

    .header-transparent .logo_standard {
        display: none
    }

    .header-transparent .logo_retina {
        display: none
    }

    /* Transparent Logos */
    .logo_transparent {
        display: none
    }

    .logo_retina_transparent {
        display: none
    }

    .header-transparent .logo_transparent {
        display: none
    }

    .header-transparent .logo_retina_transparent {
        display: inherit
    }

    /* Dark Header */
    .header-transparent.darkheader .logo_standard {
        display: none
    }

    .header-transparent.darkheader .logo_retina {
        display: inherit
    }

    .header-transparent.darkheader .logo_transparent {
        display: none
    }

    .header-transparent.darkheader .logo_retina_transparent {
        display: none
    }
}

/* ------------------------------------------------------------------------ */
/* Titlebar
/* ------------------------------------------------------------------------ */
#title {

}

/* Standard Title /--------------------------------------------------------- */

.titlebar h1 {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 400;
    color: #777777;
}

/* Full: Title /--------------------------------------------------------- */

#fulltitle {
    background: #f9f9f9;
    border-bottom: 1px solid #efefef;
    padding: 24px 0;
}

/* Full: Image Center /--------------------------------------------------------- */

#fullimagecenter {
    background-position: center center;
    background-size: cover;
    text-align: center;
}

#fullimagecenter h1 {
    text-align: center;
    display: block;
    color: #ffffff;
    padding: 140px 0;
    margin: 0;
    font-size: 32px;
}

/* Transparent Image /--------------------------------------------------------- */

#transparentimage {
    position: relative;
    padding: 90px 0 0 0;
    background-position: center center;
    background-size: cover;
}

#transparentimage h1 {
    text-align: center;
    display: block;
    color: #ffffff;
    padding: 140px 0;
    margin: 0;
    font-size: 32px;
    position: relative;
    z-index: 1;
}

/* Small: Title /--------------------------------------------------------- */

#smalltitle .sixteen {
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
}

#smalltitle h1 {
    display: inline-block;
    border-bottom: 3px solid #cccccc;
    padding-bottom: 20px;
    margin-bottom: -1px;
}

/* Small: Image /--------------------------------------------------------- */

#smallimage .sixteen {
    background-position: center center;
    border-radius: 2px;
    background-size: cover;
}

#smallimage h1 {
    display: inline-block;
    background: #ffffff;
    padding: 5px 14px;
    margin: 50px 0 50px 30px;
    border-radius: 2px;
}

#smallimage #breadcrumbs {
    margin-top: 60px;
}

#smallimage #breadcrumbs span {
    margin-right: 30px;
}

/* No Titlebar /--------------------------------------------------------- */

#notitlebar {

}

/* Breadcrumbs /--------------------------------------------------------- */

#breadcrumbs {
    color: #aaaaaa;
    text-align: right;
    font-size: 11px;
    margin-top: 4px;

}

#breadcrumbs a {
    color: #aaaaaa;
}

#breadcrumbs a:hover {
    color: #666666;
}

/* ------------------------------------------------------------------------ */
/* Content
/* ------------------------------------------------------------------------ */

#page-wrap {
}

/* Fix for iPad Portrait */
.on-mobile #page-wrap {
    overflow: hidden;
}

#content {
    padding-top: 60px;
    padding-bottom: 40px;
}

#sidebar-widgets {
    padding-top: 60px;
    padding-bottom: 60px;
}

#content.nopadding {
    padding: 0;
    margin: 0;
}

#content.sidebar-left {
    float: right;
}

/* ------------------------------------------------------------------------ */
/* Blog Standard - Overview
/* ------------------------------------------------------------------------ */
.blog-page:after {
    content: '\0020';
    display: block;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0;
}

.blog-page .post {
    margin: 0 0 60px 0;
    padding: 0 0 53px 0;
    border-bottom: 1px solid #efefef;
}

.blog-page .post.format-quote {
    padding: 0 0 60px 0;
}

.blog-page .post .button i {
    margin: 0;
}

.blog-page .post h1,
.blog-page .post h2 {
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 20px 0;
    padding: 0;
    line-height: 1;
}

.blog-page .post h1 a,
.blog-page .post h2 a {
    color: #444444;
}

.blog-page .post h1 a:hover,
.blog-page .post h2 a:hover {
    color: #44bdbd;
}

.blog-page .post h1 i,
.blog-page .post h2 i {
    margin: 0 0 0 5px;
}

.post-time {
    width: 42px;
    border-right: 1px solid #efefef;
    float: left;
    padding: 0 20px 0 0;
    margin: 0 20px 0 0;
    text-align: center;
    color: #999999;
}

.post-time .month {
    font-size: 19px;
    display: block;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 400;
}

.post-time .day {
    font-size: 30px;
    display: block;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
}

.blog-page article {
    overflow: hidden; /* float article left with overflow */
}

/* Post Format Specific /--------------------------------------------------------- */

.entry-video,
.entry-audio,
.entry-gallery,
.entry-image {
    margin: 0 0 30px 0;
}

.entry-video iframe,
.entry-audio iframe {
    max-width: 100%;
}

.entry-audio iframe {
    max-width: 100%;
}

.entry-audio iframe {
    max-width: 100%;
    width: 100%;
    margin: 0 0 -8px 0 !important;
}

.entry-gallery {
    border-radius: 2px;
    position: relative;
}

.entry-gallery img {
    border-radius: 2px;
}

.entry-image {
    border-radius: 2px;
    overflow: hidden;
    position: relative;
}

.entry-image img {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block;
}

.entry-image .entry-overlay {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 2px;
}

.entry-image .entry-overlay i {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -20px;
    font-size: 24px;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: #ffffff;
}

.entry-quote a {
    text-align: center;
    display: block;
    background: #f9f9f9;
    color: #666666;
    padding: 40px;
    font-size: 20px;
    line-height: 1.6;
    font-weight: 600;
    border-radius: 3px;
    position: relative;
    margin: 0;
}

.entry-quote a .quote-source {
    display: block;
    font-size: 14px;
    color: #666666;
    opacity: 0.6;
    margin: 10px 0 0 0;
}

.entry-quote a:hover .quote-source {
    color: #ffffff;
}

.entry-quote a:hover {
    background: #666666;
    color: #ffffff;
}

.entry-link {
    margin-bottom: 7px;
}

.entry-link a,
.entry-link a:active {
    display: block;
    background: #666666;
    border-radius: 2px;
    padding: 20px;
    font-size: 20px;
    line-height: 1;
    font-weight: 600;
    color: #ffffff !important;
}

.entry-link a span {
    font-size: 14px;
    display: block;
    margin-top: 10px;
    font-weight: 400;
    opacity: 0.6;
}

.entry-link a:hover {
    background: #333333;
    color: #ffffff;
}

/* Entry Meta Data /--------------------------------------------------------- */

.entry-meta {
    margin: 20px 0 0px 0;
}

/* Single Meta-Tags */
.blog-single .entry-meta {
    margin: 0px 0 20px 0;
}

.entry-meta {
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase;
}

.entry-meta ul {
    list-style: none;
    margin: 0;
    padding: 0;
    color: #999999;
}

.entry-meta ul li {
    display: inline;
}

.entry-meta ul li:after {
    content: ' | ';
    margin: 0 6px;
    color: #efefef;
}

.entry-meta ul li:last-child:after {
    content: '';
    margin: 0;
}

.entry-meta a {
    color: #999999;
}

.entry-meta a:hover {
    color: #666666;
}

/* Read More Link */
.blog-page .read-more-link {
}

/* Blog Medium /--------------------------------------------------------- */

.blog-medium .post h1,
.blog-medium .post h2 {
    font-size: 22px;
}

.blog-medium .post-time {
    display: none;
}

.blog-medium .entry-image,
.blog-medium .entry-gallery,
.blog-medium .entry-video,
.blog-medium .entry-audio {
    float: left;
    width: 310px;
    margin: 0 40px 7px 0;
}

.blog-medium .entry-audio iframe {
    max-width: 100%;
    width: 100%;
    height: 116px;
}

.blog-medium .entry-wrap {
    overflow: hidden;
}

.blog-medium .entry-meta .meta-author,
.blog-medium .entry-meta .meta-edit,
.blog-medium .entry-meta .meta-category:after {
    display: none;
}

/* Blog Center /--------------------------------------------------------- */

.blog-center .blog-wrap {
    width: 920px;
    margin: 0 auto;
}

/* Blog Masonry /--------------------------------------------------------- */
.blog-masonry .blog-wrap {
    opacity: 0;
    width: 100% !important;
    margin: 0;
    overflow: hidden; /* RETHINK THIS */
}

.blog-masonry .post {
    width: 360px;
    overflow: hidden;
    float: left;
    margin: 0 20px 60px 20px;
    padding: 0;
    display: inline-block;
    border: none;
    overflow: hidden;
}

.blog-masonry .post h2 {
    font-size: 20px;
}

.blog-masonry .post .entry-meta {
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase;
}

.blog-masonry .post .entry-meta .meta-author,
.blog-masonry .post .entry-meta .meta-author,
.blog-masonry .post .entry-meta .meta-comment,
.blog-masonry .post .entry-meta .meta-edit,
.blog-masonry .post .entry-meta .meta-comment:after {
    display: none;
}

.blog-masonry .post.format-quote {
    padding: 0;
}

.blog-masonry .entry-quote {
    position: relative;
    z-index: 2;
    margin: 0 0 10px 0;
}

.blog-masonry .entry-link {
    margin: 0 0 10px 0;
}

.blog-masonry .entry-audio iframe {
    max-width: 100%;
    width: 100%;
    height: 116px;
    margin: 0 0 -8px 0 !important;
}

.blog-masonry .read-more-link {
    display: none;
}

/* Blog Single /--------------------------------------------------------- */

.blog-single .post {
    margin: 0 0 40px 0;
    padding: 0;
    border: none;
}

.blog-single .post h1 {
    line-height: 1.6 !important;
}

.blog-single .post h1 a {
    color: #444444;
}

.blog-single .post-time {
    display: none;
}

.blog-single .post .entry-quote {
    margin: 0 0 30px 0;
}

.blog-single .post.format-quote {
    padding: 0;
}

.blog-single .post .entry-link {
    margin-bottom: 30px;
}

.entry-tags a {
    color: inherit;
}

/* Alternative Entry-Tag Style -->

.blog-single .entry-tags{
    margin: 10px 0 0 0;
}

.entry-tags a {
    display: inline-block;
    font-size: 13px !important;
    border: 1px solid #efefef;
    border-radius: 30px;
    padding: 1px 20px;
    text-transform: uppercase;
    font-weight: 700;
    color: #999999;
    margin: 0 4px 10px 0;
}

.entry-tags a:hover {
    background: #44bdbd;
    border-color: #44bdbd;
    color: #ffffff !important;
}*/

/* Author Information /--------------------------------------------------------- */

#author-info {
    margin: 0 0 60px 0;
    display: block;
    padding: 60px 0 0 0;
    border-top: 1px solid #efefef;
}

#author-info p {
    margin: 0;
}

#author-info .author-image {
    float: left;
    width: 80px;
    height: 80px;
    border-radius: 4px;
    overflow: hidden;
    margin: 2px 30px 0 0;
}

#author-info .author-image img {
    display: block;
    width: 80px;
    height: 80px;
}

#author-info .author-bio {
    overflow: hidden;
}

#author-info .author-bio h4 {
    text-transform: uppercase;
    font-weight: 700;
    margin: 0;
    padding: 0 0 10px 0;
    font-size: 14px;
    letter-spacing: 1px;
    color: #666666;

}

.author-archive #author-info {
    padding: 0 0 60px 0;
    margin: 0 0 60px 0;
    background: #f9f9f9;
    padding: 30px;
    border: none;
    border-radius: 2px;
}

/* Sharebox /--------------------------------------------------------- */

.sharebox {
    display: block;
    padding: 0;
    margin: -20px 0 60px 0;
}

.sharebox ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.sharebox ul li {
    float: left;
    padding: 0;
    margin: 0 22px 0 0;
    line-height: 1 !important;
}

.sharebox ul li a {
    display: block;
    float: left;
    color: inherit;
    padding: 10px 0;
    margin: 0;
}

.sharebox ul li i {
    display: inline-block;
    font-size: 16px;
    margin: 0 3px 0 0;
    line-height: 1;
}

/*.sharebox a.share-facebook{ color: #3b5998; }
    .sharebox a.share-twitter{ color: #00aced; }
    .sharebox a.share-tumblr{ color: #32506d; }
    .sharebox a.share-pinterest{ color: #cb2027; }
    .sharebox a.share-google{ color: #dd4b39; }
    .sharebox a.share-linkedin{ color: #007bb6; }
    .sharebox a.share-mail{ color: #666666; }*/

/* Related Posts /--------------------------------------------------------- */

#related-posts {
    margin: 0 0 60px 0;
    display: block;
    padding: 60px 0 0 0;
    border-top: 1px solid #efefef;
}

#related-posts ul li span {
    margin-left: 10px;
}

/* Post Navigation /--------------------------------------------------------- */

#post-navigation .prev {
    position: fixed;
    top: 50%;
    left: -25px;
    height: 60px;
    width: 80px;
    background: #444444;
    margin: -30px 0 0 0;
    color: #ffffff;
    border-radius: 0px 2px 2px 0px;
    opacity: 0.3;
    z-index: 1;
    cursor: pointer;
}

#post-navigation .next {
    position: fixed;
    top: 50%;
    right: -25px;
    height: 60px;
    width: 80px;
    background: #444444;
    margin: -30px 0 0 0;
    color: #ffffff;
    border-radius: 2px 0px 0px 2px;
    opacity: 0.3;
    z-index: 1;
    cursor: pointer;
}

#post-navigation .prev:before {
    position: absolute;
    top: 50%;
    right: 25px;
    margin: -7px 0 0 0;
    line-height: 1;
    content: "\f053";
    font-size: 14px;
    color: #ffffff;
    font-family: 'FontAwesome';
}

#post-navigation .next:before {
    position: absolute;
    top: 50%;
    left: 25px;
    margin: -7px 0 0 0;
    line-height: 1;
    content: "\f054";
    font-size: 14px;
    color: #ffffff;
    font-family: 'FontAwesome';
}

/* ------------------------------------------------------------------------ */
/* Portfolio - Single
/* ------------------------------------------------------------------------ */

/* Fix Extended Portfolio Width */
.portfolio .entry {
    width: 100%;
}

.portfolio-sidebyside,
.portfolio-wide {
    margin: 0 0 20px 0;
}

.portfolio-detail h3 {

}

.portfolio-noslider {
    text-align: center;
}

.portfolio-noslider img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
}

.portfolio-noslider .noslide {
    border-radius: 2px;
    overflow: hidden;
    display: inline-block;
    margin: 0 auto 30px 0;
    text-align: center;
    max-width: 100%;
}

#portfolio-embed {
    margin-bottom: 40px;
}

#portfolio-embed iframe {
    max-width: 100%;
}

.portfolio-detail-attributes ul {
    list-style: none;
    margin: 0;
    background: #f9f9f9;
    padding: 14px 24px;
    border-radius: 2px;
}

.portfolio-detail-attributes ul li {
    padding: 10px 0;
    border-bottom: 1px solid #f2f2f2;
}

.portfolio-detail-attributes ul li:last-child {
    border: none;
}


.portfolio-detail-attributes ul li strong {
    float: left;
    display: block;
    width: 64px;
    font-weight: 700;
}

.portfolio-detail-attributes ul li span {
    display: block;
    overflow: hidden;
}

.portfolio-detail-attributes ul li a {
    color: inherit;
}

.portfolio-detail-attributes ul li a:hover {
    color: #44bdbd;
}

/* Layout Specific /--------------------------------------------------------- */
.portfolio-wide .flexslider,
.portfolio-sidebyside .flexslider {
    margin: 0 0 40px;
}

.portfolio-sidebyside .portfolio-detail-description-text {
    margin-bottom: 40px;
}

/* Related Portfolio Items /--------------------------------------------------------- */
#portfolio-related-post {
    margin: 30px 0 0px 0;
}

/* ------------------------------------------------------------------------ */
/* Search Page
/* ------------------------------------------------------------------------ */
.search-page .read-more-link {
    display: none;
}

.search-page .post .entry-icon {
    float: left;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin-right: 30px;
    margin-top: 1px;
    background: #efefef;
    border-radius: 3px;
    color: #999999;
}

.search-page .post .entry-icon i {
    font-size: 14px;
    line-height: 1;
}

.search-page .post .entry-wrap {
    overflow: hidden;
}

.search-page .post h3 {
    margin: 0 0 0px 0;
    font-size: 20px;
}

.search-page .post .entry-content {
    margin: 10px 0 0 0;
}

.search-page .post .entry-type {
    color: #adadad;
    font-weight: 700;
    text-transform: uppercase;
}

.search-page h3 {
    margin: 0 0 0 0;
}

.search-page #content #searchform input {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 20px 10px 0 0;
    width: 440px;
    max-width: 100%;
    display: inline-block;
    float: left;
}

.search-page #content #searchform input#searchsubmit {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 20px 0 0 0;
    width: auto;
    display: inline-block;
    float: left;
}

.search-page #content #searchform {
    clear: both;
    margin-bottom: 0px;
}

/* ------------------------------------------------------------------------ */
/* Pagination
/* ------------------------------------------------------------------------ */

#pagination {
    position: relative;
    z-index: 1;
    clear: both;
    margin: 0 0 60px 0;
    text-align: center;
}

/* Custom Pagination Margins */
.blog-masonry #pagination {
    margin: 0px 0 60px 0;
}

.blog-center #pagination {
    margin: 0 0 20px 0;
}

.blog-fullwidth #pagination {
    margin: 0 0 20px 0;
}

.blog-medium #pagination {
    margin: 0 0 20px 0;
}

.woocommerce #pagination {
    margin: 20px 0 20px 0;
}

.portfolio-nomargin #pagination {
    margin: 60px 0 60px 0;
}

.pagination-nomargin, .pagination-masonry {
    margin-top: 60px;
}

#pagination ul {
    list-style: none;
    margin: 0;
}

#pagination ul li {
    margin: 0;
    padding: 0;
    display: inline-block;
}

#pagination a {
    display: inline-block;
    margin: 2px;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    background: #efefef;
    color: #999999;
    font-weight: 600;
    border-radius: 50%;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}

#pagination a:hover {
    color: #ffffff;
    background: #cccccc;
}

#pagination .current a {
    background: #44bdbd;
    color: #ffffff;
    font-weight: 600;
}

#pagination li span {
    margin: 3px 5px 0 3px;
    color: #cccccc;
}

#pagination ul li a i {
    font-size: 12px;
}

/* ------------------------------------------------------------------------ */
/* Comments - List
/* ------------------------------------------------------------------------ */
.comments-list {
    border-top: 1px solid #efefef;
    padding: 60px 0 0 0;
    margin: 0 0 60px 0;
}

.commentlist {
    list-style: none;
    margin: 30px 0 0 0;
}

.commentlist li {
    margin: 0 0 40px 0;
    padding: 0;
}

.commentlist > li:last-child {
    margin: 0 0 0px 0;
}

.commentlist li ul {
    list-style: none;
    margin: 0 0 0 80px;
}

.commentlist li ul li {
    margin: 40px 0 0 0;
    padding: 0 0 0 0;
}

.commentlist li .avatar {
    float: left;
    margin-right: 30px;
    width: 50px;
    height: 50px;
    border-radius: 4px;
    position: relative;
}

.commentlist li .avatar img {
    display: block;
}

.commentlist li .comment-text {
    background: #f9f9f9;
    border-radius: 2px;
    padding: 20px 20px 0 20px;
    position: relative;
    overflow: hidden;
}

.commentlist li .avatar:after {
    display: block;
    content: '';
    position: absolute;
    top: 15px;
    right: -30px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 10px 0;
    border-color: transparent #f9f9f9 transparent transparent;
}

.commentlist li .author {
    margin-top: 0px;
    margin-bottom: 20px;
    line-height: 1.6;
    color: #444444;
}

.commentlist li .author a {
    color: inherit;
}

.commentlist li .author span {
    display: inline;
    font-weight: 700;
}

.commentlist li .date {
    display: block;
    color: #999999;
}

.commentlist li .date a {
    color: #999999
}

.commentlist li .date a:hover {
    color: #333333
}

.commentlist li em {
    font-size: 11px;
    color: #cccccc;
    font-style: normal;
    margin-bottom: 20px;
    display: block;
}

/* ------------------------------------------------------------------------ */
/* Comments - Respond
/* ------------------------------------------------------------------------ */
.comments-reply {
    border-top: 1px solid #efefef;
    padding: 60px 0 0 0;
    margin: 0;
}

#respond {
}

#respond form {
    margin: 0;
}

#respond .required {
    color: #c82929;
    display: inline-block;
    margin: 3px 0 0 6px;
    font-size: 14px;
    font-weight: normal;
}

/*#respond p { clear: both }*/

#respond-inputs p {
    width: 32%;
    margin-right: 2%;
    float: left;
}

#respond-inputs p.last {
    margin-right: 0;
}

#respond input[type=text] {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    width: 100%;
    margin: 0;
}

#respond textarea {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    width: 100%;
}

#respond p.comment-notes,
#respond p.form-allowed-tags {
    display: none;
}

#cancel-comment-reply-link {
    display: inline-block;
    background: #cccccc;
    color: #ffffff;
    border-radius: 2px;
    text-transform: uppercase;
    padding: 2px 8px;
    font-weight: 700;
    float: right;
}

#cancel-comment-reply-link:hover {
    background: #999999;
}

.commentlist .comment-respond {
    margin-top: 40px;
}

/* Comment Styling for normal Pages */
.page-template #comments,
.page-template-default #comments {
    margin-top: 60px;
}

.single-portfolio #comments {
    margin-top: 20px;
    margin-bottom: 60px;
}

.single-portfolio #comments .comments-list,
.single-portfolio #comments .comments-reply {
    padding: 0;
    border: none;
}

/*.page-template #comments .comments-list,
.page-template #comments .comments-reply,
.page-template-default #comments .comments-list,
.page-template-default #comments .comments-reply{
    padding:0;
    border: none;
}*/

/* ------------------------------------------------------------------------ */
/* 404 Page
/* ------------------------------------------------------------------------ */
.page-404 .entry {
    text-align: center;
    padding: 40px 0 80px 0;
}

.page-404 h2 {
    font-size: 100px;
    color: #666666;
    font-weight: 700;
    width: 300px;
    height: 300px;
    line-height: 300px;
    text-align: center;
    border: 10px solid #999999;
    color: #999999;
    border-radius: 50%;
    margin: 0 auto 60px auto;
}

.page-404 h3 {
    font-size: 52px;
    font-weight: 700;
    line-height: 1.2;
    margin: 0;
    color: #999999;
    text-transform: uppercase;
}

/* ------------------------------------------------------------------------ */
/* Footer
/* ------------------------------------------------------------------------ */
#footer {
    padding: 60px 0 20px 0;
    background: #555555;
    color: #aaaaaa;
}

#footer a {
    color: #aaaaaa;
}

#footer a:hover {
    color: #ffffff;
}

/* ------------------------------------------------------------------------ */
/* Copyright
/* ------------------------------------------------------------------------ */
#copyright {
    padding: 15px 0 13px 0;
    background: #333333;
    color: #777777;
}

#copyright .copyright-col1 {
    float: left;
    margin: 0;
    padding: 0;
}

#copyright .copyright-col2 {
    float: right;
    margin: 0;
    padding: 0;
}

#copyright a {
    color: #999999
}

#copyright a:hover {
    color: #cccccc
}

#copyright .copyright-text {
}

#copyright .social-icons {
    float: right;
    margin: -7px 0;
}

#copyright .menu {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: right;
}

#copyright .menu li {
    display: inline-block;
    margin: 0 0 0 20px;
    padding: 0;
}

/* ------------------------------------------------------------------------ */
/* Back-To-Top Button
/* ------------------------------------------------------------------------ */
#back-to-top {
    position: fixed;
    z-index: 1000;
    bottom: 0px;
    right: 24px;
    display: none;
}

#back-to-top a {
    position: relative;
    display: block;
    width: 42px;
    height: 36px;
    background: #000000;
    color: #ffffff;
    font-size: 14px;
    line-height: 36px;
    text-align: center;
    border-radius: 3px 3px 0 0;
    opacity: 0.8;
}

#back-to-top a:hover {
    background-color: #44bdbd;
    opacity: 1
}

/* ------------------------------------------------------------------------ */
/* Sidebar Widgets
/* ------------------------------------------------------------------------ */

/* General Widgets /--------------------------------------------------------- */

.widget ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

#sidebar .widget {
    margin-bottom: 40px;
    color: #888888;
}

#sidebar .widget:last-child {
    margin-bottom: 0;
}

#sidebar .widget h3 {
    font-size: 14px;
    font-weight: 700;
    margin: 0 0 16px 0;
    color: #444444;
}

#sidebar .widget ul {
    margin: -10px 0 0 0;
}

#sidebar .widget ul li {
    position: relative;
}

#sidebar .widget > ul > li {
    border-bottom: 1px solid #efefef;
    position: relative;
    padding: 10px 0;
}

#sidebar .widget > ul > li:last-child {
    border: none;
}

#sidebar .widget > ul > li ul {
    margin-top: 10px;
    margin-bottom: -10px;
    border-top: 1px solid #efefef;
}

#sidebar .widget > ul > li ul li {
    background: #f9f9f9;
    padding: 10px 0 10px 14px;
    border-bottom: 1px dashed #efefef;
}

#sidebar .widget > ul > li ul li:last-child {
    border-bottom: none;
}

#sidebar .widget > ul > li ul li a:before {
    content: '+';
    margin-right: 10px;
}

#sidebar .widget ul li:after {
    font-family: "FontAwesome";
    content: "\f105";
    position: absolute;
    top: 10px;
    right: 0;
    font-size: 14px;
    color: #cccccc;
}

#sidebar .widget ul li a {
    display: block;
    color: #777777;
}

#sidebar .widget ul li a:hover {
    color: #44bdbd;
}

#sidebar .widget ul li:hover:after {
    color: #44bdbd;
}

#sidebar .widget > ul > li.current_page_item,
#sidebar .widget > ul > li.current-menu-item {
    font-weight: 600;
}

#sidebar .widget img {
    margin-bottom: 0 !important;
}

/* Search Widget /--------------------------------------------------------- */

.widget_search input {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #e1e1e1;
    padding: 10px 12px 10px 36px;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, .03);
    border-radius: 2px;
    background: #ffffff;
}

.widget_search form {
    position: relative;
}

.widget_search form:before {
    position: absolute;
    top: 14px;
    left: 14px;
    font-size: 14px;
    font-family: 'FontAwesome';
    content: '\f002';
    -webkit-font-smoothing: antialiased;
    color: #999999;
    line-height: 1;
}

.widget_search input:focus {
    border-color: #d3d3d3;
}

.widget_search #searchsubmit {
    display: none;
}

.widget_search:after {
    content: "\0020";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

/* Calendar Widget /--------------------------------------------------------- */

#wp-calendar {
    width: 100%
}

#wp-calendar caption {
    padding: 0 10px;
    text-align: right;
    font-weight: 600;
    margin: -5px 0 5px 0;
}

#wp-calendar thead {
    font-size: 10px
}

#wp-calendar thead th {
    padding: 5px 10px;
    font-weight: 600;
    border-top: 1px solid #f9f9f9;
}

#wp-calendar tbody {
    color: #aaa
}

#wp-calendar tbody td {
    background: #f9f9f9;
    border: 1px solid #fff;
    text-align: center;
    padding: 5px 8px;
}

#wp-calendar tbody td:hover {
    background: #fff
}

#wp-calendar tbody .pad {
    background: none
}

#wp-calendar tfoot #next {
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: right;
    padding-top: 5px;
}

#wp-calendar tfoot #prev {
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    padding-top: 5px;
}

/* Tag Cloud Widget /--------------------------------------------------------- */

.widget_tag_cloud a,
.widget_product_tag_cloud a {
    display: inline-block;
    font-size: 13px !important;
    border: 1px solid #efefef;
    border-radius: 30px;
    padding: 1px 20px;
    text-transform: uppercase;
    font-weight: 700;
    color: #999999;
    margin: 0 4px 10px 0;
}

.widget_tag_cloud a:hover,
.widget_product_tag_cloud a:hover {
    background: #44bdbd;
    border-color: #44bdbd;
    color: #ffffff !important;
}

/* Sponsor Widget /--------------------------------------------------------- */

.widget_sponsor .sponsors {
    margin-right: -20px;
}

.widget_sponsor a {
    display: block;
    float: left;
    margin: 0 20px 20px 0;
}

.widget_sponsor a img {
    vertical-align: top;
}

/* Flickr Widget /--------------------------------------------------------- */

/*.widget_flickr a{
    float: left;
    display: block;
    width: 33%;
    height: auto;
}*/

.widget_flickr a:hover img {
    opacity: 0.6;
}

.widget_flickr img {
    width: 100%;
    height: auto;
    float: left;
    vertical-align: top;
}

/* Portfolio Widget /--------------------------------------------------------- */

.widget_portfolio .portfolio-widget-item {
    float: left;
    padding: 0;
    margin: 0 10px 10px 0;
}

.widget_portfolio .portfolio-widget-item:nth-child(3n) {
    margin: 0 0 10px 0;
}

.widget_portfolio .portfolio-widget-item .portfolio-pic {
    display: block;
    overflow: hidden;
    padding: 0;
    margin: 0;
    position: relative;
    border-radius: 2px;
}

.widget_portfolio .portfolio-widget-item .portfolio-pic img {
    width: 100%;
    height: 100%;
    display: block;
}

.widget_portfolio .portfolio-widget-item .portfolio-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #44bdbd;
    text-align: center;
    opacity: 0;
}

.widget_portfolio .portfolio-widget-item .portfolio-overlay i {
    font-size: 24px;
    line-height: 80px;
    color: #ffffff;
}

/* Contact Widget /--------------------------------------------------------- */

.widget_contact address {
    font-style: normal;
}

.widget_contact address span {
    display: block;
    margin-bottom: 6px;
}

.widget_contact address strong {
    display: none;
}

.widget_contact address span i {
    display: inline-block;
    width: 24px;
    float: left;
    margin: 5px 0 0px 0;
}

.widget_contact .adress-overflow {
    overflow: hidden;
}

/* Custom Menu Widget /--------------------------------------------------------- */

#sidebar .widget_nav_menu {
    margin-left: -41px;
}

#sidebar .widget_nav_menu h3 {
    margin-left: 40px;
    margin-bottom: 20px;
}

#sidebar .widget_nav_menu ul {
    margin-top: 20px;
}

#sidebar .widget_nav_menu ul li {
    border: none;
    padding: 0;
}

#sidebar .widget_nav_menu ul li a {
    display: block;
    color: #999999;
    padding: 7px 0 7px 41px;
}

#sidebar .widget_nav_menu ul li a:hover {
    color: #44bdbd;
}

#sidebar .widget_nav_menu ul li.current_page_item a {
    font-weight: 600;
    color: #444444;
    margin: -1px 0;
    border: 1px solid #efefef;
    border-left: 1px solid #ffffff;
}

#sidebar .widget_nav_menu ul li:after {
    display: none;
}

/* For the left Side */
#sidebar.sidebar-left .widget_nav_menu {
    margin-left: 0;
    margin-right: -41px;
}

#sidebar.sidebar-left .widget_nav_menu h3 {
    margin-left: 0px;
}

#sidebar.sidebar-left .widget_nav_menu ul li a {
    text-align: right;
    padding: 7px 41px 7px 0;
}

#sidebar.sidebar-left .widget_nav_menu ul li.current_page_item a {
    font-weight: 600;
    color: #444444;
    margin: -1px 0;
    border: 1px solid #efefef;
    border-right: 1px solid #ffffff;
}

/* ------------------------------------------------------------------------ */
/* Footer Widgets
/* ------------------------------------------------------------------------ */
#footer .widget {
    margin-bottom: 40px;
}

#footer .widget h3 {
    font-size: 13px;
    font-weight: 600;
    margin: 0 0 20px 0;
    color: #ffffff;
}

#footer .widget ul {
    margin: -10px 0 0 0;
}

#footer .widget ul li {
    border-bottom: 1px solid #666666;
    position: relative;
    padding: 10px 10px 10px 0;
}

#footer .widget ul li .post-date {
    opacity: 0.7;
}

#footer .widget ul li a {
    display: block;
}

#footer .widget ul li:after {
    font-family: "FontAwesome";
    content: "\f105";
    position: absolute;
    top: 10px;
    right: 0;
    font-size: 14px;
    color: #999999;
}

#footer .widget ul li:hover:after {
    color: #ffffff;
}

#footer .widget_search input,
#footer .widget_product_search input[type=text] {
    color: #aaaaaa;
    background-color: #444444;
    box-shadow: none;
    border: 1px solid #444444;
}

#footer .widget_search input:focus {
    color: #ffffff
}

#footer #wp-calendar thead th {
    border-top: 1px solid #666666
}

#footer #wp-calendar tbody {
    color: #aaa
}

#footer #wp-calendar tbody td {
    background: #666666;
    border: 1px solid #555555;
}

#footer #wp-calendar tbody td:hover {
    background: #777777;
    color: #ffffff;
}

#footer #wp-calendar tbody .pad {
    background: none
}

#footer .widget_tag_cloud a,
#footer .widget_product_tag_cloud a {
    border: none;
    background: #444444;
    color: #aaaaaa;
    box-shadow: none;
}

#footer .widget_tag_cloud a:hover,
#footer .widget_product_tag_cloud a:hover {
    background: #44bdbd;
    border-color: #44bdbd;
    color: #ffffff !important;
}

#footer .widget_twitter ul li:last-child {
    margin: 0
}

/* ------------------------------------------------------------------------ */
/* Social Icons
/* ------------------------------------------------------------------------ */

.social-icons ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.social-icons ul li {
    list-style: none;
    display: block;
    width: 40px;
    height: 40px;
    float: left;
}

.social-icons ul li a {
    -webkit-transition: all 0.2s ease 0s;
    -moz-transition: all 0.2s ease 0s;
    -o-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
    display: block;
    float: left;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 21px;
    text-align: center;
    opacity: 0.6;
}

.social-icons a:hover {
    opacity: 1;
}


/* ------------------------------------------------------------------------ */
/* Basic WordPress WYSIWYG Editor Styles
/* ------------------------------------------------------------------------ */

.alignleft {
    display: block;
    float: left;
    margin-top: 5px;
    margin-right: 20px !important;
    margin-bottom: 12px;
}

.alignright {
    float: right;
    margin-left: 20px !important;
    margin-bottom: 12px;
}

.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.wp-caption {
    max-width: 100%; /* Keep wide captions from overflowing their container. */
    padding: 0px;
}

.wp-caption .wp-caption-text,
.gallery-caption,
.entry-caption {
    color: #888888;
    margin: 10px 0 10px 0 !important;
}

img.wp-smiley,
.rsswidget img {
}

blockquote.left {
    margin-right: 20px;
    text-align: right;
    margin-left: 0;
    width: 33%;
    float: left;
}

blockquote.right {
    margin-left: 20px;
    text-align: left;
    margin-right: 0;
    width: 33%;
    float: right;
}

.size-full {
}

.size-large {
}

.size-medium {
}

.size-thumbnail {
}

.sticky {
}

.bypostauthor {
}

/* Text meant only for screen readers. */
.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
}

.screen-reader-text:focus {
    background-color: #f1f1f1;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    clip: auto !important;
    color: #21759b;
    display: block;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: bold;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000; /* Above WP toolbar. */
}

/* ------------------------------------------------------------------------ */
/* Plugin - Custom Select
/* ------------------------------------------------------------------------ */
.hidden_select_container {
    width: 0;
    height: 0;
    overflow: hidden;
}

.simpleselect, .simpleselect * {
    box-sizing: content-box;
}

.simpleselect {
    font-weight: 400 !important;
    position: relative;
    width: 100%;
    min-width: 220px;
    color: #999999;
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
}

.simpleselect .options {
    width: 100%;
    min-width: 198px;
}

.simpleselect .placeholder, .simpleselect .options .option, .simpleselect .options .optgroup .optgroup-label {
    padding: 8px 12px;
    cursor: pointer;
}

.simpleselect .options .optgroup .optgroup-label {
    cursor: default;
    font-weight: 400 !important;
}

.simpleselect .options .optgroup .option {
    padding-left: 20px;
}

.simpleselect .placeholder, .simpleselect .options {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 2px;
}

.simpleselect .placeholder {
    position: relative;
}

.simpleselect .placeholder:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 12px;
    width: 8px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAOCAYAAAASVl2WAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NjA0Q0FGMUI4RTU1MTFFMzhGMThCQzUzMTMxMzdBN0YiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NjA0Q0FGMUM4RTU1MTFFMzhGMThCQzUzMTMxMzdBN0YiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2MDRDQUYxOThFNTUxMUUzOEYxOEJDNTMxMzEzN0E3RiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo2MDRDQUYxQThFNTUxMUUzOEYxOEJDNTMxMzEzN0E3RiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pq8U8ZgAAAClSURBVHjaYpw7dw4DEggEYh4gXgwTYEGS5AfiyVD6FBDfBAkyISloB2JpqAlLgZgNWYENEGciKTYG4haYFSCVKA6BglIg3gdSIAHEfQzYASfj////GfABJgYCgGXevLlyQNoDh/xrkBteAHEREKtjUeAJsuIXEKdgkewG4h0wNxwB4ulIkmeBuAbdkZVA/BSIvwBxNNRklLj4CMS50KC+CRMECDAA5ogdqmResXgAAAAASUVORK5CYII=) 0 center no-repeat;
}

.simpleselect .placeholder:hover {
    background: #fafafa;
}

.simpleselect .options {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
}

.simpleselect .options .option.active {
    background: #666;
    color: #fff;
}

.simpleselect.disabled .placeholder, .simpleselect.disabled .placeholder:hover {
    background: #fafafa;
    color: #aaa;
    border-color: #eee;
    cursor: default;
}

.simpleselect.disabled .placeholder:after {
    opacity: .5;
}

/* ------------------------------------------------------------------------ */
/* Plugin - Contact Form 7
/* ------------------------------------------------------------------------ */
.wpcf7-form input[type="text"], .wpcf7-form input[type="email"], .wpcf7-form input[type="tel"] {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100% !important;
    max-width: 100%;
    margin: 5px 0 0;
    background: #ffffff;
}

.wpcf7-form textarea {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    margin: 5px 0 0;
}

.wpcf7-form select {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    max-width: 100%;
    margin: 5px 0 0;
    width: 100%;
}

.wpcf7-form .customSelect {
    margin: 5px 0 0;
    padding: 8px 10px;
}

.wpcf7-form {
    margin: 0;
    padding: 0;
}

.wpcf7-form .wpcf7-submit {
    background: #999999;
    color: #ffffff;
    border: none;
    font-weight: 700;
}

.wpcf7-form input[type="checkbox"] {
    width: auto;
}

.wpcf7 form.wpcf7-form p {
    position: relative;
    font-weight: 600;
    margin: 0 0 20px !important;
}

.wpcf7-list-item {
    display: inline-block;
    margin: 0 10px 0 0 !important;
}

.wpcf7-list-item-label {
    font-weight: normal;
}

.wpcf7-response-output {
    display: none;
}

.wpcf7-form .invalid {
    color: #bf1515 !important;
}

.wpcf7-not-valid {
    border-color: #bf1515 !important;
}

/*.wpcf7 span.wpcf7-not-valid-tip{color:rgba(0,0,0,0.6);;margin-top:-1px;background:rgba(0,0,0,0.05);padding:4px 10px;border-radius:0 0 2px 2px;}*/
.wpcf7 span.wpcf7-not-valid-tip, .wpcf7-validation-errors {
    display: none !important;
}

div.wpcf7-response-output {
    margin: 0 !important;
    padding: 5px 10px !important;
}

div.wpcf7-mail-sent-ok, div.wpcf7-mail-sent-ng, div.wpcf7-spam-blocked, div.wpcf7-validation-errors {
    position: relative;
    display: block;
    text-align: center;
    border-radius: 2px;
    font-weight: 700;
    padding: 12px 40px 12px 20px !important; /*border:1px solid #edbbbb !important;*/
    border: none !important;
    background-color: #44bdbd;
    color: #ffffff;
    margin: 0 0 30px !important;
}

div.wpcf7-mail-sent-ok {
    color: #ffffff;
}

.one-line-form .wpcf7-submit {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    margin: 5px 0 0 !important;
    padding: 15px 16px;
    font-weight: 700;
}

.wpcf7-form .simpleselect {
    margin: 5px 0 0 !important;
}

.minimalistic_form input[type="text"],
.minimalistic_form input[type="password"],
.minimalistic_form input[type="email"],
.minimalistic_form input[type="tel"],
.minimalistic_form textarea {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100% !important;
    max-width: 100%;
    margin: 0;
    padding: 10px 2px;
    font-size: 18px !important;
    line-height: 1;
    background-color: transparent !important;
    border-top: transparent;
    border-right: transparent;
    border-left: transparent;
    border-bottom-width: 1px;
    border-radius: 0;
}

.minimalistic_form .simpleselect .placeholder {
    border: 1px solid #ddd;
    border-radius: 2px;
    background-color: transparent !important;
    border-top: transparent;
    border-right: transparent;
    border-left: transparent;
    padding: 0px 2px 10px 2px !important;
    margin: 0;
    cursor: pointer;
    font-size: 18px !important;
    border-radius: 0;
}


/* ------------------------------------------------------------------------ */
/* Plugin - Tipsy
/* ------------------------------------------------------------------------ */
.tipsy {
    font-size: 13px;
    position: absolute;
    padding: 5px;
    z-index: 100000;
}

.tipsy-inner {
    background-color: #000;
    color: #FFF;
    max-width: 200px;
    padding: 5px 12px 4px 12px;
    text-align: center;
}

/* Rounded corners */
.tipsy-inner {
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}

.tipsy-arrow {
    position: absolute;
    width: 0;
    height: 0;
    line-height: 0;
    border: 5px dashed #000;
}

/* Rules to colour arrows */
.tipsy-arrow-n {
    border-bottom-color: #000;
}

.tipsy-arrow-s {
    border-top-color: #000;
}

.tipsy-arrow-e {
    border-left-color: #000;
}

.tipsy-arrow-w {
    border-right-color: #000;
}

.tipsy-n .tipsy-arrow {
    top: 0px;
    left: 50%;
    margin-left: -5px;
    border-bottom-style: solid;
    border-top: none;
    border-left-color: transparent;
    border-right-color: transparent;
}

.tipsy-nw .tipsy-arrow {
    top: 0;
    left: 10px;
    border-bottom-style: solid;
    border-top: none;
    border-left-color: transparent;
    border-right-color: transparent;
}

.tipsy-ne .tipsy-arrow {
    top: 0;
    right: 10px;
    border-bottom-style: solid;
    border-top: none;
    border-left-color: transparent;
    border-right-color: transparent;
}

.tipsy-s .tipsy-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-top-style: solid;
    border-bottom: none;
    border-left-color: transparent;
    border-right-color: transparent;
}

.tipsy-sw .tipsy-arrow {
    bottom: 0;
    left: 10px;
    border-top-style: solid;
    border-bottom: none;
    border-left-color: transparent;
    border-right-color: transparent;
}

.tipsy-se .tipsy-arrow {
    bottom: 0;
    right: 10px;
    border-top-style: solid;
    border-bottom: none;
    border-left-color: transparent;
    border-right-color: transparent;
}

.tipsy-e .tipsy-arrow {
    right: 0;
    top: 50%;
    margin-top: -5px;
    border-left-style: solid;
    border-right: none;
    border-top-color: transparent;
    border-bottom-color: transparent;
}

.tipsy-w .tipsy-arrow {
    left: 0;
    top: 50%;
    margin-top: -5px;
    border-right-style: solid;
    border-left: none;
    border-top-color: transparent;
    border-bottom-color: transparent;
}


/* ------------------------------------------------------------------------ */
/* Plugin - Revolution Slider
/* ------------------------------------------------------------------------ */
.tp-bullets.simplebullets.custom .bullet {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block !important;
    cursor: pointer !important;
    border: 2px solid #fff !important;
    width: 12px !important;
    height: 12px !important;
    border-radius: 50% !important;
    margin-left: 7px !important;
    -webkit-transition: color .2s;
    -moz-transition: color .2s;
    -ms-transition: color .2s;
    -o-transition: color .2s;
    transition: all .2s
}

.tp-bullets.simplebullets.custom .bullet.last {
    margin-right: 7px !important
}

.tp-bullets.simplebullets.custom .bullet {
    opacity: .5
}

.tp-bullets.simplebullets.custom .bullet:hover {
    opacity: 1
}

.tp-bullets.simplebullets.custom .bullet.selected {
    background: #fff !important;
    opacity: 1
}

/* Custom Bullets v5+ */
.tp-bullets.custom .tp-bullet {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: none;
    display: inline-block !important;
    cursor: pointer !important;
    border: 2px solid #fff !important;
    width: 12px !important;
    height: 12px !important;
    border-radius: 50% !important;
    margin-left: 7px !important;
    -webkit-transition: color .2s;
    -moz-transition: color .2s;
    -ms-transition: color .2s;
    -o-transition: color .2s;
    transition: all .2s
}

.tp-bullets.custom .tp-bullet.last {
    margin-right: 7px !important
}

.tp-bullets.custom .tp-bullet {
    opacity: .5
}

.tp-bullets.custom .tp-bullet:hover {
    opacity: 1
}

.tp-bullets.custom .tp-bullet.selected {
    background: #fff !important;
    opacity: 1
}

.tp-leftarrow.custom, .tp-rightarrow.custom {
    margin-top: 0px !important;
    top: 50% !important;
    background: #ffffff !important;
    color: #333333 !important;
    width: 60px !important;
    height: 60px !important;
    line-height: 60px !important;
    text-align: center !important;
    -webkit-transition: background-color .2s;
    -moz-transition: background-color .2s;
    -ms-transition: background-color .2s;
    -o-transition: background-color .2s;
    transition: background-color .2s
}

.tp-leftarrow.custom {
    border-radius: 0 2px 2px 0 !important;
}

.tp-rightarrow.custom {
    border-radius: 2px 0 0 2px !important;
}

.tp-leftarrow.custom:before {
    color: #333333;
    line-height: 60px !important;
    content: "\f104" !important;
    font-family: 'FontAwesome' !important;
    font-size: 40px;
    margin-right: 3px;
    -webkit-font-smoothing: antialiased
}

.tp-rightarrow.custom:before {
    color: #333333;
    line-height: 60px !important;
    content: "\f105" !important;
    font-family: 'FontAwesome' !important;
    font-size: 40px;
    margin-left: 3px;
    -webkit-font-smoothing: antialiased
}

.tp-leftarrow.custom:hover, .tp-rightarrow.custom:hover {
    background: rgba(0, 0, 0, 0.79) !important;
    opacity: 1 !important
}

.tp-leftarrow.custom:hover:before, .tp-rightarrow.custom:hover:before {
    color: #ffffff !important;
}

.tparrows {
    line-height: 1 !important;
}

.rev_slider a.button {
    -webkit-transition: all 0.2s !important;
    -moz-transition: all 0.2s !important;
    -ms-transition: all 0.2s !important;
    -o-transition: all 0.2s !important;
    transition: all 0.2s !important;
}

/* ------------------------------------------------------------------------ */
/* Plugin - PrettyPhoto CSS edited by mintithemes.com
/* ------------------------------------------------------------------------ */
div.pp_default .pp_expand {
    cursor: pointer;
    width: 30px;
    height: 30px;
    margin: 0 11px 0 0;
}

div.pp_default .pp_expand:after {
    background: rgba(0, 0, 0, .2);
    color: #fff;
    position: absolute;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    top: 0;
    left: 0;
    text-indent: 0;
    font-size: 14px;
    font-family: FontAwesome;
    content: '\f065';
    -webkit-font-smoothing: antialiased;
}

div.pp_default .pp_contract {
    cursor: pointer;
    width: 30px;
    height: 30px;
    margin: 0 11px 0 0;
}

div.pp_default .pp_contract:after {
    background: rgba(0, 0, 0, .2);
    color: #fff;
    position: absolute;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    top: 0;
    left: 0;
    text-indent: 0;
    font-size: 14px;
    font-family: FontAwesome;
    content: '\f066';
    -webkit-font-smoothing: antialiased;
}

a.pp_contract, a.pp_expand {
    cursor: pointer;
    display: none;
    height: 20px;
    position: absolute;
    right: 30px;
    text-indent: -10000px;
    top: 10px;
    width: 20px;
    z-index: 20000;
}

div.pp_default .pp_close {
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: block;
    margin: 5px -7px 0 0;
    padding: 0;
    line-height: 1;
    color: #fff;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
}

div.pp_default .pp_close:hover {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

div.pp_default .pp_close:after {
    position: absolute;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    top: 0;
    left: 0;
    text-indent: 0;
    font-size: 18px;
    font-family: 'icomoon';
    content: '\e603';
    -webkit-font-smoothing: antialiased;
}

div.pp_default .pp_gallery ul li a {
    border-radius: 1px;
    overflow: hidden;
}

div.pp_default .pp_gallery a.pp_arrow_next, div.pp_default .pp_gallery a.pp_arrow_previous {
    position: static;
    left: auto;
}

div.pp_default .pp_nav .pp_pause, div.pp_default .pp_nav .pp_play {
    height: 30px;
    width: 30px;
    margin: 0;
    color: #fff;
    display: none;
}

div.pp_default .pp_nav .pp_play:after {
    position: absolute;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    top: 0;
    left: 0;
    text-indent: 0;
    font-size: 14px;
    font-family: FontAwesome;
    content: '\f04b';
    -webkit-font-smoothing: antialiased;
}

div.pp_default .pp_nav .pp_pause:after {
    position: absolute;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    top: 0;
    left: 0;
    text-indent: 0;
    font-size: 14px;
    font-family: FontAwesome;
    content: '\f04c';
    -webkit-font-smoothing: antialiased;
}

div.pp_default a.pp_arrow_next, div.pp_default a.pp_arrow_previous {
    height: 30px;
    width: 30px;
    position: relative;
    color: #fff;
}

div.pp_default a.pp_arrow_previous:after {
    position: absolute;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    top: 0;
    left: 0;
    text-indent: 0;
    font-size: 14px;
    font-family: FontAwesome;
    content: '\f053';
    -webkit-font-smoothing: antialiased;
}

div.pp_default a.pp_arrow_next:after {
    position: absolute;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    top: 0;
    left: 0;
    text-indent: 0;
    font-size: 14px;
    font-family: FontAwesome;
    content: '\f054';
    -webkit-font-smoothing: antialiased;
}

div.pp_default .pp_nav .currentTextHolder {
    color: #fff;
    display: block;
    text-align: center;
    padding: 0 4px;
    width: 30px;
    font-size: 14px;
    line-height: 30px;
    margin: 0;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    opacity: 0.7;
}

div.pp_default .pp_arrow_next:hover, div.pp_default .pp_arrow_previous:hover, div.pp_default .pp_close:hover, div.pp_default .pp_nav .pp_pause:hover, div.pp_default .pp_nav .pp_play:hover {
    opacity: .7;
}

div.pp_pic_holder a:focus {
    outline: 0;
}

div.pp_overlay {
    background: #000000;
    left: 0;
    position: absolute;
    top: 0;
    width: 100% !important;
    z-index: 9999;
    opacity: 0;
    -webkit-transition: opacity .35s ease;
    -moz-transition: opacity .35s ease;
    -ms-transition: opacity .35s ease;
    -o-transition: opacity .35s ease;
    transition: opacity .35s ease;
}

div.pp_pic_holder {
    display: none;
    position: absolute;
    z-index: 10000 !important;
}

.pp_content {
    margin: 0;
    padding: 0;
}

.pp_content_container {
    margin-left: 15px;
    width: 100%;
}

.pp_description {
    display: none !important;
    margin: 0;
}

.pp_social {
    float: left;
    height: 20px;
    margin: 10px 0 0;
}

.pp_social .facebook {
    float: left;
    overflow: hidden;
}

.pp_social .twitter {
    float: left;
    margin-right: 10px;
}

.pp_nav {
    clear: right;
    float: left;
    margin: 5px 10px 0 -10px;
}

.pp_nav p {
    float: left;
    white-space: nowrap;
    margin: 0;
}

.pp_nav .pp_pause, .pp_nav .pp_play {
    float: left;
    margin-right: 0;
    text-indent: -10000px;
}

a.pp_arrow_next, a.pp_arrow_previous {
    display: block;
    float: left;
    height: 30px;
    overflow: hidden;
    text-indent: -10000px;
    width: 30px;
}

.pp_hoverContainer {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2000;
}

.pp_gallery {
    display: none;
    left: 50%;
    margin-top: -50px;
    position: absolute;
    z-index: 10000;
}

.pp_gallery div {
    float: left;
    overflow: hidden;
    position: relative;
}

.pp_gallery ul {
    float: left;
    height: 35px;
    position: relative;
    white-space: nowrap;
    margin: 0 0 0 5px;
    padding: 0;
}

.pp_gallery ul a {
    border: 1px rgba(0, 0, 0, .3) solid;
    display: block;
    float: left;
    height: 33px;
    overflow: hidden;
}

.pp_gallery ul a img {
    border: 0;
}

.pp_gallery li {
    display: block;
    float: left;
    margin: 0 5px 0 0;
    padding: 0;
}

.pp_gallery li.default a {
    display: block;
    height: 33px;
    width: 50px;
}

a.pp_next {
    display: block;
    float: right;
    height: 100%;
    text-indent: -10000px;
    width: 49%;
    position: relative;
    overflow: hidden;
}

a.pp_next:after {
    position: absolute;
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    right: 0px;
    top: 50%;
    margin-top: 10px;
    border-radius: 2px 0 0 2px;
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    text-indent: 0;
    font-size: 40px;
    font-family: FontAwesome;
    content: '\f105';
    opacity: 0;
    -webkit-font-smoothing: antialiased;
    -webkit-transition: opacity .2s ease;
    -moz-transition: opacity .2s ease;
    -ms-transition: opacity .2s ease;
    -o-transition: opacity .2s ease;
    transition: opacity .2s ease;
}

a.pp_previous {
    display: block;
    float: left;
    height: 100%;
    text-indent: -10000px;
    width: 49%;
    position: relative;
    overflow: hidden;
}

a.pp_previous:after {
    position: absolute;
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    left: 0px;
    top: 50%;
    margin-top: 10px;
    border-radius: 0 2px 2px 0;
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    text-indent: 0;
    font-size: 40px;
    font-family: FontAwesome;
    content: '\f104';
    opacity: 0;
    -webkit-font-smoothing: antialiased;
    -webkit-transition: opacity .2s ease;
    -moz-transition: opacity .2s ease;
    -ms-transition: opacity .2s ease;
    -o-transition: opacity .2s ease;
    transition: opacity .2s ease;
}

div.pp_default .pp_next:hover {
    cursor: pointer;
}

div.pp_default .pp_next:hover:after {
    opacity: 1;
    background: #333333;
}

div.pp_default .pp_previous:hover {
    cursor: pointer;
}

div.pp_default .pp_previous:hover:after {
    opacity: 1;
    background: #333333;
}

a.pp_close {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    text-indent: -10000px;
}

.ppt {
    margin-left: 15px;
    margin-bottom: 5px;
    font-size: 20px;
    color: #fff;
}

#pp_full_res {
    line-height: 1 !important;
}

#pp_full_res .pp_inline {
    text-align: left;
}

#pp_full_res .pp_inline p {
    margin: 0 0 15px;
}

div.dark_rounded .pp_details, div.dark_square .pp_details, div.facebook .pp_details, div.light_rounded .pp_details, div.light_square .pp_details, div.pp_default .pp_details {
    position: relative;
}

.pp_bottom, .pp_top {
    height: 0;
    position: relative;
}

* html .pp_bottom, * html .pp_top {
    padding: 0;
}

.pp_fade, .pp_gallery li.default a img {
    display: none;
}

/* Hide navigation beneath image */
.pp_details .pp_nav .pp_arrow_previous,
.pp_details .pp_nav .pp_arrow_next {
    display: none;
}

/* Fix Loading Icon Crop */
.pp_content {
    min-width: 40px;
    min-height: 40px;
}

/* Loader Animation */
.pp_loaderIcon {
    width: 34px;
    height: 34px;
    border-radius: 54px;
    border: 3px solid rgba(120, 120, 120, 0.8);
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -15px;
    margin-top: -15px;
    z-index: 1 !important;
}

.pp_loaderIcon:after {
    content: "";
    position: absolute;
    top: -3px;
    left: -3px;
    border: 3px solid #ffffff;
    border-radius: 54px;
    height: 34px;
    width: 34px;
    clip: rect(0px, 26px, 17px, 0px);
    -moz-animation: rotate .7s;
    -moz-animation-timing-function: linear;
    -moz-animation-iteration-count: infinite;
    -webkit-animation: rotate .7s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    -ms-animation: rotate .7s;
    -ms-animation-timing-function: linear;
    -ms-animation-iteration-count: infinite;
    animation: rotate .7s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@-moz-keyframes rotate {
    0% {
        -moz-transform: rotate(0);
    }
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes rotate {
    0% {
        -webkit-transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-ms-keyframes rotate {
    0% {
        -ms-transform: rotate(0);
    }
    100% {
        -ms-transform: rotate(360deg);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* ------------------------------------------------------------------------ */
/* Plugin - FlexSlider edited by mintithemes.com
/* ------------------------------------------------------------------------ */
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
    outline: none;
}

.slides,
.flex-control-nav,
.flex-direction-nav {
    margin: 0;
    padding: 0;
    list-style: none;
}

/* FlexSlider Necessary Styles */
.flexslider {
    margin: 0;
    padding: 0;
}

.flexslider .slides > li {
    display: none;
    -webkit-backface-visibility: hidden;
}

/* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img {
    width: 100%;
    display: block;
}

.flex-pauseplay span {
    text-transform: capitalize;
}

/* Clearfix for the .slides element */
.slides:after {
    content: "\0020";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

html[xmlns] .slides {
    display: block;
}

* html .slides {
    height: 1%;
}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides > li:first-child {
    display: block;
}

/* FlexSlider Default Theme */
.flexslider {
    margin: 0;
    position: relative;
    zoom: 1;
}

.flex-viewport {
    max-height: 2000px;
    -webkit-transition: all 0.2 ease;
    -moz-transition: all 0.2 ease;
    -o-transition: all 0.2 ease;
    transition: all 0.2 ease;
}

.loading .flex-viewport {
    max-height: 300px;
}

.flexslider .slides {
    zoom: 1;
}

/* Direction Nav */
.flex-direction-nav {
    *height: 0;
}

.flex-direction-nav a {
    cursor: pointer !important;
    background: rgba(0, 0, 0, 0.3);
    opacity: 0;
    text-decoration: none;
    display: block;
    color: #fff !important;
    width: 60px;
    height: 60px;
    margin: -30px 0 0;
    line-height: 60px;
    text-align: center;
    position: absolute;
    top: 50%;
    z-index: 10;
    overflow: hidden;
    cursor: pointer;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease;
}

.flex-direction-nav .flex-prev {
    left: 0px;
    border-radius: 0 2px 2px 0;
}

.flex-direction-nav .flex-next {
    right: 0px;
    border-radius: 2px 0 0 2px;
}

.flexslider:hover .flex-prev, .flexslider:hover .flex-next {
    opacity: 0.7;
}

.flexslider:hover .flex-next:hover, .flexslider:hover .flex-prev:hover {
    opacity: 1;
    color: #fff;
    background: #333333;
}

.flex-direction-nav .flex-disabled {
    opacity: 0 !important;
    filter: alpha(opacity=0);
    cursor: default;
}

.flex-direction-nav a:before {
    font-family: "FontAwesome";
    font-size: 40px;
    display: inline-block;
    content: '\f104';
}

.flex-direction-nav a.flex-next:before {
    content: '\f105';
}

/* Control Nav */
.flex-control-nav {
    width: 100%;
    position: absolute;
    bottom: 10px;
    text-align: center;
}

.flex-control-nav li {
    margin: 0 4px;
    display: inline-block;
    zoom: 1;
    *display: inline;
}

.flex-control-paging li a {
    text-indent: -9999px;
    display: inline-block;
    background: transparent;
    border: 2px solid #fff;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    opacity: .5;
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -ms-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    margin: 0;
    cursor: pointer
}

.flex-control-paging li a:hover {
    opacity: 1;
}

.flex-control-paging li a.flex-active {
    background: #ffffff;
    opacity: 1;
}

.flexslider .slides {
    cursor: grab !important;
    cursor: -moz-grab !important;
    cursor: -webkit-grab !important;
}

/* Flexslider Custom Additions */
.blog-medium .flex-direction-nav .flex-prev,
.blog-medium .flex-direction-nav .flex-next {
    width: 48px;
    height: 48px;
    line-height: 48px;
    margin-top: -24px;
}

.blog-medium .flex-direction-nav .flex-prev {
    left: 0px;
}

.blog-medium .flex-direction-nav .flex-next {
    right: 0px;
}

.blog-medium .flex-direction-nav a:before {
    font-size: 34px;
}

/*.blog-medium .flex-control-nav{
    display: none;
}*/

.blog-masonry .flex-direction-nav .flex-prev,
.blog-masonry .flex-direction-nav .flex-next {
    width: 48px;
    height: 48px;
    line-height: 48px;
    margin-top: -24px;
}

.blog-masonry .flex-direction-nav .flex-prev {
    left: 0px;
}

.blog-masonry .flex-direction-nav .flex-next {
    right: 0px;
}

.blog-masonry .flex-direction-nav a:before {
    font-size: 34px;
}

/* ------------------------------------------------------------------------ */
/* Plugin - Owl Carousel
/* ------------------------------------------------------------------------ */
.owl-carousel .animated {
    -webkit-animation-duration: 1000ms;
    animation-duration: 1000ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.owl-carousel .owl-animated-in {
    z-index: 0
}

.owl-carousel .owl-animated-out {
    z-index: 1
}

.owl-carousel .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

.owl-height {
    -webkit-transition: height 400ms ease;
    -moz-transition: height 400ms ease;
    -ms-transition: height 400ms ease;
    -o-transition: height 400ms ease;
    transition: height 400ms ease
}

.owl-carousel {
    display: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    z-index: 1
}

.owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y
}

.owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0
}

.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0px, 0px, 0px)
}

.owl-carousel .owl-controls .owl-nav .owl-prev, .owl-carousel .owl-controls .owl-nav .owl-next, .owl-carousel .owl-controls .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.owl-carousel.owl-loaded {
    display: block
}

.owl-carousel.owl-loading {
    opacity: 0;
    display: block
}

.owl-carousel.owl-hidden {
    opacity: 0
}

.owl-carousel .owl-refresh .owl-item {
    display: none
}

.owl-carousel .owl-item {
    z-index: 1;
    transform: translate3d(0, 0, 0);
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.owl-carousel .owl-item img {
    display: block;
    -webkit-transform-style: preserve-3d
}

.owl-carousel.owl-text-select-on .owl-item {
    -webkit-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto
}

.owl-carousel .owl-grab {
    cursor: move;
    cursor: -webkit-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab
}

.owl-carousel.owl-rtl {
    direction: rtl
}

.owl-carousel.owl-rtl .owl-item {
    float: right
}

.no-js .owl-carousel {
    display: block
}

.owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    -webkit-transition: opacity 400ms ease;
    -moz-transition: opacity 400ms ease;
    -ms-transition: opacity 400ms ease;
    -o-transition: opacity 400ms ease;
    transition: opacity 400ms ease
}

.owl-carousel .owl-item img {
    transform-style: preserve-3d
}

.owl-carousel .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #000
}

.owl-carousel .owl-video-play-icon {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
    /*background: url(owl.video.play.png) no-repeat;*/
    cursor: pointer;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    -webkit-transition: scale 100ms ease;
    -moz-transition: scale 100ms ease;
    -ms-transition: scale 100ms ease;
    -o-transition: scale 100ms ease;
    transition: scale 100ms ease
}

.owl-carousel .owl-video-play-icon:hover {
    -webkit-transition: scale(1.3, 1.3);
    -moz-transition: scale(1.3, 1.3);
    -ms-transition: scale(1.3, 1.3);
    -o-transition: scale(1.3, 1.3);
    transition: scale(1.3, 1.3)
}

.owl-carousel .owl-video-playing .owl-video-tn, .owl-carousel .owl-video-playing .owl-video-play-icon {
    display: none
}

.owl-carousel .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    -webkit-transition: opacity 400ms ease;
    -moz-transition: opacity 400ms ease;
    -ms-transition: opacity 400ms ease;
    -o-transition: opacity 400ms ease;
    transition: opacity 400ms ease
}

.owl-carousel .owl-video-frame {
    position: relative;
    z-index: 1
}

/* Owl Theme Design */
.owl-theme .owl-controls {
    margin-top: 10px;
    text-align: center;
    -webkit-tap-highlight-color: transparent
}

.owl-theme .owl-controls .owl-nav [class*=owl-] {
    position: absolute;
    top: 50%;
    color: #fff;
    cursor: pointer;
    margin-top: -60px;
    opacity: 0.5;
    -webkit-transition: opacity 400ms ease;
    -moz-transition: opacity 400ms ease;
    -ms-transition: opacity 400ms ease;
    -o-transition: opacity 400ms ease;
    transition: opacity 400ms ease
}

.owl-theme .owl-controls .owl-nav .owl-prev:after {
    font-family: "FontAwesome";
    font-size: 40px;
    height: 40px;
    line-height: 40px;
    display: block;
    content: '\f104';
    color: rgba(0, 0, 0, 0.6);
}

.owl-theme .owl-controls .owl-nav .owl-prev {
    left: -50px;
}

.owl-theme .owl-controls .owl-nav .owl-next:after {
    font-family: "FontAwesome";
    font-size: 40px;
    height: 40px;
    line-height: 40px;
    display: block;
    content: '\f105';
    color: rgba(0, 0, 0, 0.6);
}

.owl-theme .owl-controls .owl-nav .owl-next {
    right: -50px;
}

.owl-theme .owl-controls .owl-nav [class*=owl-]:hover {
    opacity: 1;
}

.owl-theme .owl-controls .owl-nav .disabled {
    opacity: .5;
    cursor: default
}

.owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
    *display: inline
}

.owl-theme .owl-dots .owl-dot span {
    width: 6px;
    height: 6px;
    margin: 0 4px;
    opacity: 0.5;
    border: 2px solid #999999;
    background: transparent;
    display: block;
    -webkit-backface-visibility: visible;
    -webkit-transition: opacity 200ms ease;
    -moz-transition: opacity 200ms ease;
    -ms-transition: opacity 200ms ease;
    -o-transition: opacity 200ms ease;
    transition: opacity 200ms ease;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px
}

.owl-theme .owl-dots .owl-dot.active span {
    background: #999999;
    opacity: 1;
}

.owl-theme .owl-dots .owl-dot:hover span {
    opacity: 1;
}

.boxed-layout .owl-nav {
    display: none;
}

/* ------------------------------------------------------------------------ */
/* bbPress Style
/* ------------------------------------------------------------------------ */
#bbpress-forums hr {
    margin: 0 0 24px
}

#bbpress-forums {
    background: transparent;
    clear: both;
    margin-bottom: 0;
    overflow: hidden
}

body.reply-edit .reply {
    float: none
}

#bbpress-forums div.reply {
    height: auto;
    width: 100%
}

#bbpress-forums div.bbp-forum-header, #bbpress-forums div.bbp-topic-header, #bbpress-forums div.bbp-reply-header {
    background-color: #ffffff;
    border-bottom: 1px dashed #efefef;
    padding: 16px 20px
}

#bbpress-forums .status-trash.even, #bbpress-forums .status-spam.even {
    background-color: #fee
}

#bbpress-forums .status-trash.odd, #bbpress-forums .status-spam.odd {
    background-color: #fee
}

#bbpress-forums .status-closed, #bbpress-forums .status-closed a {
    color: #ccc
}

#bbpress-forums ul {
    background: none;
    list-style: none;
    margin: 0;
    padding: 0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

#bbpress-forums ul.bbp-threaded-replies {
    margin-left: 50px
}

#bbpress-forums li {
    background: none;
    margin: 0;
    list-style: none;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

#bbpress-forums ul.bbp-lead-topic, #bbpress-forums ul.bbp-topics, #bbpress-forums ul.bbp-forums, #bbpress-forums ul.bbp-replies, #bbpress-forums ul.bbp-search-results {
    overflow: hidden;
    margin-bottom: 20px;
    clear: both
}

#bbpress-forums li.bbp-header, #bbpress-forums li.bbp-body, #bbpress-forums li.bbp-footer {
    clear: both
}

#bbpress-forums li.bbp-header, #bbpress-forums li.bbp-footer {
    background: #ffffff;
    font-weight: bold;
    text-transform: uppercase;
    padding: 15px 20px;
    color: #666666;
    text-align: center
}

#bbpress-forums li.bbp-header {
    background: #666666;
    color: #ffffff;
    border-radius: 2px
}

#bbpress-forums li.bbp-header a {
    color: #ffffff;
    text-transform: none;
    display: inline-block;
    background: #333333 !important;
    border-radius: 3px;
    padding: 8px 12px !important;
    text-transform: uppercase;
    font-weight: 700 !important;
    font-size: 12px;
    letter-spacing: 1px
}

#bbpress-forums li.bbp-header ul {
    overflow: hidden
}

#bbpress-forums .bbp-forums-list {
    margin: 0;
    padding-left: 20px;
    border-left: 1px solid #efefef
}

#bbpress-forums .bbp-forums-list li {
    display: inline
}

#bbpress-forums li.bbp-footer {
    display: none
}

#bbpress-forums li.bbp-footer p {
    margin: 0
}

li.bbp-forum-info, li.bbp-topic-title {
    float: left;
    text-align: left;
    width: 55%
}

li.bbp-forum-info a {
    font-weight: 600
}

li.bbp-forum-topic-count, li.bbp-topic-voice-count, li.bbp-forum-reply-count, li.bbp-topic-reply-count {
    float: left;
    text-align: center;
    width: 10%
}

li.bbp-forum-freshness, li.bbp-topic-freshness {
    text-align: center;
    float: left;
    width: 22%
}

#bbpress-forums li.bbp-body ul.forum, #bbpress-forums li.bbp-body ul.topic {
    border-bottom: 1px solid #efefef;
    overflow: hidden;
    padding: 20px
}

li.bbp-header div.bbp-topic-content span#subscription-toggle, li.bbp-header div.bbp-topic-content span#favorite-toggle, li.bbp-header div.bbp-reply-content span#subscription-toggle, li.bbp-header div.bbp-reply-content span#favorite-toggle {
    float: right
}

#bbpress-forums div.bbp-forum-title h3, #bbpress-forums div.bbp-topic-title h3, #bbpress-forums div.bbp-reply-title h3 {
    background: none;
    border: none;
    margin: 20px 0;
    padding: 0;
    text-transform: none
}

#bbpress-forums div.bbp-forum-author, #bbpress-forums div.bbp-topic-author, #bbpress-forums div.bbp-reply-author {
    float: left;
    text-align: center;
    width: 115px
}

#bbpress-forums div.bbp-forum-author img.avatar, #bbpress-forums div.bbp-topic-author img.avatar, #bbpress-forums div.bbp-reply-author img.avatar {
    border: none;
    max-width: 66px;
    padding: 0;
    margin: 25px auto 0;
    float: none;
    border-radius: 50%
}

#bbpress-forums div.bbp-forum-author a.bbp-author-name, #bbpress-forums div.bbp-topic-author a.bbp-author-name, #bbpress-forums div.bbp-reply-author a.bbp-author-name {
    margin: 0 auto -18px;
    word-break: break-word;
    display: inline-block
}

#bbpress-forums div.bbp-topic-author a.bbp-author-name, #bbpress-forums div.bbp-reply-author a.bbp-author-name {
    clear: left;
    display: block
}

#bbpress-forums div.bbp-forum-author .bbp-author-role, #bbpress-forums div.bbp-topic-author .bbp-author-role, #bbpress-forums div.bbp-reply-author .bbp-author-role {
    background: #999999;
    color: #ffffff;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
    padding: 1px 10px;
    border-radius: 40px;
    margin-bottom: 30px
}

#bbpress-forums li.bbp-header .bbp-search-author, #bbpress-forums li.bbp-footer .bbp-search-author, #bbpress-forums li.bbp-header .bbp-forum-author, #bbpress-forums li.bbp-footer .bbp-forum-author, #bbpress-forums li.bbp-header .bbp-topic-author, #bbpress-forums li.bbp-footer .bbp-topic-author, #bbpress-forums li.bbp-header .bbp-reply-author, #bbpress-forums li.bbp-footer .bbp-reply-author {
    float: left;
    margin: 0;
    padding: 0;
    width: 120px
}

#bbpress-forums li.bbp-header .bbp-search-content, #bbpress-forums li.bbp-footer .bbp-search-content, #bbpress-forums li.bbp-header .bbp-forum-content, #bbpress-forums li.bbp-footer .bbp-forum-content, #bbpress-forums li.bbp-header .bbp-topic-content, #bbpress-forums li.bbp-footer .bbp-topic-content, #bbpress-forums li.bbp-header .bbp-reply-content, #bbpress-forums li.bbp-footer .bbp-reply-content {
    margin-left: 140px;
    padding: 0;
    text-align: left
}

div.bbp-forum-header, div.bbp-topic-header, div.bbp-reply-header, li.bbp-body div.hentry {
    margin-bottom: 0;
    overflow: hidden;
    padding: 20px 0 0 20px;
    border-bottom: 1px solid #efefef
}

div.bbp-forum-header, div.bbp-topic-header, div.bbp-reply-header {
    clear: both
}

span.bbp-author-ip {
    display: none
}

#bbpress-forums .bbp-topics .bbp-body ul .bbp-topic-title {
    position: relative;
    padding-left: 40px
}

#bbpress-forums .bbp-topics .bbp-body ul .bbp-topic-title:before {
    position: absolute;
    top: 50%;
    left: 0;
    margin: -11px 0 0;
    line-height: 1;
    content: "\f0e6";
    font-size: 21px;
    color: #999999;
    font-family: 'FontAwesome'
}

#bbpress-forums .bbp-topics .bbp-body .bbp-topic-title .bbp-topic-permalink {
    font-weight: 600
}

/* Topic and reply content /--------------------------------------------------------- */
#bbpress-forums div.bbp-forum-content, #bbpress-forums div.bbp-topic-content, #bbpress-forums div.bbp-reply-content {
    margin-left: 130px;
    padding: 20px 40px 15px 20px;
    text-align: left
}

#bbpress-forums div.bbp-forum-content:after, #bbpress-forums div.bbp-topic-content:after, #bbpress-forums div.bbp-reply-content:after {
    clear: both;
    content: ".";
    display: block;
    float: none;
    height: 0;
    font-size: 0;
    visibility: hidden
}

#bbpress-forums div.bbp-topic-content a, #bbpress-forums div.bbp-reply-content a {
    background: none;
    border: none;
    display: inline;
    font-weight: normal;
    margin: 0;
    padding: 0
}

#bbpress-forums div.bbp-topic-content h1, #bbpress-forums div.bbp-reply-content h1, #bbpress-forums div.bbp-topic-content h2, #bbpress-forums div.bbp-reply-content h2, #bbpress-forums div.bbp-topic-content h3, #bbpress-forums div.bbp-reply-content h3, #bbpress-forums div.bbp-topic-content h4, #bbpress-forums div.bbp-reply-content h4, #bbpress-forums div.bbp-topic-content h5, #bbpress-forums div.bbp-reply-content h5, #bbpress-forums div.bbp-topic-content h6, #bbpress-forums div.bbp-reply-content h6 {
    clear: none
}

#bbpress-forums div.bbp-topic-content img, #bbpress-forums div.bbp-reply-content img {
    max-width: 100%
}

#bbpress-forums div.bbp-topic-content ul, #bbpress-forums div.bbp-reply-content ul, #bbpress-forums div.bbp-topic-content ol, #bbpress-forums div.bbp-reply-content ol {
    margin: 0 15px 15px;
    padding: 0
}

#bbpress-forums div.bbp-topic-content ul li, #bbpress-forums div.bbp-reply-content ul li {
    list-style-type: disc
}

#bbpress-forums div.bbp-topic-content ol li, #bbpress-forums div.bbp-reply-content ol li {
    list-style-type: decimal
}

#bbpress-forums div.bbp-topic-content ol li li, #bbpress-forums div.bbp-reply-content ol li li {
    list-style-type: lower-alpha
}

#bbpress-forums div.bbp-topic-content ol li li li, #bbpress-forums div.bbp-reply-content ol li li li {
    list-style-type: upper-roman
}

#bbpress-forums div.bbp-topic-content code, #bbpress-forums div.bbp-reply-content code, #bbpress-forums div.bbp-topic-content pre, #bbpress-forums div.bbp-reply-content pre {
    font-family: Inconsolata, Consolas, Monaco, Lucida Console, monospace;
    display: inline-block;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 0 5px;
    max-width: 95%;
    vertical-align: middle;
    margin-top: -3px
}

#bbpress-forums div.bbp-topic-content pre, #bbpress-forums div.bbp-reply-content pre {
    display: block;
    line-height: 18px;
    margin: 0 0 24px;
    padding: 5px 10px;
    white-space: pre;
    overflow: auto
}

#bbpress-forums div.bbp-topic-content pre code, #bbpress-forums div.bbp-reply-content pre code {
    display: block;
    border: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
    overflow-wrap: normal;
    overflow: auto;
    max-width: 100%
}

/* Reply to /--------------------------------------------------------- */
#bbpress-forums div.bbp-reply-to {
    margin-left: 130px;
    padding: 12px 12px 12px 0;
    text-align: right
}

#bbpress-forums div#bbp-cancel-reply-to {
    text-align: right
}

/* Breadcrumb and Tags /--------------------------------------------------------- */
div.bbp-breadcrumb {
    display: none
}

#bbpress-forums div.bbp-breadcrumb p, #bbpress-forums div.bbp-topic-tags p {
    margin: 0
}

#bbpress-forums div.bbp-topic-tags {
    border: 1px solid #efefef;
    float: right;
    margin: 0 0 40px;
    padding: 4px 10px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px
}

#bbp-topic-hot-tags {
    clear: both
}

/* Search /--------------------------------------------------------- */
#bbpress-forums .bbp-search-form:after {
    content: "\0020";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden
}

#bbpress-forums .bbp-search-form {
    clear: both;
    margin: 0 0 20px !important
}

#bbpress-forums #bbp-search-form .hidden {
    display: none
}

#bbpress-forums #bbp-search-form #bbp_search {
    display: block;
    width: 84% !important;
    margin-right: 2%;
    float: left;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

#bbpress-forums #bbp-search-form #bbp_search_submit {
    display: block;
    width: 14% !important;
    margin: 0;
    float: left;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

/* Admin Links /--------------------------------------------------------- */
span.bbp-admin-links {
    float: right;
    color: #efefef
}

span.bbp-admin-links a {
    font-weight: normal;
    color: #999999;
    text-decoration: none
}

span.bbp-admin-links a:hover {
    color: #666666
}

fieldset span.bbp-admin-links {
    float: left
}

td.bbp-topic-admin-links, td.bbp-topic-counts {
    width: 50%
}

.bbp-forum-header a.bbp-forum-permalink, .bbp-topic-header a.bbp-topic-permalink, .bbp-reply-header a.bbp-reply-permalink {
    float: right;
    margin-left: 10px;
    color: #ccc;
    font-weight: 700
}

/* Toggles /--------------------------------------------------------- */
#subscription-toggle {
    color: #666666;
    float: right
}

#subscription-toggle a {
    text-transform: uppercase;
    font-weight: 700;
    width: auto;
    padding: 8px 16px;
    letter-spacing: 1px;
    font-size: 12px;
    margin: 0 0 40px;
    display: inline-block;
    border-radius: 2px;
    background: #333333;
    color: #ffffff
}

#subscription-toggle a:hover {
    color: #ffffff;
    background-color: #44bdbd
}

#subscription-toggle span.is-subscribed a {
    color: #ffffff;
    background-color: #d25c5a
}

#subscription-toggle span.is-subscribed a:hover {
    color: #d25c5a;
    background-color: #fceeee
}

#bbpress-forums .bbp-forum-info .bbp-forum-content, #bbpress-forums p.bbp-topic-meta {
    margin: 0;
    padding: 0;
    word-wrap: break-word
}

#bbpress-forums p.bbp-topic-meta span {
    white-space: nowrap;
    color: #999999
}

#bbpress-forums p.bbp-topic-meta span a {
    color: #999999
}

/* Pagination /--------------------------------------------------------- */
.bbp-pagination-count {
    display: none;
}

/*.bbp-pagination-count{float:left;border:1px solid transparent}
.bbp-pagination-links{float:right;list-style:none;display:inline}
.bbp-pagination-links a,.bbp-pagination-links span.current{display:block;float:left;padding:0 5px;margin-left:5px;border:1px solid #efefef;text-decoration:none}
.bbp-pagination-links a:hover,.bbp-pagination-links span.current{background:#eee;opacity:.8;border:1px solid #ddd}
.bbp-pagination-links span.dots{display:block;float:left;padding:1px 4px;margin-left:5px}
.bbp-pagination{float:left;width:100%;margin-bottom:15px}
.bbp-topic-pagination{display:inline-block;margin-left:5px;margin-bottom:2px}
.bbp-topic-pagination a{font-size:10px;line-height:10px;padding:1px 3px;border:1px solid #ddd;text-decoration:none}*/

/* Forms /--------------------------------------------------------- */
.bbp-no-reply, .bbp-no-topic {
    margin-top: 40px
}

.bbp-reply-form:before, .bbp-topic-form:before {
    content: '\0020';
    display: block;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0
}

.bbp-reply-form, .bbp-topic-form {
    padding-top: 40px;
    padding-bottom: 20px
}

.topic-edit .bbp-reply-form, .topic-edit .bbp-topic-form {
    padding-top: 00px;
    padding-bottom: 20px
}

.reply-edit .bbp-reply-form {
    padding-top: 0
}

#bbpress-forums fieldset.bbp-form {
    clear: both
}

#bbpress-forums form > fieldset.bbp-form > legend {
    color: #444444;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 0 20px;
    display: block;
    background: #666666;
    color: #ffffff;
    width: 100%;
    padding: 15px 20px;
    border-radius: 2px
}

#bbpress-forums fieldset.bbp-form label {
    margin: 0 0 10px;
    font-weight: 700;
    display: inline-block
}

#bbpress-forums fieldset.bbp-form input[type="text"] {
    width: 100%
}

#bbp-edit-topic-tag.bbp-form fieldset.bbp-form label, #bbp-login fieldset label, #bbp-register fieldset label, #bbp-lost-pass fieldset label {
    width: 100px
}

#bbpress-forums fieldset.bbp-form p, #bbpress-forums fieldset.bbp-form textarea, #bbpress-forums fieldset.bbp-form select, #bbpress-forums fieldset.bbp-form input {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

textarea#bbp_reply_content, textarea#bbp_topic_content, textarea#bbp_forum_content {
    width: 97%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

textarea#bbp_forum_content {
    height: 210px
}

#bbpress-forums fieldset.bbp-forum-form-attributes {
    width: 200px;
    float: right;
    clear: none;
    margin-left: 25px
}

/* Fix for too-long forum names, usually from conversion /--------------------------------------------------------- */
#bbpress-forums fieldset select#bbp_forum_id {
    max-width: 200px
}

.bbp-topic-form, .bbp-reply-form, .bbp-topic-tag-form {
    clear: left
}

body.topic-edit .bbp-topic-form div.avatar img, body.reply-edit .bbp-reply-form div.avatar img, body.single-forum .bbp-topic-form div.avatar img, body.single-reply .bbp-reply-form div.avatar img {
    margin-right: 0;
    padding: 10px;
    border: 1px solid #ddd;
    line-height: 0;
    background-color: #efefef
}

body.page .bbp-reply-form code, body.page .bbp-topic-form code, body.single-topic .bbp-reply-form code, body.single-forum .bbp-topic-form code, body.topic-edit .bbp-topic-form code, body.reply-edit .bbp-reply-form code {
    font-size: 10px;
    background-color: #f0fff8;
    border: 1px solid #CEEFE1;
    display: block;
    padding: 8px;
    margin-top: 5px;
    width: 369px
}

#merge_tag, #delete_tag {
    display: inline
}

div.bbp-submit-wrapper {
    margin-top: 0;
    clear: both
}

p.form-allowed-tags {
    max-width: 100%
}

/* TinyMCE in Forum /--------------------------------------------------------- */
#bbpress-forums div.bbp-the-content-wrapper {
    margin-bottom: 20px;
    border-radius: 2px
}

#bbpress-forums div.bbp-the-content-wrapper textarea.bbp-the-content {
    width: 100%;
    margin: 0
}

.wp-editor-wrap.html-active {
    border: 1px solid #dddddd;
    border-radius: 2px
}

#bbpress-forums div.bbp-the-content-wrapper table, #bbpress-forums div.bbp-the-content-wrapper tbody, #bbpress-forums div.bbp-the-content-wrapper tr, #bbpress-forums div.bbp-the-content-wrapper td {
    border: none;
    padding: 0;
    margin: 0;
    width: auto
}

#bbpress-forums div.bbp-the-content-wrapper input {
    padding: 5px;
    margin: 0 2px 0 0;
    line-height: 1em
}

#bbpress-forums div.bbp-the-content-wrapper div.quicktags-toolbar {
    padding: 10px;
    min-height: 26px;
    background: #ffffff;
    border-bottom: 1px dashed #efefef
}

#bbpress-forums div.bbp-the-content-wrapper td.mceToolbar {
    padding: 4px 4px 8px
}

#bbpress-forums div.wp-editor-container {
    margin: 0;
    padding: 0;
    line-height: 0
}

#bbpress-forums div.bbp-the-content-wrapper td.mceStatusbar {
    line-height: 16px
}

/* Edit User /--------------------------------------------------------- */
#bbpress-forums #bbp-your-profile h2 {
    display: none
}

#bbpress-forums #bbp-your-profile fieldset {
    margin: 0 0 40px
}

#bbpress-forums #bbp-your-profile fieldset div {
    width: 100%
}

#bbpress-forums #bbp-your-profile fieldset select {
    margin-bottom: 0
}

#bbpress-forums #bbp-your-profile fieldset input, #bbpress-forums #bbp-your-profile fieldset textarea {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%
}

#bbpress-forums #bbp-your-profile fieldset legend {
    display: block
}

#bbpress-forums #bbp-your-profile fieldset label[for] {
    padding: 0;
    margin: 0 0 5px;
    cursor: pointer
}

#bbpress-forums #bbp-your-profile fieldset fieldset {
    margin: 0;
    border: none;
    padding: 0;
    clear: none;
    float: none
}

#bbpress-forums #bbp-your-profile fieldset fieldset.password input, #bbpress-forums #bbp-your-profile fieldset fieldset.password span {
    width: 100%
}

#bbpress-forums #bbp-your-profile fieldset fieldset.capabilities dl {
    margin: 0
}

#bbpress-forums #bbp-your-profile fieldset fieldset.password span.description {
    margin-left: 0;
    display: none
}

#bbpress-forums #bbp-your-profile fieldset.submit {
    margin-top: -40px
}

#bbpress-forums #bbp-your-profile fieldset.submit legend {
    display: none
}

/* Notices /--------------------------------------------------------- */
div.bbp-template-notice, div.indicator-hint {
    padding: 15px 20px;
    margin: 0 0 20px;
    border-radius: 3px;
    background-color: #f9f9f9;
    clear: both
}

div.bbp-template-notice.info {
    display: none
}

div.bbp-template-notice.important {
    border: #e6db55 1px solid;
    background-color: #fffbcc
}

div.bbp-template-notice.error, div.bbp-template-notice.warning {
    background-color: #fceeee;
    color: #d25c5a
}

div.bbp-template-notice p {
    margin: 0 !important
}

/* Sticky /--------------------------------------------------------- */
.bbp-topics-front ul.super-sticky .bbp-topic-title:before, .bbp-topics ul.super-sticky .bbp-topic-title:before, .bbp-topics ul.sticky .bbp-topic-title:before, .bbp-forum-content ul.sticky .bbp-topic-title:before {
    content: "\f0a1" !important;
    color: #44bdbd !important
}

#bbpress-forums .bbp-topics .bbp-body .bbp-topic-title .bbp-topic-permalink {
    font-weight: 600
}

/* Revisions /--------------------------------------------------------- */
#bbpress-forums .bbp-topic-content ul.bbp-topic-revision-log, #bbpress-forums .bbp-reply-content ul.bbp-topic-revision-log, #bbpress-forums .bbp-reply-content ul.bbp-reply-revision-log {
    width: 100%;
    margin: 0 0 27px;
    padding: 12px 0 0;
    border-top: 1px dashed #efefef;
    border-radius: 2px;
    color: #999999;
    font-size: 11px
}

#bbpress-forums .bbp-topic-content ul.bbp-topic-revision-log li, #bbpress-forums .bbp-reply-content ul.bbp-topic-revision-log li, #bbpress-forums .bbp-reply-content ul.bbp-reply-revision-log li {
    list-style-type: none
}

/* Widgets /--------------------------------------------------------- */
.bbp-login-form fieldset legend {
    display: none
}

.bbp-login-form .bbp-username input, .bbp-login-form .bbp-email input, .bbp-login-form .bbp-password input {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%
}

.bbp-login-form label {
    margin: 0 0 5px
}

.bbp-login-form .bbp-remember-me:after {
    content: "\0020";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden
}

.bbp-login-form .bbp-remember-me input {
    display: inline-block !important;
    float: left;
    margin-top: 7px;
    margin-right: 7px
}

.bbp-login-form .bbp-remember-me label {
    float: left
}

.bbp-login-form .bbp-submit-wrapper {
    margin-top: 10px
}

.bbp-login-form .bbp-login-links a {
    float: left;
    clear: left
}

.bbp-logged-in img.avatar {
    margin: 0;
    border-radius: 50%
}

.widget_display_stats dt, .widget_display_stats dd {
    display: inline-block
}

/* Avatars /--------------------------------------------------------- */
#bbpress-forums p.bbp-topic-meta img.avatar, #bbpress-forums ul.bbp-reply-revision-log img.avatar, #bbpress-forums ul.bbp-topic-revision-log img.avatar, #bbpress-forums div.bbp-template-notice img.avatar, #bbpress-forums .widget_display_topics img.avatar, #bbpress-forums .widget_display_replies img.avatar {
    float: none;
    margin-bottom: -7px;
    display: none
}

fieldset div.avatar {
    float: right
}

/* User Pages /--------------------------------------------------------- */
#bbpress-forums h1 {
    clear: none
}

#bbpress-forums #bbp-user-wrapper {
    float: left;
    width: 100%
}

#bbpress-forums .bbp-user-section {
    overflow: auto
}

#bbpress-forums .bbp-user-section .bbp-user-forum-role, #bbpress-forums .bbp-user-section .bbp-user-topic-count, #bbpress-forums .bbp-user-section .bbp-user-reply-count {
    margin: 0;
    font-weight: 700;
    text-transform: uppercase;
    color: #999999;
    font-size: 12px;
    display: none
}

#bbpress-forums #bbp-user-wrapper h2.entry-title {
    clear: none
}

#bbpress-forums #bbp-user-wrapper ul.bbp-lead-topic, #bbpress-forums #bbp-user-wrapper ul.bbp-topics, #bbpress-forums #bbp-user-wrapper ul.bbp-forums, #bbpress-forums #bbp-user-wrapper ul.bbp-replies, #bbpress-forums #bbp-user-wrapper fieldset.bbp-form {
    clear: none
}

#bbpress-forums #bbp-single-user-details {
    margin: 0 10px 0 0;
    width: 140px;
    float: left;
    overflow: hidden
}

#bbpress-forums #bbp-single-user-details #bbp-user-avatar {
    margin: 0;
    width: 140px
}

#bbpress-forums #bbp-single-user-details #bbp-user-avatar img.avatar {
    border: none;
    height: 140px;
    padding: 0;
    margin: 0 0 20px;
    width: 140px;
    border-radius: 50%
}

#bbpress-forums #bbp-single-user-details #bbp-user-description {
    float: none;
    margin-left: 180px
}

#bbpress-forums #bbp-single-user-details #bbp-user-navigation {
    float: none;
    margin: 0 0 20px
}

#bbpress-forums #bbp-single-user-details #bbp-user-navigation li {
    margin: 0;
    border-bottom: 1px solid #efefef
}

#bbpress-forums #bbp-single-user-details #bbp-user-navigation li:last-child {
    border: none
}

#bbpress-forums #bbp-single-user-details #bbp-user-navigation a {
    padding: 8px 0;
    display: block;
    text-decoration: none;
    border-radius: 2px
}

#bbpress-forums #bbp-single-user-details #bbp-user-navigation li.current a {
    font-weight: 600
}

#bbpress-forums #bbp-user-body {
    margin-left: 180px
}

body.my-account #bbpress-forums {
    border-top: none;
    padding-top: 0;
    margin-bottom: 0
}

/* User Capabilities /--------------------------------------------------------- */
#bbpress-forums dl.bbp-user-capabilities {
    display: inline-block;
    vertical-align: top
}

#bbpress-forums dl.bbp-user-capabilities dt {
    margin: 0 0 10px;
    text-transform: capitalize
}

#bbpress-forums dl.bbp-user-capabilities dd {
    margin: 0;
    padding: 0
}

/* BuddyPress Activity Streams /--------------------------------------------------------- */
.activity-list li.bbp_topic_create .activity-content .activity-inner, .activity-list li.bbp_reply_create .activity-content .activity-inner {
    border-left: 2px solid #eaeaea;
    margin-left: 5px;
    padding-left: 10px
}

/* BuddyPress Group Forums /--------------------------------------------------------- */
#bbpress-forums div.row-actions {
    font-size: 11px;
    visibility: hidden
}

#bbpress-forums li:hover > div.row-actions {
    visibility: visible
}

/* Search Results /--------------------------------------------------------- */
#bbp-search-results .bbp-topic-header .bbp-meta, #bbp-search-results .bbp-topic-header .bbp-topic-title-meta, #bbp-search-results .bbp-reply-header .bbp-meta {
    display: none
}

/* BBPress Pagination /--------------------------------------------------------- */
.page-numbers {
    margin-bottom: 20px;
}

.bbp-pagination-links {
    list-style: none;
}

.bbp-pagination-links {
    display: inline;
}

.bbp-pagination-links a, .bbp-pagination-links a.next, .bbp-pagination-links a.prev {
    display: block;
    float: left;
    padding: 4px 9px;
    margin-right: 7px;
    background: #f9f9f9;
    color: #666666;
    border-radius: 2px;
}

.bbp-pagination-links span.current {
    display: block;
    float: left;
    padding: 4px 9px;
    margin-right: 7px;
    background: #44bdbd;
    color: #ffffff;
    font-weight: 700;
    border-radius: 2px;
}

.bbp-pagination-links span.dots {
    display: block;
    float: left;
    padding: 4px 4px;
    margin-right: 7px;
}

/* ------------------------------------------------------------------------ */
/* EOF
/* ------------------------------------------------------------------------ */
