body {
    /*font-family: 'Public Sans', sans-serif;*/
    font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

.layout-navbar-fixed .layout-wrapper:not(.layout-horizontal) .layout-page:before {
    content: "";
    width: 100%;
    height: 4.75rem;
    position: fixed;
    top: 0px;
    z-index: 10
}

.bg-menu-theme .menu-header {
    position: relative
}

.bg-menu-theme .menu-header:before {
    content: "";
    position: absolute;
    inset-inline-start: 0;
    top: 50%;
    width: 1rem;
    height: 1px;
    transition: all .3s ease-in-out
}

.layout-wrapper:not(.layout-horizontal) .bg-menu-theme .menu-inner .menu-item .menu-link {
    border-radius: .375rem
}

.layout-horizontal .bg-menu-theme .menu-inner > .menu-item > .menu-link {
    border-radius: .375rem
}

@media (min-width: 1200px) {
    .layout-horizontal .bg-menu-theme .menu-inner > .menu-item {
        margin: .625rem 0
    }

    .layout-horizontal .bg-menu-theme .menu-inner > .menu-item:not(:first-child) {
        margin-left: .1875rem
    }

    .layout-horizontal .bg-menu-theme .menu-inner > .menu-item:not(:last-child) {
        margin-right: .1875rem
    }

    .layout-horizontal .bg-menu-theme .menu-inner > .menu-item .menu-sub {
        box-shadow: 0 .25rem .75rem 0 rgba(34, 48, 62, .14);
        z-index: 1
    }
}

.layout-wrapper:not(.layout-horizontal) .bg-menu-theme .menu-inner > .menu-item.active:before {
    content: "";
    position: absolute;
    right: 0;
    width: .25rem;
    height: 2.6845rem;
    border-radius: .375rem 0 0 .375rem
}

[dir=rtl] .layout-wrapper:not(.layout-horizontal) .bg-menu-theme .menu-inner > .menu-item.active:before {
    left: 0;
    right: inherit;
    border-radius: 0 .375rem .375rem 0
}

.bg-menu-theme .menu-sub > .menu-item > .menu-link:before {
    content: "";
    position: absolute;
    left: 1.4375rem;
    width: .375rem;
    height: .375rem;
    border-radius: 50%
}

[dir=rtl] .bg-menu-theme .menu-sub > .menu-item > .menu-link:before {
    right: 1.4375rem;
    left: inherit
}

.layout-horizontal .bg-menu-theme .menu-sub > .menu-item > .menu-link:before {
    inset-inline-start: 1rem
}

.bg-menu-theme .menu-horizontal-wrapper > .menu-inner > .menu-item > .menu-sub > .menu-item > .menu-link:before {
    display: none
}

.bg-menu-theme .menu-sub > .menu-item.active > .menu-link:not(.menu-toggle):before {
    left: 1.1875rem;
    width: .875rem;
    height: .875rem
}

[dir=rtl] .bg-menu-theme .menu-sub > .menu-item.active > .menu-link:not(.menu-toggle):before {
    right: 1.1875rem;
    left: inherit
}

.layout-horizontal .bg-menu-theme .menu-sub > .menu-item.active > .menu-link:not(.menu-toggle):before {
    inset-inline-start: .75rem
}

.layout-menu-hover .layout-menu {
    box-shadow: 0 .25rem .75rem 0 rgba(34, 48, 62, .14);
    transition: all .3s ease-in-out
}

.app-brand .layout-menu-toggle {
    position: absolute;
    inset-inline-start: 15.2rem;
    border-radius: 50%;
    opacity: 1;
    z-index: 3
}

.app-brand .layout-menu-toggle i {
    width: 1.375rem;
    height: 1.375rem;
    transition: all .3s ease-in-out;
    line-height: 1
}

[dir=rtl] .app-brand .layout-menu-toggle i {
    transform: scaleX(-1)
}

@media (max-width: 1199.98px) {
    .app-brand .layout-menu-toggle {
        display: none
    }

    .layout-menu-expanded .app-brand .layout-menu-toggle {
        display: block
    }
}

.menu-vertical .menu-item {
    position: relative
}

.text-primary {
    color: #696cff !important
}

.text-body[href]:hover, .text-heading[href]:hover {
    color: #5f61e6 !important
}

.bg-primary {
    background-color: #696cff !important
}

a.bg-primary:hover, a.bg-primary:focus {
    background-color: #6467f2 !important
}

.dropdown-notifications-item:not(.mark-as-read) .dropdown-notifications-read span {
    background-color: #696cff
}

.bg-label-primary {
    background-color: #e7e7ff !important;
    color: #696cff !important
}

.border-label-primary {
    border: 3px solid #c3c4ff
}

.border-light-primary {
    border: 3px solid rgba(105, 108, 255, .08)
}

.bg-label-hover-primary {
    background-color: #e7e7ff !important;
    color: #696cff !important
}

.bg-label-hover-primary:hover {
    background-color: #696cff !important;
    color: #fff !important
}

.bg-gradient-primary {
    background-image: linear-gradient(45deg, #696cff, #b4b6ff) !important
}

.page-item .page-link:hover, .page-item .page-link:focus, .pagination li > a:not(.page-link):hover, .pagination li > a:not(.page-link):focus {
    background-color: rgba(105, 108, 255, .16);
    color: #696cff
}

.page-item.active .page-link, .pagination li.active > a:not(.page-link) {
    box-shadow: 0 .125rem .25rem 0 rgba(105, 108, 255, .4)
}

.page-item.active .page-link, .page-item.active .page-link:hover, .page-item.active .page-link:focus, .pagination li.active > a:not(.page-link), .pagination li.active > a:not(.page-link):hover, .pagination li.active > a:not(.page-link):focus {
    border-color: #696cff;
    background-color: #696cff;
    color: #fff
}

.pagination-outline-primary .page-item.active .page-link, .pagination-outline-primary .page-item.active .page-link:hover, .pagination-outline-primary .page-item.active .page-link:focus, .pagination-outline-primary.pagination li.active > a:not(.page-link), .pagination-outline-primary.pagination li.active > a:not(.page-link):hover, .pagination-outline-primary.pagination li.active > a:not(.page-link):focus {
    border-color: #696cff !important;
    color: #696cff !important;
    background-color: #e7e7ff !important
}

.progress-bar {
    background-color: #696cff;
    color: #fff;
    box-shadow: 0 2px 4px 0 rgba(105, 108, 255, .4)
}

.modal-onboarding .carousel-indicators [data-bs-target] {
    background-color: #696cff
}

.carousel-control-prev, .carousel-control-next {
    color: #696cff
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
    color: #696cff
}

.list-group-item-primary {
    border-color: #696cff;
    background-color: #e7e7ff;
    color: #696cff !important
}

a.list-group-item-primary, button.list-group-item-primary {
    color: #696cff
}

a.list-group-item-primary:hover, a.list-group-item-primary:focus, button.list-group-item-primary:hover, button.list-group-item-primary:focus {
    border-color: #696cff;
    background-color: #d9d9f0;
    color: #696cff
}

a.list-group-item-primary.active, button.list-group-item-primary.active {
    border-color: #696cff !important;
    background-color: #696cff !important;
    color: #fff !important
}

.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
    background-color: #e7e7ff;
    color: #696cff
}

.list-group.list-group-timeline .list-group-timeline-primary:before {
    border-color: #696cff;
    background-color: #696cff
}

.alert-primary {
    background-color: #e7e7ff;
    border-color: #e7e7ff;
    color: #696cff
}

.alert-primary .btn-close {
    background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='%23696cff' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='0.5' fill='%23696cff' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
}

.alert-primary .alert-link {
    color: #696cff
}

.alert-primary hr {
    color: #696cff !important
}

.alert-primary .alert-icon {
    background-color: #696cff;
    box-shadow: 0 0 0 .125rem rgba(105, 108, 255, .16)
}

.alert-solid-primary {
    background-color: #696cff;
    color: #fff
}

.alert-solid-primary .btn-close {
    background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='0.5' fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
}

.alert-solid-primary .alert-link {
    color: #fff
}

.alert-solid-primary hr {
    color: #fff !important
}

.alert-solid-primary .alert-icon {
    color: #696cff !important;
    box-shadow: 0 0 0 .125rem #8789ff
}

.alert-outline-primary {
    border-color: #696cff;
    color: #696cff
}

.alert-outline-primary .btn-close {
    background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='%23696cff' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='0.5' fill='%23696cff' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
}

.alert-outline-primary .alert-link {
    color: #696cff
}

.alert-outline-primary hr {
    color: #696cff !important
}

.alert-outline-primary .alert-icon {
    background-color: #696cff !important;
    box-shadow: 0 0 0 .125rem rgba(105, 108, 255, .16)
}

.accordion.accordion-header-primary .accordion-item.active .accordion-button {
    color: #696cff !important
}

.tooltip-primary .tooltip-inner, .tooltip-primary > .tooltip .tooltip-inner, .ngb-tooltip-primary + ngb-tooltip-window .tooltip-inner {
    background: #696cff;
    color: #fff;
    box-shadow: 0 .125rem .25rem 0 rgba(105, 108, 255, .4)
}

.tooltip-primary.bs-tooltip-auto[data-popper-placement=top] .tooltip-arrow::before, .tooltip-primary > .tooltip.bs-tooltip-auto[data-popper-placement=top] .tooltip-arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement=top] .tooltip-arrow::before {
    border-top-color: #696cff
}

.tooltip-primary.bs-tooltip-auto[data-popper-placement=left] .tooltip-arrow::before, .tooltip-primary > .tooltip.bs-tooltip-auto[data-popper-placement=left] .tooltip-arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement=left] .tooltip-arrow::before {
    border-left-color: #696cff
}

[dir=rtl] .tooltip-primary.bs-tooltip-auto[data-popper-placement=left] .tooltip-arrow::before, [dir=rtl] .tooltip-primary > .tooltip.bs-tooltip-auto[data-popper-placement=left] .tooltip-arrow::before, [dir=rtl] .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement=left] .tooltip-arrow::before {
    border-right-color: #696cff
}

.tooltip-primary.bs-tooltip-auto[data-popper-placement=bottom] .tooltip-arrow::before, .tooltip-primary > .tooltip.bs-tooltip-auto[data-popper-placement=bottom] .tooltip-arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement=bottom] .tooltip-arrow::before {
    border-bottom-color: #696cff
}

.tooltip-primary.bs-tooltip-auto[data-popper-placement=right] .tooltip-arrow::before, .tooltip-primary > .tooltip.bs-tooltip-auto[data-popper-placement=right] .tooltip-arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement=right] .tooltip-arrow::before {
    border-right-color: #696cff
}

[dir=rtl] .tooltip-primary.bs-tooltip-auto[data-popper-placement=right] .tooltip-arrow::before, [dir=rtl] .tooltip-primary > .tooltip.bs-tooltip-auto[data-popper-placement=right] .tooltip-arrow::before, [dir=rtl] .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-auto[data-popper-placement=right] .tooltip-arrow::before {
    border-left-color: #696cff
}

.popover.popover-primary, .popover .popover-primary > .popover, .popover .ngb-popover-primary + ngb-popover-window {
    border-color: rgba(0, 0, 0, 0);
    background: #696cff;
    box-shadow: 0 .125rem .25rem 0 rgba(105, 108, 255, .4)
}

.popover.popover-primary .popover-header, .popover .popover-primary > .popover .popover-header, .popover .ngb-popover-primary + ngb-popover-window .popover-header {
    border-color: rgba(255, 255, 255, .2);
    background: rgba(0, 0, 0, 0);
    color: #fff
}

.popover.popover-primary .popover-body, .popover .popover-primary > .popover .popover-body, .popover .ngb-popover-primary + ngb-popover-window .popover-body {
    background: rgba(0, 0, 0, 0);
    color: rgba(255, 255, 255, .8)
}

.popover.popover-primary > .popover-arrow::before, .popover .popover-primary > .popover > .popover-arrow::before, .popover .ngb-popover-primary + ngb-popover-window > .popover-arrow::before {
    border-color: rgba(0, 0, 0, 0)
}

.popover.popover-primary.bs-popover-auto[data-popper-placement=top] > .popover-arrow::after, .popover .popover-primary > .popover.bs-popover-auto[data-popper-placement=top] > .popover-arrow::after, .popover .ngb-popover-primary + ngb-popover-window.bs-popover-auto[data-popper-placement=top] > .popover-arrow::after {
    border-top-color: #696cff !important
}

.popover.popover-primary.bs-popover-auto[data-popper-placement=right] > .popover-arrow::after, .popover .popover-primary > .popover.bs-popover-auto[data-popper-placement=right] > .popover-arrow::after, .popover .ngb-popover-primary + ngb-popover-window.bs-popover-auto[data-popper-placement=right] > .popover-arrow::after {
    border-right-color: #696cff !important
}

[dir=rtl] .popover.popover-primary.bs-popover-auto[data-popper-placement=right] > .popover-arrow::after, [dir=rtl] .popover .popover-primary > .popover.bs-popover-auto[data-popper-placement=right] > .popover-arrow::after, [dir=rtl] .popover .ngb-popover-primary + ngb-popover-window.bs-popover-auto[data-popper-placement=right] > .popover-arrow::after {
    border-left-color: #696cff !important
}

.popover.popover-primary.bs-popover-auto[data-popper-placement=bottom] > .popover-arrow::after, .popover .popover-primary > .popover.bs-popover-auto[data-popper-placement=bottom] > .popover-arrow::after, .popover .ngb-popover-primary + ngb-popover-window.bs-popover-auto[data-popper-placement=bottom] > .popover-arrow::after {
    border-bottom-color: #696cff !important
}

.popover.popover-primary.bs-popover-auto[data-popper-placement=left] > .popover-arrow::after, .popover .popover-primary > .popover.bs-popover-auto[data-popper-placement=left] > .popover-arrow::after, .popover .ngb-popover-primary + ngb-popover-window.bs-popover-auto[data-popper-placement=left] > .popover-arrow::after {
    border-left-color: #696cff !important
}

[dir=rtl] .popover.popover-primary.bs-popover-auto[data-popper-placement=left] > .popover-arrow::after, [dir=rtl] .popover .popover-primary > .popover.bs-popover-auto[data-popper-placement=left] > .popover-arrow::after, [dir=rtl] .popover .ngb-popover-primary + ngb-popover-window.bs-popover-auto[data-popper-placement=left] > .popover-arrow::after {
    border-right-color: #696cff !important
}

.popover.popover-primary.bs-popover-auto[data-popper-placement=bottom] .popover-header::before, .popover .popover-primary > .popover.bs-popover-auto[data-popper-placement=bottom] .popover-header::before, .popover .ngb-popover-primary + ngb-popover-window.bs-popover-auto[data-popper-placement=bottom] .popover-header::before {
    border-bottom: 1px solid rgba(0, 0, 0, 0) !important
}

.table-primary {
    --bs-table-bg: #e1e2ff;
    --bs-table-striped-bg: #d6d7f3;
    --bs-table-striped-color: #22303e;
    --bs-table-active-bg: #d2d4f0;
    --bs-table-active-color: #22303e;
    --bs-table-hover-bg: #d6d7f3;
    --bs-table-hover-color: #22303e;
    color: #22303e;
    border-color: #cacde8
}

.table-primary .btn-icon, .table-primary .btn {
    color: #22303e
}

.btn-primary {
    color: #fff !important;
    background-color: #696cff;
    border-color: #696cff;
    box-shadow: 0 .125rem .25rem 0 rgba(105, 108, 255, .4)
}

.btn-primary:hover {
    color: #fff !important;
    background-color: #5f61e6 !important;
    border-color: #5f61e6 !important
}

.btn-check:focus + .btn-primary, .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #5f61e6;
    border-color: #5f61e6
}

.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .btn-primary.show.dropdown-toggle, .show > .btn-primary.dropdown-toggle {
    color: #fff !important;
    background-color: #5f61e6 !important;
    border-color: #5f61e6 !important
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #fff !important;
    background-color: #696cff !important;
    border-color: #696cff !important
}

.btn-group .btn-primary, .input-group .btn-primary {
    border-right: var(--bs-border-width) solid #5f61e6;
    border-left: var(--bs-border-width) solid #5f61e6
}

.btn-group-vertical .btn-primary {
    border-top-color: #5f61e6;
    border-bottom-color: #5f61e6
}

.btn-label-primary {
    color: #696cff !important;
    border-color: rgba(0, 0, 0, 0);
    background: #e7e7ff
}

.btn-label-primary:hover {
    border-color: rgba(0, 0, 0, 0) !important;
    background: #5f61e6 !important;
    color: #fff !important;
    box-shadow: 0 .125rem .25rem 0 rgba(105, 108, 255, .4) !important
}

.btn-label-primary:focus, .btn-label-primary.focus {
    color: #fff !important;
    background: #5f61e6
}

.btn-label-primary:active, .btn-label-primary.active, .btn-label-primary.show.dropdown-toggle, .show > .btn-label-primary.dropdown-toggle {
    color: #fff !important;
    background-color: #5f61e6 !important;
    border-color: rgba(0, 0, 0, 0) !important
}

.btn-label-primary.disabled, .btn-label-primary:disabled {
    color: #696cff !important;
    border-color: rgba(0, 0, 0, 0) !important;
    background: #e7e7ff !important
}

.btn-group .btn-label-primary, .input-group .btn-label-primary {
    border-right: var(--bs-border-width) solid #cfd0ff !important;
    border-left: var(--bs-border-width) solid #cfd0ff !important
}

.btn-group-vertical .btn-label-primary {
    border-top: var(--bs-border-width) solid #cfd0ff !important;
    border-bottom: var(--bs-border-width) solid #cfd0ff !important
}

.btn-text-primary {
    color: #696cff
}

.btn-text-primary:hover {
    background: #f3f3ff;
    color: #696cff
}

.btn-text-primary:focus, .btn-text-primary.focus {
    color: #696cff;
    background: #f3f3ff
}

.btn-text-primary:active, .btn-text-primary.active, .btn-text-primary-check:checked + .btn-text-primary, :not(.btn-text-primary-check) + .btn-text-primary:active, .btn-text-primary:first-child:active, .btn-text-primary.show.dropdown-toggle, .show > .btn-text-primary.dropdown-toggle {
    color: #696cff;
    background: #f3f3ff !important
}

.btn-text-primary:disabled, .btn-text-primary.disabled {
    color: #696cff
}

.btn-group .btn-text-primary, .input-group .btn-text-primary {
    border-right: var(--bs-border-width) solid #696cff !important;
    border-left: var(--bs-border-width) solid #696cff !important
}

.btn-group-vertical .btn-text-primary {
    border-top: var(--bs-border-width) solid #696cff !important;
    border-bottom: var(--bs-border-width) solid #696cff !important
}

.btn-outline-primary {
    color: #696cff !important;
    border-color: #696cff;
    background: rgba(0, 0, 0, 0)
}

.btn-outline-primary:hover {
    color: #fff !important;
    background-color: #5f61e6 !important;
    border-color: #5f61e6 !important;
    box-shadow: 0 .125rem .25rem 0 rgba(105, 108, 255, .4) !important
}

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
    color: #fff;
    background-color: #5f61e6;
    border-color: #5f61e6
}

.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
    color: #fff !important;
    background-color: #5f61e6 !important;
    border-color: #5f61e6 !important
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #696cff !important;
    border-color: #696cff !important
}

.btn-outline-primary .badge {
    background: #696cff;
    border-color: #696cff;
    color: #fff
}

.btn-outline-primary:hover .badge, .btn-outline-primary:focus:hover .badge, .btn-outline-primary:active .badge, .btn-outline-primary.active .badge, .show > .btn-outline-primary.dropdown-toggle .badge {
    background: #fff;
    border-color: #fff
}

.dropdown-item:not(.disabled).active, .dropdown-item:not(.disabled):active {
    background-color: rgba(105, 108, 255, .16);
    color: #696cff !important
}

.dropdown-menu > li:not(.disabled) > a:not(.dropdown-item):active, .dropdown-menu > li.active:not(.disabled) > a:not(.dropdown-item) {
    background-color: rgba(105, 108, 255, .16);
    color: #696cff !important
}

.dt-button-collection .dropdown-item:not(.disabled):active {
    background-color: rgba(105, 108, 255, .16)
}

.nav-pills .nav-link.active {
    box-shadow: 0 .125rem .25rem 0 rgba(105, 108, 255, .4)
}

.nav-pills .nav-link.active, .nav-pills .nav-link.active:hover, .nav-pills .nav-link.active:focus {
    background-color: #696cff;
    color: #fff
}

.nav-tabs .nav-link.active, .nav-tabs .nav-link.active:hover, .nav-tabs .nav-link.active:focus, .nav-tabs .nav-link.active, .nav-tabs .nav-link.active:hover, .nav-tabs .nav-link.active:focus {
    box-shadow: 0 -2px 0 #696cff inset
}

.nav-align-bottom .nav-tabs .nav-link.active, .nav-align-bottom .nav-tabs .nav-link.active:hover, .nav-align-bottom .nav-tabs .nav-link.active:focus, .nav-align-bottom .nav-tabs .nav-link.active, .nav-align-bottom .nav-tabs .nav-link.active:hover, .nav-align-bottom .nav-tabs .nav-link.active:focus {
    box-shadow: 0 2px 0 #696cff inset
}

.nav-align-left .nav-tabs .nav-link.active, .nav-align-left .nav-tabs .nav-link.active:hover, .nav-align-left .nav-tabs .nav-link.active:focus, .nav-align-left .nav-tabs .nav-link.active, .nav-align-left .nav-tabs .nav-link.active:hover, .nav-align-left .nav-tabs .nav-link.active:focus {
    box-shadow: -2px 0px 0 #696cff inset
}

.nav-align-right .nav-tabs .nav-link.active, .nav-align-right .nav-tabs .nav-link.active:hover, .nav-align-right .nav-tabs .nav-link.active:focus, .nav-align-right .nav-tabs .nav-link.active, .nav-align-right .nav-tabs .nav-link.active:hover, .nav-align-right .nav-tabs .nav-link.active:focus {
    box-shadow: 2px 0px 0 #696cff inset
}

[dir=rtl] .nav-align-left .nav-tabs .nav-link.active, [dir=rtl] .nav-align-left .nav-tabs .nav-link.active:hover, [dir=rtl] .nav-align-left .nav-tabs .nav-link.active:focus, [dir=rtl] .nav-align-left .nav-tabs .nav-link.active, [dir=rtl] .nav-align-left .nav-tabs .nav-link.active:hover, [dir=rtl] .nav-align-left .nav-tabs .nav-link.active:focus {
    box-shadow: 2px 0px 0 #696cff inset
}

[dir=rtl] .nav-align-right .nav-tabs .nav-link.active, [dir=rtl] .nav-align-right .nav-tabs .nav-link.active:hover, [dir=rtl] .nav-align-right .nav-tabs .nav-link.active:focus, [dir=rtl] .nav-align-right .nav-tabs .nav-link.active, [dir=rtl] .nav-align-right .nav-tabs .nav-link.active:hover, [dir=rtl] .nav-align-right .nav-tabs .nav-link.active:focus {
    box-shadow: -2px 0px 0 #696cff inset
}

.form-control:focus, .form-select:focus {
    border-color: #696cff !important
}

.input-group:focus-within .form-control, .input-group:focus-within .input-group-text {
    border-color: #696cff !important
}

.form-check-input:checked {
    background-color: #696cff;
    border-color: #696cff;
    box-shadow: 0 .125rem .25rem 0 rgba(105, 108, 255, .4)
}

.form-check-input[type=checkbox]:indeterminate {
    background-color: #696cff;
    border-color: #696cff;
    box-shadow: 0 .125rem .25rem 0 rgba(105, 108, 255, .4)
}

.custom-option.checked {
    border: 2px solid #696cff !important;
    margin: 0
}

.custom-option.checked .custom-option-body i, .custom-option.checked .custom-option-header i {
    color: #696cff
}

.custom-option.custom-option-label.checked {
    background-color: rgba(105, 108, 255, .12);
    color: #696cff
}

.custom-option.custom-option-label.checked .custom-option-header span, .custom-option.custom-option-label.checked .custom-option-title {
    color: #696cff
}

.form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg filter='url%28%23a%29'%3e%3ccircle cx='12' cy='11' r='8.5' fill='%23fff'/%3e%3c/g%3e%3cdefs%3e%3cfilter id='a' x='0' y='0' width='22' height='22' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3e%3cfeFlood flood-opacity='0' result='BackgroundImageFix'/%3e%3cfeColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3e%3cfeOffset dy='2'/%3e%3cfeGaussianBlur stdDeviation='2'/%3e%3cfeColorMatrix values='0 0 0 0 0.180392 0 0 0 0 0.14902 0 0 0 0 0.239216 0 0 0 0.16 0'/%3e%3cfeBlend in2='BackgroundImageFix' result='effect1_dropShadow_6488_3264'/%3e%3cfeBlend in='SourceGraphic' in2='effect1_dropShadow_6488_3264' result='shape'/%3e%3c/filter%3e%3c/defs%3e%3c/svg%3e")
}

.form-switch .form-check-input:checked {
    background-image: url("data:image/svg+xml,%3csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg filter='url%28%23a%29'%3e%3ccircle cx='12' cy='11' r='8.5' fill='%23fff'/%3e%3c/g%3e%3cdefs%3e%3cfilter id='a' x='0' y='0' width='22' height='22' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3e%3cfeFlood flood-opacity='0' result='BackgroundImageFix'/%3e%3cfeColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3e%3cfeOffset dy='2'/%3e%3cfeGaussianBlur stdDeviation='2'/%3e%3cfeColorMatrix values='0 0 0 0 0.180392 0 0 0 0 0.14902 0 0 0 0 0.239216 0 0 0 0.16 0'/%3e%3cfeBlend in2='BackgroundImageFix' result='effect1_dropShadow_6488_3264'/%3e%3cfeBlend in='SourceGraphic' in2='effect1_dropShadow_6488_3264' result='shape'/%3e%3c/filter%3e%3c/defs%3e%3c/svg%3e")
}

.form-control:focus ~ .form-label {
    border-color: #696cff
}

.form-control:focus ~ .form-label::after {
    border-color: inherit
}

.switch-input:checked ~ .switch-toggle-slider {
    background: #696cff;
    color: #fff;
    box-shadow: 0 2px 6px 0 rgba(105, 108, 255, .3)
}

.switch-primary.switch .switch-input:checked ~ .switch-toggle-slider {
    background: #696cff;
    color: #fff;
    box-shadow: 0 2px 6px 0 rgba(105, 108, 255, .3)
}

.avatar .avatar-shadow-primary {
    color: #696cff;
    background-color: rgba(105, 108, 255, .16);
    box-shadow: 0 0 0 .25rem rgba(105, 108, 255, .06)
}

.timeline .timeline-point-primary {
    background-color: #696cff !important;
    outline: 3px solid rgba(105, 108, 255, .12)
}

.timeline.timeline-outline .timeline-point-primary {
    border: 2px solid #696cff !important
}

.timeline .timeline-indicator-primary {
    background-color: rgba(105, 108, 255, .16)
}

.timeline .timeline-indicator-primary i {
    color: #696cff !important
}

.divider.divider-primary .divider-text:before, .divider.divider-primary .divider-text:after {
    border-color: #696cff
}

.navbar.bg-primary {
    background-color: rgba(105, 108, 255, .88) !important;
    color: #e0e1ff
}

.navbar.bg-primary .navbar-brand, .navbar.bg-primary .navbar-brand a {
    color: #fff
}

.navbar.bg-primary .navbar-brand:hover, .navbar.bg-primary .navbar-brand:focus, .navbar.bg-primary .navbar-brand a:hover, .navbar.bg-primary .navbar-brand a:focus {
    color: #fff
}

.navbar.bg-primary .navbar-search-wrapper .navbar-search-icon, .navbar.bg-primary .navbar-search-wrapper .search-input {
    color: #e0e1ff
}

.navbar.bg-primary .search-input-wrapper .search-input, .navbar.bg-primary .search-input-wrapper .search-toggler {
    background-color: #696cff !important;
    color: #e0e1ff
}

.navbar.bg-primary .navbar-nav > .nav-link, .navbar.bg-primary .navbar-nav > .nav-item > .nav-link, .navbar.bg-primary .navbar-nav > .nav > .nav-item > .nav-link {
    color: #e0e1ff
}

.navbar.bg-primary .navbar-nav > .nav-link:hover, .navbar.bg-primary .navbar-nav > .nav-link:focus, .navbar.bg-primary .navbar-nav > .nav-item > .nav-link:hover, .navbar.bg-primary .navbar-nav > .nav-item > .nav-link:focus, .navbar.bg-primary .navbar-nav > .nav > .nav-item > .nav-link:hover, .navbar.bg-primary .navbar-nav > .nav > .nav-item > .nav-link:focus {
    color: #fff
}

.navbar.bg-primary .navbar-nav > .nav-link.disabled, .navbar.bg-primary .navbar-nav > .nav-item > .nav-link.disabled, .navbar.bg-primary .navbar-nav > .nav > .nav-item > .nav-link.disabled {
    color: #b0b2ff !important
}

.navbar.bg-primary .navbar-nav .show > .nav-link, .navbar.bg-primary .navbar-nav .active > .nav-link, .navbar.bg-primary .navbar-nav .nav-link.show, .navbar.bg-primary .navbar-nav .nav-link.active {
    color: #fff
}

.navbar.bg-primary .navbar-toggler {
    color: #e0e1ff;
    border-color: rgba(255, 255, 255, .15)
}

.navbar.bg-primary .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3Csvg width='14px' height='11px' viewBox='0 0 14 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M0,0 L14,0 L14,1.75 L0,1.75 L0,0 Z M0,4.375 L14,4.375 L14,6.125 L0,6.125 L0,4.375 Z M0,8.75 L14,8.75 L14,10.5 L0,10.5 L0,8.75 Z' id='path-1'%3E%3C/path%3E%3C/defs%3E%3Cg id='💎-UI-Elements' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='12)-Navbar' transform='translate(-1174.000000, -1290.000000)'%3E%3Cg id='Group' transform='translate(1174.000000, 1288.000000)'%3E%3Cg id='Icon-Color' transform='translate(0.000000, 2.000000)'%3E%3Cuse fill='rgba(255, 255, 255, 0.8)' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='0.1' fill='rgba(255, 255, 255, 0.8)' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
}

.navbar.bg-primary .navbar-text {
    color: #e0e1ff
}

.navbar.bg-primary .navbar-text a {
    color: #fff
}

.navbar.bg-primary .navbar-text a:hover, .navbar.bg-primary .navbar-text a:focus {
    color: #fff
}

.navbar.bg-primary hr {
    border-color: rgba(255, 255, 255, .15)
}

.menu.bg-primary {
    background-color: #696cff !important;
    color: #e0e1ff
}

.menu.bg-primary.menu-horizontal {
    background-color: rgba(105, 108, 255, .95) !important
}

.menu.bg-primary .menu-link, .menu.bg-primary .menu-horizontal-prev, .menu.bg-primary .menu-horizontal-next {
    color: #e0e1ff
}

.menu.bg-primary .menu-link:hover, .menu.bg-primary .menu-link:focus, .menu.bg-primary .menu-horizontal-prev:hover, .menu.bg-primary .menu-horizontal-prev:focus, .menu.bg-primary .menu-horizontal-next:hover, .menu.bg-primary .menu-horizontal-next:focus {
    color: #fff
}

.menu.bg-primary .menu-link.active, .menu.bg-primary .menu-horizontal-prev.active, .menu.bg-primary .menu-horizontal-next.active {
    color: #fff
}

.menu.bg-primary .menu-item.disabled .menu-link, .menu.bg-primary .menu-horizontal-prev.disabled, .menu.bg-primary .menu-horizontal-next.disabled {
    color: #b0b2ff !important
}

.menu.bg-primary .menu-item.open:not(.menu-item-closing) > .menu-toggle, .menu.bg-primary .menu-item.active > .menu-link {
    color: #fff
}

.menu.bg-primary .menu-item.active > .menu-link:not(.menu-toggle) {
    background-color: #6d70ff
}

.menu.bg-primary.menu-horizontal .menu-sub > .menu-item.active > .menu-link:not(.menu-toggle) {
    background-color: #7174ff
}

.menu.bg-primary.menu-horizontal .menu-inner .menu-item:not(.menu-item-closing) > .menu-sub, .menu.bg-primary.menu-horizontal .menu-inner .menu-item.open > .menu-toggle {
    background: #6d70ff
}

.menu.bg-primary .menu-inner > .menu-item.menu-item-closing .menu-item.open .menu-sub, .menu.bg-primary .menu-inner > .menu-item.menu-item-closing .menu-item.open .menu-toggle {
    background: rgba(0, 0, 0, 0);
    color: #e0e1ff
}

.menu.bg-primary .menu-inner-shadow {
    background: linear-gradient(#696cff 41%, rgba(105, 108, 255, 0.11) 95%, rgba(105, 108, 255, 0))
}

.menu.bg-primary .menu-text {
    color: #fff
}

.menu.bg-primary .menu-header {
    color: #c2c4ff
}

.menu.bg-primary hr, .menu.bg-primary .menu-divider, .menu.bg-primary .menu-inner > .menu-item.open > .menu-sub::before {
    border-color: rgba(255, 255, 255, .15) !important
}

.menu.bg-primary .menu-block::before {
    background-color: #c2c4ff
}

.menu.bg-primary .menu-inner > .menu-item.open .menu-item.open > .menu-toggle::before {
    background-color: #8385ff
}

.menu.bg-primary .menu-inner > .menu-item.open .menu-item.active > .menu-link::before {
    background-color: #fff
}

.menu.bg-primary .menu-inner > .menu-item.open .menu-item.open > .menu-toggle::before, .menu.bg-primary .menu-inner > .menu-item.open .menu-item.active > .menu-link::before {
    box-shadow: 0 0 0 1px #6d70ff
}

.menu.bg-primary .ps__thumb-y, .menu.bg-primary .ps__rail-y.ps--clicking > .ps__thumb-y {
    background: rgba(255, 255, 255, .5942917647) !important
}

@media (max-width: 1199.98px) {
    .app-brand .layout-menu-toggle {
        border: 7px solid #696cff !important
    }
}

.footer.bg-primary {
    color: #e0e1ff
}

.layout-footer-fixed .layout-horizontal .footer.bg-primary {
    background-color: #696cff !important
}

.layout-footer-fixed .layout-wrapper:not(.layout-horizontal) .footer.bg-primary .footer-container {
    background-color: #696cff !important
}

.footer.bg-primary .footer-link {
    color: #fff
}

.footer.bg-primary .footer-link:hover, .footer.bg-primary .footer-link:focus {
    color: #fff
}

.footer.bg-primary .footer-link.disabled {
    color: #b0b2ff !important
}

.footer.bg-primary .footer-text {
    color: #e0e1ff
}

.footer.bg-primary .show > .footer-link, .footer.bg-primary .active > .footer-link, .footer.bg-primary .footer-link.show, .footer.bg-primary .footer-link.active {
    color: #fff
}

.footer.bg-primary hr {
    border-color: rgba(255, 255, 255, .15)
}

.bg-primary.toast, .bg-primary.bs-toast {
    color: #fff;
    background-color: rgba(105, 108, 255, .85) !important;
    box-shadow: 0 .25rem 1rem rgba(105, 108, 255, .4)
}

.bg-primary.toast .toast-header, .bg-primary.bs-toast .toast-header {
    color: #fff
}

.bg-primary.toast .toast-header .btn-close, .bg-primary.bs-toast .toast-header .btn-close {
    background-color: #696cff !important;
    background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='1' fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    box-shadow: 0 .1875rem .375rem 0 rgba(105, 108, 255, .4) !important
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:focus:not(:placeholder-shown) ~ label, .form-floating > .form-select:focus ~ label, .form-floating > .form-select:focus:not(:placeholder-shown) ~ label {
    color: #696cff
}

.svg-illustration svg {
    fill: #696cff
}

.jstree-default .jstree-wholerow-clicked, .jstree-default .jstree-wholerow-hovered {
    background: #e9e9ff
}

.jstree-default-dark .jstree-wholerow-hovered, .jstree-default-dark .jstree-wholerow-clicked {
    background: #474996
}

.card.card-border-shadow-primary::after {
    border-bottom-color: #c3c4ff
}

.card.card-border-shadow-primary:hover::after {
    border-bottom-color: #696cff
}

.card.card-hover-border-primary:hover, .card .card-hover-border-primary:hover {
    border-color: #c6c7ff
}

html:not([dir=rtl]) .border-primary, html[dir=rtl] .border-primary {
    border-color: #696cff !important
}

a {
    color: #696cff
}

a:hover {
    color: #787bff
}

.fill-primary {
    fill: #696cff
}

.noUi-target:not([disabled]) {
    background: rgba(105, 108, 255, .16)
}

.noUi-target:not([disabled]) .noUi-connect {
    background: #696cff
}

.noUi-target:not([disabled]) .noUi-handle {
    border-color: #696cff
}

.noUi-target:not([disabled]) .noUi-handle:hover {
    box-shadow: 0 0 0 8px rgba(105, 108, 255, .16)
}

.noUi-target:not([disabled]) .noUi-handle:active, .noUi-target:not([disabled]) .noUi-handle:focus {
    box-shadow: 0 0 0 13px rgba(105, 108, 255, .16)
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: rgba(105, 108, 255, .16) !important;
    color: #696cff !important
}

.select2-container--default.select2-container--focus .select2-selection, .select2-container--default.select2-container--open .select2-selection {
    border-width: 2px;
    border-color: #696cff !important
}

.select2-primary .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background: rgba(105, 108, 255, .16) !important;
    color: #696cff !important
}

.tagify--focus {
    border-color: #696cff !important
}

.tagify__dropdown__item--active {
    color: #696cff !important;
    background: rgba(105, 108, 255, .16) !important
}

table.dataTable.dtr-column > tbody > tr > td.control:before, table.dataTable.dtr-column > tbody > tr > th.control:before {
    background-color: #696cff;
    border: 2px solid #fff;
    box-shadow: 0 0 3px rgba(34, 48, 62, .8)
}

.bootstrap-select .dropdown-menu.inner a[aria-selected=true] {
    background: rgba(105, 108, 255, .16) !important;
    color: #696cff !important
}

.bootstrap-select .dropdown-menu li.active small, .bootstrap-select .dropdown-menu li:active small {
    color: #696cff !important
}

.bootstrap-select .dropdown-menu .dropdown-item.selected span::before {
    color: #696cff !important
}

.bootstrap-select .dropdown-toggle.show {
    border-color: #696cff
}

.datepicker table tr td .focused, .datepicker table tr td span.active, .datepicker table tr td span.active.disabled, .datepicker table tr td.range-start, .datepicker table tr td.range-end {
    background: #696cff !important;
    color: #fff !important;
    box-shadow: 0 .125rem .25rem 0 rgba(105, 108, 255, .4)
}

.datepicker table tr td.active, .datepicker table tr td.active.highlighted {
    color: #696cff;
    background: #e7e7ff
}

.datepicker table tr td.range, .datepicker table tr td.range.highlighted, .datepicker table tr td.range.today {
    color: #696cff !important;
    background: #e7e7ff !important
}

.datepicker table tr td.range.focused, .datepicker table tr td.range.highlighted.focused, .datepicker table tr td.range.today.focused {
    background: #e7e7ff !important
}

.datepicker table tr td.range.disabled, .datepicker table tr td.range.highlighted.disabled, .datepicker table tr td.range.today.disabled {
    background: rgba(231, 231, 255, .5) !important;
    color: rgba(105, 108, 255, .5) !important
}

.datepicker table tr td.today:not(.active) {
    background-color: #696cff !important;
    color: #fff !important;
    box-shadow: 0 .125rem .25rem 0 rgba(105, 108, 255, .4)
}

.flatpickr-day.today, .flatpickr-day.today:hover {
    color: #fff !important;
    border-color: #696cff;
    background-color: #696cff !important;
    box-shadow: 0 .125rem .25rem 0 rgba(105, 108, 255, .4)
}

.flatpickr-day.selected, .flatpickr-day.selected:focus, .flatpickr-day.selected:hover, .flatpickr-day.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange {
    color: #696cff !important;
    background: #e7e7ff !important;
    border-color: #e7e7ff !important
}

.flatpickr-day.selected.inRange, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange, .flatpickr-day.startRange.inRange, .flatpickr-day.startRange:focus, .flatpickr-day.startRange:hover, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange, .flatpickr-day.endRange.inRange, .flatpickr-day.endRange:focus, .flatpickr-day.endRange:hover, .flatpickr-day.endRange.nextMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.week.selected {
    color: #fff !important;
    background: #696cff !important;
    border-color: #696cff !important;
    box-shadow: 0 .125rem .25rem 0 rgba(105, 108, 255, .4)
}

.daterangepicker table thead th select, .daterangepicker table thead td select {
    background-color: rgba(0, 0, 0, 0)
}

.daterangepicker td.active:not(.off) {
    background: #696cff !important;
    color: #fff;
    box-shadow: 0 .125rem .25rem 0 rgba(105, 108, 255, .4)
}

.daterangepicker .start-date:not(.end-date):not(.off), .daterangepicker .end-date:not(.start-date):not(.off) {
    background-color: #696cff;
    color: #fff;
    border: 0 !important
}

.daterangepicker .start-date:not(.end-date):not(.off):hover, .daterangepicker .end-date:not(.start-date):not(.off):hover {
    background-color: #696cff !important
}

.daterangepicker .input-mini.active {
    border-color: #696cff !important
}

.daterangepicker td.in-range:not(.start-date):not(.end-date):not(.off) {
    color: #696cff !important;
    background-color: #e7e7ff !important
}

.ranges li.active {
    color: #fff !important;
    background-color: #696cff !important
}

li.ui-timepicker-selected, .ui-timepicker-list .ui-timepicker-selected:hover {
    color: #fff !important;
    background: #696cff !important
}

.ql-snow.ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar button:hover, .ql-snow .ql-toolbar button:focus, .ql-snow .ql-toolbar button.ql-active, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: #696cff !important
}

.ql-snow.ql-toolbar button:hover .ql-fill, .ql-snow.ql-toolbar button:focus .ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:hover .ql-fill, .ql-snow .ql-toolbar button:focus .ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
    fill: #696cff !important
}

.ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
    stroke: #696cff !important
}

.tt-suggestion:active, .tt-cursor {
    background: rgba(105, 108, 255, .08) !important;
    color: #696cff !important
}

.dropzone.dz-drag-hover {
    border-color: #696cff !important
}

.swiper-pagination-bullet.swiper-pagination-bullet-active, .swiper-pagination.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: #696cff !important
}

.sk-primary.sk-plane, .sk-primary .sk-chase-dot:before, .sk-primary .sk-bounce-dot, .sk-primary .sk-wave-rect, .sk-primary.sk-pulse, .sk-primary .sk-swing-dot, .sk-primary .sk-circle-dot:before, .sk-primary .sk-circle-fade-dot:before, .sk-primary .sk-grid-cube, .sk-primary .sk-fold-cube:before {
    background-color: #696cff
}

.plyr input[type=range]::-ms-fill-lower {
    background: #696cff !important
}

.plyr input[type=range]:active::-webkit-slider-thumb {
    background: #696cff !important
}

.plyr input[type=range]:active::-moz-range-thumb {
    background: #696cff !important
}

.plyr input[type=range]:active::-ms-thumb {
    background: #696cff !important
}

.plyr--video .plyr__control.plyr__control--overlaid, .plyr--video .plyr__controls button.tab-focus:focus, .plyr--video .plyr__control[aria-expanded=true], .plyr--video .plyr__controls button:hover {
    background: #696cff !important;
    color: #fff !important
}

.plyr--audio .plyr__controls button.tab-focus:focus, .plyr--audio .plyr__control[aria-expanded=true], .plyr--audio .plyr__controls button:hover {
    background: #696cff !important;
    color: #fff !important
}

.plyr__play-large {
    background: #696cff !important;
    color: #fff !important
}

.plyr__progress--played, .plyr__volume--display {
    color: #696cff !important
}

.plyr--full-ui input[type=range] {
    color: #696cff !important
}

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
    background: #696cff !important
}

.fc .fc-event-primary:not(.fc-list-event) {
    background-color: #e7e7ff !important;
    color: #696cff !important
}

.fc .fc-event-primary.fc-list-event .fc-list-event-dot {
    border-color: #696cff !important
}

.fc .fc-button-primary:not(.fc-prev-button):not(.fc-next-button) {
    background-color: rgba(105, 108, 255, .16) !important;
    border: 0;
    color: #696cff
}

.fc .fc-button-primary:not(.fc-prev-button):not(.fc-next-button).fc-button-active, .fc .fc-button-primary:not(.fc-prev-button):not(.fc-next-button):hover {
    background-color: #696cff !important;
    border-color: #696cff !important;
    color: #fff
}

.fc .fc-button-primary:not(.fc-prev-button):not(.fc-next-button):hover {
    box-shadow: 0 .125rem .25rem 0 rgba(105, 108, 255, .4)
}

.swal2-progress-steps[class] .swal2-progress-step.swal2-active-progress-step, .swal2-progress-steps[class] .swal2-progress-step-line, .swal2-progress-steps[class] .swal2-active-progress-step, .swal2-progress-steps[class] .swal2-progress-step {
    background: #696cff;
    color: #fff
}

.swal2-progress-steps[class] .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step, .swal2-progress-steps[class] .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step-line {
    background: #e9e9ff
}

.pcr-app .pcr-type.active, .pcr-app .pcr-save {
    background: #696cff !important
}

.icon-card.active {
    outline: 1px solid #696cff
}

.icon-card.active i, .icon-card.active svg {
    color: #696cff
}

.shepherd-element[data-popper-placement=bottom] .shepherd-arrow:before {
    background-color: #fff !important;
    border-color: #fff
}

.shepherd-element .shepherd-header {
    color: #fff
}

.shepherd-element .shepherd-header .shepherd-title, .shepherd-element .shepherd-header .shepherd-cancel-icon {
    color: #fff !important
}

.shepherd-element .shepherd-button:not(:disabled).btn-primary {
    color: #fff;
    background-color: #696cff;
    border-color: #696cff;
    box-shadow: 0 .125rem .25rem 0 rgba(105, 108, 255, .4)
}

.shepherd-element .shepherd-button:not(:disabled).btn-primary:hover {
    color: #fff !important;
    background-color: #5f61e6 !important;
    border-color: #5f61e6 !important
}

.btn-check:focus + .shepherd-element .shepherd-button:not(:disabled).btn-primary, .shepherd-element .shepherd-button:not(:disabled).btn-primary:focus, .shepherd-element .shepherd-button:not(:disabled).btn-primary.focus {
    color: #fff;
    background-color: #5f61e6;
    border-color: #5f61e6
}

.btn-check:checked + .shepherd-element .shepherd-button:not(:disabled).btn-primary, .btn-check:active + .shepherd-element .shepherd-button:not(:disabled).btn-primary, .shepherd-element .shepherd-button:not(:disabled).btn-primary:active, .shepherd-element .shepherd-button:not(:disabled).btn-primary.active, .shepherd-element .shepherd-button:not(:disabled).btn-primary.show.dropdown-toggle, .show > .shepherd-element .shepherd-button:not(:disabled).btn-primary.dropdown-toggle {
    color: #fff !important;
    background-color: #5f61e6 !important;
    border-color: #5f61e6 !important
}

.shepherd-element .shepherd-button:not(:disabled).btn-primary.disabled, .shepherd-element .shepherd-button:not(:disabled).btn-primary:disabled {
    color: #fff !important;
    background-color: #696cff !important;
    border-color: #696cff !important
}

.shepherd-element .btn-group .shepherd-button:not(:disabled).btn-primary, .shepherd-element .input-group .shepherd-button:not(:disabled).btn-primary {
    border-right: var(--bs-border-width) solid #5f61e6;
    border-left: var(--bs-border-width) solid #5f61e6
}

.shepherd-element .btn-group-vertical .shepherd-button:not(:disabled).btn-primary {
    border-top-color: #5f61e6;
    border-bottom-color: #5f61e6
}

.shepherd-element .shepherd-button:not(:disabled).btn-label-secondary {
    color: #8592a3 !important;
    border-color: rgba(0, 0, 0, 0);
    background: #ebeef0
}

.shepherd-element .shepherd-button:not(:disabled).btn-label-secondary:hover {
    border-color: rgba(0, 0, 0, 0) !important;
    background: #788393 !important;
    color: #fff !important;
    box-shadow: 0 .125rem .25rem 0 rgba(133, 146, 163, .4) !important
}

.shepherd-element .shepherd-button:not(:disabled).btn-label-secondary:focus, .shepherd-element .shepherd-button:not(:disabled).btn-label-secondary.focus {
    color: #fff !important;
    background: #788393
}

.shepherd-element .shepherd-button:not(:disabled).btn-label-secondary:active, .shepherd-element .shepherd-button:not(:disabled).btn-label-secondary.active, .shepherd-element .shepherd-button:not(:disabled).btn-label-secondary.show.dropdown-toggle, .show > .shepherd-element .shepherd-button:not(:disabled).btn-label-secondary.dropdown-toggle {
    color: #fff !important;
    background-color: #788393 !important;
    border-color: rgba(0, 0, 0, 0) !important
}

.shepherd-element .shepherd-button:not(:disabled).btn-label-secondary.disabled, .shepherd-element .shepherd-button:not(:disabled).btn-label-secondary:disabled {
    color: #8592a3 !important;
    border-color: rgba(0, 0, 0, 0) !important;
    background: #ebeef0 !important
}

.shepherd-element .btn-group .shepherd-button:not(:disabled).btn-label-secondary, .shepherd-element .input-group .shepherd-button:not(:disabled).btn-label-secondary {
    border-right: var(--bs-border-width) solid #d8dce2 !important;
    border-left: var(--bs-border-width) solid #d8dce2 !important
}

.shepherd-element .btn-group-vertical .shepherd-button:not(:disabled).btn-label-secondary {
    border-top: var(--bs-border-width) solid #d8dce2 !important;
    border-bottom: var(--bs-border-width) solid #d8dce2 !important
}

.bs-stepper .step.active .bs-stepper-circle {
    background-color: #696cff;
    color: #fff;
    box-shadow: 0 .125rem .25rem 0 rgba(105, 108, 255, .4)
}

.bs-stepper .step.active .bs-stepper-icon svg {
    fill: #696cff !important
}

.bs-stepper .step.active .bs-stepper-icon i, .bs-stepper .step.active .bs-stepper-label {
    color: #696cff !important
}

.bs-stepper .step.active .bs-stepper-icon i .bs-stepper-title, .bs-stepper .step.active .bs-stepper-label .bs-stepper-title {
    color: #696cff
}

.bs-stepper .step.crossed .step-trigger .bs-stepper-circle {
    background-color: rgba(105, 108, 255, .16) !important;
    color: #696cff !important
}

.bs-stepper .step.crossed .step-trigger .bs-stepper-icon svg {
    fill: #696cff !important
}

.bs-stepper .step.crossed .step-trigger .bs-stepper-icon i {
    color: #696cff !important
}

.bs-stepper.wizard-icons .step.crossed .bs-stepper-label {
    color: #696cff !important
}

.bs-stepper.wizard-icons .step.crossed + .line i {
    color: #696cff
}

.app-chat .sidebar-body .chat-contact-list li.active {
    background-color: #696cff
}

.app-chat .app-chat-history .chat-history .chat-message.chat-message-right .chat-message-text {
    background-color: #696cff !important
}

.navbar.landing-navbar .navbar-nav .show > .nav-link, .navbar.landing-navbar .navbar-nav .active > .nav-link, .navbar.landing-navbar .navbar-nav .nav-link.show, .navbar.landing-navbar .navbar-nav .nav-link.active, .navbar.landing-navbar .navbar-nav .nav-link:hover {
    color: #696cff !important
}

.landing-features .features-icon-wrapper .features-icon-box .features-icon {
    border: 2px solid rgba(105, 108, 255, .2)
}

.landing-features .features-icon-wrapper .features-icon-box:hover .features-icon {
    background-color: rgba(105, 108, 255, .05)
}

.bg-navbar-theme {
    background-color: rgba(255, 255, 255, .88) !important;
    color: #384551
}

.bg-navbar-theme .navbar-brand, .bg-navbar-theme .navbar-brand a {
    color: #646e78
}

.bg-navbar-theme .navbar-brand:hover, .bg-navbar-theme .navbar-brand:focus, .bg-navbar-theme .navbar-brand a:hover, .bg-navbar-theme .navbar-brand a:focus {
    color: #646e78
}

.bg-navbar-theme .navbar-search-wrapper .navbar-search-icon, .bg-navbar-theme .navbar-search-wrapper .search-input {
    color: #384551
}

.bg-navbar-theme .search-input-wrapper .search-input, .bg-navbar-theme .search-input-wrapper .search-toggler {
    background-color: #fff !important;
    color: #384551
}

.bg-navbar-theme .navbar-nav > .nav-link, .bg-navbar-theme .navbar-nav > .nav-item > .nav-link, .bg-navbar-theme .navbar-nav > .nav > .nav-item > .nav-link {
    color: #384551
}

.bg-navbar-theme .navbar-nav > .nav-link:hover, .bg-navbar-theme .navbar-nav > .nav-link:focus, .bg-navbar-theme .navbar-nav > .nav-item > .nav-link:hover, .bg-navbar-theme .navbar-nav > .nav-item > .nav-link:focus, .bg-navbar-theme .navbar-nav > .nav > .nav-item > .nav-link:hover, .bg-navbar-theme .navbar-nav > .nav > .nav-item > .nav-link:focus {
    color: #646e78
}

.bg-navbar-theme .navbar-nav > .nav-link.disabled, .bg-navbar-theme .navbar-nav > .nav-item > .nav-link.disabled, .bg-navbar-theme .navbar-nav > .nav > .nav-item > .nav-link.disabled {
    color: #888f97 !important
}

.bg-navbar-theme .navbar-nav .show > .nav-link, .bg-navbar-theme .navbar-nav .active > .nav-link, .bg-navbar-theme .navbar-nav .nav-link.show, .bg-navbar-theme .navbar-nav .nav-link.active {
    color: #646e78
}

.bg-navbar-theme .navbar-toggler {
    color: #384551;
    border-color: rgba(100, 110, 120, .075)
}

.bg-navbar-theme .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3Csvg width='14px' height='11px' viewBox='0 0 14 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M0,0 L14,0 L14,1.75 L0,1.75 L0,0 Z M0,4.375 L14,4.375 L14,6.125 L0,6.125 L0,4.375 Z M0,8.75 L14,8.75 L14,10.5 L0,10.5 L0,8.75 Z' id='path-1'%3E%3C/path%3E%3C/defs%3E%3Cg id='💎-UI-Elements' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='12)-Navbar' transform='translate(-1174.000000, -1290.000000)'%3E%3Cg id='Group' transform='translate(1174.000000, 1288.000000)'%3E%3Cg id='Icon-Color' transform='translate(0.000000, 2.000000)'%3E%3Cuse fill='rgba(34, 48, 62, 0.5)' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='0.1' fill='rgba(34, 48, 62, 0.5)' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
}

.bg-navbar-theme .navbar-text {
    color: #384551
}

.bg-navbar-theme .navbar-text a {
    color: #646e78
}

.bg-navbar-theme .navbar-text a:hover, .bg-navbar-theme .navbar-text a:focus {
    color: #646e78
}

.bg-navbar-theme hr {
    border-color: rgba(100, 110, 120, .075)
}

.layout-navbar {
    background-color: rgba(255, 255, 255, .88) !important;
    backdrop-filter: saturate(200%) blur(6px)
}

.layout-horizontal .layout-navbar {
    box-shadow: 0 1px 0 #e4e6e8
}


.layout-navbar-fixed .layout-page:before {
    backdrop-filter: saturate(200%) blur(10px);
    background: linear-gradient(180deg, rgba(245, 245, 249, 0.7) 44%, rgba(245, 245, 249, 0.43) 73%, rgba(245, 245, 249, 0));
    -webkit-mask: linear-gradient(#f5f5f9, #f5f5f9 18%, transparent 100%);
    mask: linear-gradient(#f5f5f9, #f5f5f9 18%, transparent 100%)
}

.bg-menu-theme {
    background-color: #fff !important;
    color: #384551
}

.bg-menu-theme.menu-horizontal {
    background-color: rgba(255, 255, 255, .95) !important
}

.bg-menu-theme .menu-link, .bg-menu-theme .menu-horizontal-prev, .bg-menu-theme .menu-horizontal-next {
    color: #384551
}

.bg-menu-theme .menu-link:hover, .bg-menu-theme .menu-link:focus, .bg-menu-theme .menu-horizontal-prev:hover, .bg-menu-theme .menu-horizontal-prev:focus, .bg-menu-theme .menu-horizontal-next:hover, .bg-menu-theme .menu-horizontal-next:focus {
    color: #384551
}

.bg-menu-theme .menu-link.active, .bg-menu-theme .menu-horizontal-prev.active, .bg-menu-theme .menu-horizontal-next.active {
    color: #384551
}

.bg-menu-theme .menu-item.disabled .menu-link, .bg-menu-theme .menu-horizontal-prev.disabled, .bg-menu-theme .menu-horizontal-next.disabled {
    color: #888f97 !important
}

.bg-menu-theme .menu-item.open:not(.menu-item-closing) > .menu-toggle, .bg-menu-theme .menu-item.active > .menu-link {
    color: #384551
}

.bg-menu-theme .menu-item.active > .menu-link:not(.menu-toggle) {
    background-color: #fff
}

.bg-menu-theme.menu-horizontal .menu-sub > .menu-item.active > .menu-link:not(.menu-toggle) {
    background-color: #fff
}

.bg-menu-theme.menu-horizontal .menu-inner .menu-item:not(.menu-item-closing) > .menu-sub, .bg-menu-theme.menu-horizontal .menu-inner .menu-item.open > .menu-toggle {
    background: #fff
}

.bg-menu-theme .menu-inner > .menu-item.menu-item-closing .menu-item.open .menu-sub, .bg-menu-theme .menu-inner > .menu-item.menu-item-closing .menu-item.open .menu-toggle {
    background: rgba(0, 0, 0, 0);
    color: #384551
}

.bg-menu-theme .menu-inner-shadow {
    background: linear-gradient(#fff 41%, rgba(255, 255, 255, 0.11) 95%, rgba(255, 255, 255, 0))
}

.bg-menu-theme .menu-text {
    color: #384551
}

.bg-menu-theme .menu-header {
    color: #6a747d
}

.bg-menu-theme hr, .bg-menu-theme .menu-divider, .bg-menu-theme .menu-inner > .menu-item.open > .menu-sub::before {
    border-color: rgba(0, 0, 0, 0) !important
}

.bg-menu-theme .menu-block::before {
    background-color: #6a747d
}

.bg-menu-theme .menu-inner > .menu-item.open .menu-item.open > .menu-toggle::before {
    background-color: #fff
}

.bg-menu-theme .menu-inner > .menu-item.open .menu-item.active > .menu-link::before {
    background-color: #384551
}

.bg-menu-theme .menu-inner > .menu-item.open .menu-item.open > .menu-toggle::before, .bg-menu-theme .menu-inner > .menu-item.open .menu-item.active > .menu-link::before {
    box-shadow: 0 0 0 1px #fff
}

.bg-menu-theme .ps__thumb-y, .bg-menu-theme .ps__rail-y.ps--clicking > .ps__thumb-y {
    background: rgba(56, 69, 81, .2) !important
}

@media (max-width: 1199.98px) {
    .app-brand .layout-menu-toggle {
        border: 7px solid #fff !important
    }
}

html:not(.layout-menu-collapsed) .bg-menu-theme .menu-inner .menu-item.open > .menu-link, .layout-menu-hover.layout-menu-collapsed .bg-menu-theme .menu-inner .menu-item.open > .menu-link, html:not(.layout-menu-collapsed) .bg-menu-theme .menu-inner .menu-item .menu-link:not(.active):hover, .layout-menu-hover.layout-menu-collapsed .bg-menu-theme .menu-inner .menu-item .menu-link:not(.active):hover {
    background-color: rgba(34, 48, 62, .06)
}

.bg-menu-theme .menu-inner .menu-sub > .menu-item.active > .menu-link.menu-toggle {
    background-color: rgba(34, 48, 62, .08) !important
}

.bg-menu-theme .menu-inner .menu-sub > .menu-item.active:not(:has(.menu-sub)) .menu-icon {
    color: #696cff
}

.bg-menu-theme .menu-inner > .menu-item.active > .menu-link, .bg-menu-theme.menu-horizontal .menu-inner > .menu-item.active > .menu-sub > .menu-item.active:not(:has(.menu-sub)) > .menu-link {
    color: #696cff;
    background-color: rgba(105, 108, 255, .16) !important
}

.bg-menu-theme .menu-inner > .menu-item.active:before {
    background: #696cff
}

.bg-menu-theme .menu-sub > .menu-item > .menu-link:before {
    background-color: #a7acb2 !important
}

.bg-menu-theme .menu-sub > .menu-item.active > .menu-link:not(.menu-toggle):before {
    background-color: #696cff !important;
    border: 3px solid #e7e7ff !important
}

.app-brand .layout-menu-toggle {
    background-color: #696cff;
    border: 7px solid #f5f5f9
}

.app-brand .layout-menu-toggle i {
    color: #fff
}

.app-brand .layout-menu-toggle .menu-inner > .menu-header::before {
    background-color: #a7acb2
}

.bg-footer-theme {
    color: #646e78
}

.layout-footer-fixed .layout-horizontal .bg-footer-theme {
    background-color: #fff !important
}

.layout-footer-fixed .layout-wrapper:not(.layout-horizontal) .bg-footer-theme .footer-container {
    background-color: #fff !important
}

.bg-footer-theme .footer-link {
    color: #696cff
}

.bg-footer-theme .footer-link:hover, .bg-footer-theme .footer-link:focus {
    color: #696cff
}

.bg-footer-theme .footer-link.disabled {
    color: #a2a8ae !important
}

.bg-footer-theme .footer-text {
    color: #646e78
}

.bg-footer-theme .show > .footer-link, .bg-footer-theme .active > .footer-link, .bg-footer-theme .footer-link.show, .bg-footer-theme .footer-link.active {
    color: #696cff
}

.bg-footer-theme hr {
    border-color: rgba(105, 108, 255, .075)
}
