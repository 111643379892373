
.input-block {
    position: relative;
}

.input-block input {
    font-weight: 500;
    font-size: 1.6rem;
    color: #495055;
    width: 100%;
    padding: 15px 15px;
    border-radius: 10px;
    border: 1px solid #D9D9D9;
    outline: none;
}
.table-alignment {
    float: left;
}
.name-alignments {
    display: flex;
    align-items: center;

}
.avatar.design.me-2 {
    height: 120px;
    width: 120px;
    margin-bottom: 10px;
}
.upload-file{
    display: flex;
    flex-direction: column;
    align-items: center;
  justify-content: center;
    border-radius: 8px;
   padding: 15px;
    width:180px;
   height: 90px;
    cursor: pointer;
text-align: center;
}
.avatar.avatar-xs.me-2 {
    height: 30px;
    width: 30px;
}
i.bx.bx-user.bx-sm.me-2.ml-2 {
    color: #8592a3;
}
i.bx.bx-phone.bx-sm.me-2.ml-2 {
    color: #8592a3;
}
i.bx.bx-comment-dots.email-icon.bx-sm.text-primary {
    color: #8592a3 !important;
}
span.avatar-initial.rounded-circle.bg-label-primary {
    background: #ebeef0 !important;
}
.student-names {
    display: inline;
}
.rc-drawer-content-wrapper {
    width: 50%;
}
.input-block span.placeholder {
    position: absolute;
    margin: 17px 0;
    padding: 0 4px;
    font-family: Roboto, sans-serif;
    color: #6c757d;
    display: flex;
    align-items: center;
    font-size: 15px;
    top: 0;
    left: 17px;
    transition: all 0.2s;
    transform-origin: 0% 0%;
    background: none;
    pointer-events: none;
}

.input-block input:valid + span.placeholder,
.input-block input:focus + span.placeholder {
    transform: scale(0.8) translateY(-30px);
    background: #fff;
}

.input-block input:focus {
    color: #284B63;
    border-color: #284B63;
}

.input-block input:focus + span.placeholder {
    color: #284B63;
}
