.drawer-handle {
    right: 0 !important;
    z-index: 10;
    box-shadow: none !important;
    background: #fff !important;
}

.drawer-open .drawer-handle {
    top: 0;
    background: #f1f1f1;
    right: 0;
    height: 53px;
}

.drawer-header {
    /*padding: 10px 1.25rem;*/
    /*padding: 1.5rem 0.5rem 0 1.5rem !important;*/
    padding: 0 !important;

    margin-bottom: 0;
    /*color: #555;*/
    font-size: 1.25rem !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*background: #d9eafd;*/
    color: black !important;
    font-weight: 600;
}

.drawer-header img {
    height: 35px;
    cursor: pointer;
    padding: 5px 5px;
}

.drawer-open .drawer-header {
    position: relative;
    padding: 16px 24px;
    background: #fff !important;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 4px 4px 0 0;
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
}

.ant-drawer-title {
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
}

.drawer-open .drawer-body {
    padding: 10px 30px;
}

.drawerWrapper {
    background: black;
}

.drawer-right .drawer-handle {
    position: absolute;
    left: auto !important;
    right: 10px !important;
}

.drawer-close {
}

.drawer-close:after {
    content: "X";
}

.drawer-body {
    /*padding: 1.5rem !important;*/
    padding: 20px 30px;
}

.drawer-body .information-box {
    border: 1px solid #CECECE;
    border-radius: 15px;
    /*padding: 25px;*/
}

.follow_ups.information-box h2 {
    font-family: 'Public Sans', sans-serif;
    font-weight: 600;
    /*margin: 0 0 20px;*/
    font-size: 26px;
}

.drawer-header {
    box-shadow: 0 5px 10px rgba(105, 108, 255, 0.07) !important;
}

.drawer-header h5 {
    display: flex;
    padding: 15px 20px 10px;
    width: 100%;
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
}

.drawer-header span {
    position: absolute;
    top: 13px;
    right: 30px;
    font-size: 18px;
    padding: 0;
    text-align: center;
    color: #fff;
    line-height: 28px;
    background: #f1f1f1;
    border-radius: 50%;
    width: 35px;
}

.follow_ups.information-box .flex-box {
    margin: 0 0 40px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: space-between;
}

.follow_ups.information-box .add-follow {
    margin: 0 0 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    justify-content: space-between;

}

.follow_ups.information-box .add-follow h5 {
    margin: 0;
    font-family: 'Public Sans', sans-serif;
    font-weight: 600;
    font-size: 16px;
}

.follow_ups.information-box .or {
    margin: 30px 0 0;
    display: flex;
    align-items: center;
    gap: 15px;
}

.follow_ups .added-on .active {
    display: block;
}

.drawer-body .flex-box {
    width: 100%;
}

.drawer-body .information-box h2 {
    font-family: 'Public Sans', sans-serif;
    font-weight: 600;
    margin: 0 0 20px;
    font-size: 26px;
}
