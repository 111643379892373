.rc-switch {
    min-width: 48px;
    width: auto;
}

.rc-switch-checked:after {
    left: 28px;
}

.rc-switch-inner-checked {
    left: -28px;
    top: 4px;
}

.rc-switch-checked .rc-switch-inner-unchecked {
    left: 58px;
}

.rc-switch-inner-unchecked {
    left: 28px;
    top: 4px;
}

.rc-switch-checked {
    background-color: #696cff;
    border-color: #696cff;
}

.switch-box > input[type=submit]:hover, button:hover, .button:hover, a.button:hover,
a.button.color-1:hover, a.button.color-2:hover, a.button.color-3:hover, a.button.color-4:hover,
a.button.color-5:hover, #reviews .add_review .button:hover, .pp_woocommerce .pp_content #submit,
a.button.color-2.accent-2:hover, a.button.color-3.accent-2:hover {
    background: #696cff;
    border-color: #696cff;
    color: #ffffff;
}
