.layout-main-inner * > :not(:is(.bx,.bxn, .fa)) {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}
.text-align-left {
  text-align: left;
}
.errorMsg {
  text-align: left;
  color: red;
  padding: 0 0 0 5px;
  font-size: 12px;
  font-weight: 400;
  background: #ff000008;
  border-radius: 5px;
  margin-top: 2px;
}
.form-label {
  float: left;
}
.card-header {
  border-bottom: 1px solid rgba(105, 108, 255, 0.1) !important;
}
.card-header h5 {
  line-height: 1.75rem;
  font-size: 1.125rem;
  font-weight: 500;
  color: #384551;
}
.card-body h6 {
  text-align: left;
}
.card-body .form-title {
  text-align: left;
  font-size: 18px;
  color: #696cff !important;
  border-left: 3px solid #696cffc7;
  padding: 8px 15px;
  border-radius: 2px;
  margin: 20px 0;
  text-transform: uppercase;
}
.menu-item {
  cursor: pointer;
}
.app-form .rc-select-selector {
  height: 38px;
}
.app-form .form-control {
  height: 38px;
  margin-bottom: 0;
}
.app-form .rc-select-selection-search-input {
  position: absolute;
  top: 0;
  height: 38px;
  margin: 0;
  padding: 0 10px;
}
.app-form .btn-primary {
  color: #fff !important;
  background-color: #921A1D;
  border-color: #921A1D;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(107, 19, 22, 0.2);
}
.app-form .btn-primary:hover {
  color: #fff !important;
  background-color: #6b1316 !important;
  border-color: #6b1316 !important;
}
.app-form .form-control:focus,
.app-form .form-select:focus {
  border-color: #6b1316 !important;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(107, 19, 22, 0.4);
  border-width: 1.5px;
}
.loader_outer_view {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 99999 !important;
  background: #ffffffba;
  display: flex;
  justify-content: center;
  top: 0;
}
.loader_outer_view .sk-chase {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}
.loader_outer_view .loader_inner {
  height: 100vh;
  position: absolute;
  top: 0;
  padding-top: 20%;
  text-align: center;
}
.loader_outer_view .spinner-border {
  height: 80px;
  width: 80px;
}
.loader_outer_view .spinnerText {
  font-size: 40px;
  text-align: center;
  margin-top: 10px;
  color: #696cff;
}
.student_icons {
  display: flex;
}
.student_icons a {
  margin-top: 5px;
  margin-right: 5px;
  cursor: pointer;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #788393;
  opacity: 0.7;
  border-radius: 10px;
  border-right: 2px solid white;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.dateBirthBox {
  padding: 0 !important;
  position: relative;
  display: flex;
  justify-content: center;
  width: 40px;
}
.dateBirthBox .birthPicker {
  position: absolute;
  width: 40px;
  left: 0;
  opacity: 0;
  z-index: 2;
}
.student-action-dropdown-menu li a {
  padding: 7px 15px;
}
.hyperLink {
  color: rgba(0, 0, 238, 0.7) !important;
  cursor: pointer;
}
.hyperLink.hover:hover {
  text-decoration: underline;
}

.cke_notification_warning {
    display: none !important;
}

.doc-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
